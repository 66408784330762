import React from 'react';

import { Button } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useStyles from '../../../Styles';
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import UserTable from "./UserTable";

const ViewUsers = (props) => {

    const roles = useSelector(state => state.auth.roles);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const superUser = useSelector(state => state.auth.superUser);
    const history = useHistory();
    const classes = useStyles();

    const handleCreateUser = () => {
        if (superUser) {
            history.push('/user-management/superuser/users/create');
        } else {
            history.push('/user-management/account/' + chosenAccount.id + '/users/create');
        }
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">Users</Typography>
            {(hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_user") || superUser) &&
                <Button onClick={handleCreateUser}>Create User</Button>}
            <UserTable />
        </main>
    )

}

export default ViewUsers;