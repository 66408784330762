import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clone from 'lodash/clone';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import Plot from "react-plotly.js";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    minMaxPeakText: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const PricingComponent = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [rtPlotData, setRtPlotData] = useState([]);
    const [daPlotData, setDaPlotData] = useState([]);
    const [pnodes, setPnodes] = useState();
    const [dayAheadPnodes, setDayAheadPnodes] = useState([]);
    const [realTimePnodes, setRealtimePnodes] = useState([]);
    const [rtLmps, setRtLmps] = useState([]);
    const [daLmps, setDaLmps] = useState([]);
    const [displayComponents, setDisplayComponents] = useState(false);

    useEffect(() => {
        retrievePnodes();
        setStartDate(new Date());
        setEndDate(new Date());
        // eslint-disable-next-line
    }, [])

    const retrievePnodes = async () => {
        await trackPromise(axios.get("/account-service/v1/pnodes/iso/PJM").then(response => {
            if (response.data) {
                setPnodes(response.data.sort((r1, r2) => r1.pnodeName.localeCompare(r2.pnodeName)));
            }
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const doSearch = async () => {
        let urlParams = "?startDate=" + moment(startDate).format("YYYY-MM-DD") +
            "&endDate=" + moment(endDate).format("YYYY-MM-DD") + "&pageNumber=0";
        const startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
        const duration = (Math.abs(startDateUtc - endDateUtc)) / (1000 * 60 * 60);
        setDaLmps([]);
        setRtLmps([]);
        setRtPlotData([]);
        setDaPlotData([]);
        if (realTimePnodes && realTimePnodes.length > 0) {
            let realTimeParams = urlParams + "&pnodeIds=" + realTimePnodes.map(r => r.pnodeId);
            realTimeParams += "&pageSize=" + realTimePnodes.length * duration;
            await trackPromise(axios.get("/operational-data/pjm/v3/lmp/hourly/realtime" + realTimeParams).then(response => {
                if (response.data && response.data.hourlyLmps) {
                    setRtLmps(response.data.hourlyLmps);
                    generateRtLmpGraph(response.data.hourlyLmps, displayComponents);
                }
            }).catch(error => {
                dispatch(onError(error));
            }));
        }
        if (dayAheadPnodes && dayAheadPnodes.length > 0) {
            let dayAheadParams = urlParams + "&pnodeIds=" + dayAheadPnodes.map(r => r.pnodeId);
            dayAheadParams += "&pageSize=" + dayAheadPnodes.length * duration;
            await trackPromise(axios.get("/operational-data/pjm/v3/lmp/hourly/dayahead" + dayAheadParams).then(response => {
                if (response.data && response.data.hourlyLmps) {
                    setDaLmps(response.data.hourlyLmps);
                    generateDaLmpGraph(response.data.hourlyLmps, displayComponents);
                }
            }).catch(error => {
                dispatch(onError(error));
            }));
        }
    }

    const generateRtLmpGraph = (lmps, displayComponents) => {
        const data = generateLmpGraph(lmps, displayComponents, false);
        setRtPlotData(data);
    }

    const generateDaLmpGraph = (lmps, displayComponents) => {
        const data = generateLmpGraph(lmps, displayComponents, true);
        setDaPlotData(data);
    }

    const generateLmpGraph = (lmps, displayComponents, isDayAhead) => {
        if (lmps) {
            let lmpsByPnodeName = lmps.reduce((r, a) => {
                r[a.pnodeName] = r[a.pnodeName] || [];
                r[a.pnodeName].push(a);
                return r;
            }, Object.create(null));
            let keys = Object.keys(lmpsByPnodeName);
            let data = [];
            let market = isDayAhead ? " (DA)" : " (RT)";
            if (!displayComponents) {
                keys.forEach(key => data.push({
                    x: lmpsByPnodeName[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                    y: lmpsByPnodeName[key].map(r => r.totalLmp),
                    type: 'line',
                    connectgaps: false,
                    name: key + market,
                    showlegend: true
                }));
            } else {
                keys.forEach(key => {
                    data.push({
                        x: lmpsByPnodeName[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                        y: lmpsByPnodeName[key].map(r => r.totalLmp),
                        type: 'line',
                        connectgaps: false,
                        name: key + market + " - Total LMP"
                    })
                    data.push({
                        x: lmpsByPnodeName[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                        y: lmpsByPnodeName[key].map(r => r.systemEnergyPrice),
                        type: 'line',
                        connectgaps: false,
                        name: key + market + " - System Energy Price"
                    });
                    data.push({
                        x: lmpsByPnodeName[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                        y: lmpsByPnodeName[key].map(r => r.congestionPrice),
                        type: 'line',
                        connectgaps: false,
                        name: key + market + " - Congestion Price"
                    });
                    data.push({
                        x: lmpsByPnodeName[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                        y: lmpsByPnodeName[key].map(r => r.marginalLossPrice),
                        type: 'line',
                        connectgaps: false,
                        name: key + market + " - Marginal Loss Price"
                    });
                });
            }
            return data;
        }
    }

    const generateCsv = () => {
        let header = "\"Hour Beginning Date\",\" Hour Beginning\",\" Hour Ending Date\",\"HourEnding\",";
        let lines = [];
        if (rtLmps && rtLmps.length > 0) {
            let pnodeNames = rtLmps.reduce((r, a) => {
                r[a.pnodeName] = r[a.pnodeName] || [];
                r[a.pnodeName].push(a);
                return r;
            }, Object.create(null));
            let keys = Object.keys(pnodeNames).sort((c1, c2) => c1.localeCompare(c2));
            for (let key of keys) {
                if (displayComponents) {
                    header += "\"" + key + " (RT) - Total LMP\",\"" + key + " (RT) - System Energy Price\",\"" + key + " (RT) - Congestion Price\",\"" + key + " (RT) - Marginal Loss Price\",";
                } else {
                    header += "\"" + key + " (RT) - Total LMP\",";
                }
            }
        }
        if (daLmps && daLmps.length > 0) {
            let pnodeNames = daLmps.reduce((r, a) => {
                r[a.pnodeName] = r[a.pnodeName] || [];
                r[a.pnodeName].push(a);
                return r;
            }, Object.create(null));
            let keys = Object.keys(pnodeNames).sort((c1, c2) => c1.localeCompare(c2));
            for (let key of keys) {
                if (displayComponents) {
                    header += "\"" + key + " (DA) - Total LMP\",\"" + key + " (DA) - System Energy Price\",\"" + key + " (DA) - Congestion Price\",\"" + key + " (DA) - Marginal Loss Price\",";
                } else {
                    header += "\"" + key + " (DA) - Total LMP\",";
                }
            }
        }
        lines.push(header);
        lines.push(...generateLmpCsvLines(rtLmps, daLmps));
        const csvFile = new Blob([lines.join("\n")], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(csvFile);
        link.setAttribute('href', url);
        link.setAttribute('download', 'lmp.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const generateLmpCsvLines = (rtLmps, daLmps) => {
        let rtLmpsByHourBeginningDateTime;
        let daLmpsByHourBeginningDateTime;
        if (rtLmps && rtLmps.length > 0) {
            rtLmpsByHourBeginningDateTime = rtLmps.reduce((r, a) => {
                r[a.hourBeginningDateTime.hourBeginningDate + "-" + a.hourBeginningDateTime.hourBeginning] = r[a.hourBeginningDateTime.hourBeginningDate + "-" + a.hourBeginningDateTime.hourBeginning] || [];
                r[a.hourBeginningDateTime.hourBeginningDate + "-" + a.hourBeginningDateTime.hourBeginning].push(a);
                return r;
            }, Object.create(null));
        }
        if (daLmps && daLmps.length > 0) {
            daLmpsByHourBeginningDateTime = daLmps.reduce((r, a) => {
                r[a.hourBeginningDateTime.hourBeginningDate + "-" + a.hourBeginningDateTime.hourBeginning] = r[a.hourBeginningDateTime.hourBeginningDate + "-" + a.hourBeginningDateTime.hourBeginning] || [];
                r[a.hourBeginningDateTime.hourBeginningDate + "-" + a.hourBeginningDateTime.hourBeginning].push(a);
                return r;
            }, Object.create(null));
        }
        let lines = [];
        let localDate = clone(startDate);
        let localHour = 0;
        while (localDate <= endDate) {
            while (localHour < 24) {
                let line = "\"" + localDate.toLocaleDateString() + "\",\"" + localHour + "\",\"" + localDate.toLocaleDateString() + "\",\"" + Number(parseInt(localHour, 10) + 1) + "\",";
                let rtLmpByHourBeginningDateTime = rtLmpsByHourBeginningDateTime &&
                    rtLmpsByHourBeginningDateTime[localDate.getFullYear() + "-" + ("0" + (localDate.getMonth() + 1)).slice(-2) + "-" + ("0" + localDate.getDate()).slice(-2) + "-" + localHour];
                if (rtLmpByHourBeginningDateTime) {
                    rtLmpByHourBeginningDateTime.sort((c1, c2) => c1.pnodeName.localeCompare(c2.pnodeName));
                    for (let lmp of rtLmpByHourBeginningDateTime) {
                        if (displayComponents) {
                            line += "\"" + lmp.totalLmp + "\",\"" + lmp.systemEnergyPrice + "\",\"" + lmp.congestionPrice + "\",\"" + lmp.marginalLossPrice + "\",";
                        } else {
                            line = line + "\"" + lmp.totalLmp + "\",";
                        }
                    }
                }
                let daLmpByHourBeginningDateTime = daLmpsByHourBeginningDateTime &&
                    daLmpsByHourBeginningDateTime[localDate.getFullYear() + "-" + ("0" + (localDate.getMonth() + 1)).slice(-2) + "-" + ("0" + localDate.getDate()).slice(-2) + "-" + localHour];
                if (daLmpByHourBeginningDateTime) {
                    daLmpByHourBeginningDateTime.sort((c1, c2) => c1.pnodeName.localeCompare(c2.pnodeName));
                    for (let lmp of daLmpByHourBeginningDateTime) {
                        if (displayComponents) {
                            line += "\"" + lmp.totalLmp + "\",\"" + lmp.systemEnergyPrice + "\",\"" + lmp.congestionPrice + "\",\"" + lmp.marginalLossPrice + "\",";
                        } else {
                            line = line + "\"" + lmp.totalLmp + "\",";
                        }
                    }
                }
                localHour = localHour + 1;
                lines.push(line);
            }
            localHour = 0;
            localDate.setDate(localDate.getDate() + 1);
        }
        return lines;
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={(date) => setStartDate(date)}
                            value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate" onChange={(date) => setEndDate(date)}
                            value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={5}>
                    {pnodes && pnodes.length > 0 && <Autocomplete multiple options={pnodes} renderInput={(params) => <TextField {...params} label="Day Ahead Nodes" />}
                        getOptionLabel={(option) => option.pnodeName}
                        onChange={(event, value) => setDayAheadPnodes([...value])}
                        value={dayAheadPnodes} fullWidth />}
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={5}>
                    {pnodes && pnodes.length > 0 && <Autocomplete multiple options={pnodes} renderInput={(params) => <TextField {...params} label="Real Time Nodes" />}
                        getOptionLabel={(option) => option.pnodeName}
                        onChange={(event, value) => setRealtimePnodes([...value])}
                        value={realTimePnodes} fullWidth />}
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={5} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={doSearch}>Search</Button>
                </Grid>
                <Grid item xs={6} sm={5} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <FormControlLabel control={<Checkbox checked={displayComponents} onChange={() => {
                        setDisplayComponents(!displayComponents);
                        generateDaLmpGraph(daLmps, !displayComponents);
                        generateRtLmpGraph(rtLmps, !displayComponents)
                    }} />} label="Display Pricing Components" />
                </Grid>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={12}>
                    {((rtPlotData && rtPlotData.length > 0) || (daPlotData && daPlotData.length > 0)) &&
                        <Plot data={[...rtPlotData, ...daPlotData]} layout={{
                            width: 1200,
                            height: 1000,
                            title: 'Pricing',
                            showLegend: true,
                            xaxis: { title: { text: 'Date (Hour Ending)' } },
                            yaxis: { title: { text: 'Total LMP' } },
                            legend: { orientation: "h" },
                        }} config={{ dispalylogo: false }} />
                    }
                </Grid>
                <Grid item xs={6} sm={5} />
                <Grid item xs={6} sm={2}>
                    {((rtPlotData && rtPlotData.length > 0) || (daPlotData && daPlotData.length > 0)) &&
                        <Button onClick={generateCsv}>Download</Button>
                    }
                </Grid>
                <Grid item xs={6} sm={5} />
            </Grid>

        </main>
    )

};

export default PricingComponent;