import { Button, Grid, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../../Styles";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";

const HistoricalPeaks = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [iso, setIso] = useState('');
    const [zone, setZone] = useState('');
    const [peakPeriods, setPeakPeriods] = useState([]);
    const [historicalPeaks, setHistoricalPeaks] = useState();


    useEffect(() => {
        retrievePeakPeriods();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrievePeakPeriods = async () => {
        await trackPromise(axios.get("/peak-manager/v1/peak/period").then(response => {
            setPeakPeriods(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleIsoMenuChange = (e) => {
        setIso(e.target.value);
        setZone('');
    }

    const handleZoneMenuChange = (e) => {
        setZone(e.target.value);
    }

    const renderZoneMenuItems = () => {
        if (iso === 'PJM') {
            let menuItems = [''];
            menuItems.push(<MenuItem key='' value=''></MenuItem>);
            peakPeriods.filter(r => r.iso === 'PJM').filter(r => r.zone !== null).sort((c1, c2) => c1.zone.localeCompare(c2.zone)).forEach(r => menuItems.push(<MenuItem key={r.zone} value={r.zone}>{r.zone}</MenuItem>));
            return menuItems;
        }
    }

    const renderIsoMenuItems = () => {
        if (peakPeriods) {
            let isos = Array.from(new Set(peakPeriods.map(r => r.iso)));
            let menuItems = [''];
            isos.forEach(r => menuItems.push(<MenuItem key={r} value={r}>{r}</MenuItem>));
            return menuItems;
        }
    }

    const doSearch = async () => {
        let url = "/peak-manager/v1/peak/historical";
        if (chosenAccount) {
            url += "/account/" + chosenAccount.id
        }
        url += "/iso/" + iso;
        if (zone) {
            url += "/zone/" + zone;
        }
        await trackPromise(axios.get(url).then(response => {
            setHistoricalPeaks(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const renderHistoricalPeaks = () => {
        if (historicalPeaks) {
            return (Object.keys(historicalPeaks).sort((c1, c2) => c2.localeCompare(c1)).map(year => {
                return (
                    <React.Fragment key={year}>
                        <Typography variant="h6" align="center" style={{ marginTop: 25 }}>{year}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Hour Beginning</TableCell>
                                    <TableCell>Hour Ending</TableCell>
                                    <TableCell>System Load (MWs)</TableCell>
                                    <TableCell>Facility Load (MWs)</TableCell>
                                    <TableCell>Official</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historicalPeaks[year].sort((c1, c2) => { return c2.load - c1.load }).map(historicalPeak => {
                                    return (
                                        <TableRow key={historicalPeak.id}>
                                            <TableCell>{historicalPeak.hourBeginningDateTime.hourBeginningDate}</TableCell>
                                            <TableCell>{historicalPeak.hourBeginningDateTime.hourBeginning}</TableCell>
                                            <TableCell>{historicalPeak.hourEndingDateTime.hourEnding}</TableCell>
                                            <TableCell><NumberFormat value={historicalPeak.load} displayType='text' fixedDecimalScale={true} decimalScale={0} thousandSeparator /></TableCell>
                                            <TableCell>{historicalPeak.facilityLoad ? <NumberFormat value={historicalPeak.facilityLoad} displayType='text' fixedDecimalScale={true} decimalScale={2} thousandSeparator /> : 'N/A'}</TableCell>
                                            <TableCell>{historicalPeak.official ? <CheckCircleOutline /> : ''}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </React.Fragment>
                )
            }
            ))
        }
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h6" align="center" style={{ marginBottom: 25 }}>Historical Capacity and Transmission Peaks</Typography>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <Select fullWidth value={iso} onChange={handleIsoMenuChange}>
                        {renderIsoMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <Select value={zone} fullWidth disabled={iso !== 'PJM'} onChange={handleZoneMenuChange}>
                        {renderZoneMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <Button onClick={doSearch} fullWidth>Search</Button>
                </Grid>
            </Grid>
            {renderHistoricalPeaks()}
        </main>
    )

}

export default HistoricalPeaks;