import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableFooter } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useStyles from '../../../Styles';
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import { retrieveUsers, retrieveUsersAsSuperUser } from '../../../store/user-management/actions/userActions';
import { CHANGE_USER_TABLE_PAGE_SIZE } from '../../../store/user-management/userManagementActionTypes';
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const UserTable = (props) => {

    const users = useSelector(state => state.users.users);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const superUser = useSelector(state => state.auth.superUser);
    const userTablePageSize = useSelector(state => state.users.userTablePageSize);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!users || (!users.results || users.results.length < 1)) {
            fetchUsers(0, userTablePageSize);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleRowClick = (userId) => {
        if (superUser) {
            history.push('/user-management/superuser/users/' + userId);
        } else {
            history.push('/user-management/account/' + chosenAccount.id + '/users/' + userId);
        }
    }

    const fetchUsers = (pageNumber, pageSize) => {
        if (superUser) {
            dispatch(retrieveUsersAsSuperUser(pageNumber, pageSize));
        } else {
            dispatch(retrieveUsers(chosenAccount.id, pageNumber, pageSize));
        }
    }

    const handlePageChange = (pageNumber) => {
        fetchUsers(pageNumber, userTablePageSize);
    }

    const handleRowsPerPageChange = (e) => {
        dispatch({ type: CHANGE_USER_TABLE_PAGE_SIZE, userTablePageSize: e.target.value });
        fetchUsers(0, e.target.value);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const renderTableRow = (user) => {
        if (hasPermissionForAccount(roles, chosenAccount.id, "ROLE_retrieve_user_role") || superUser) {
            return (
                <TableRow className={classes.tableRow} key={user.id} onClick={(e) => handleRowClick(user.id)}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.emailAddress}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                </TableRow>
            );
        }
        return (
            <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.emailAddress}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
            </TableRow>
        );
    }


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>E-Mail Address</TableCell>
                    <TableCell>Phone Number</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(users && users.results) && users.results.map(user => { return (renderTableRow(user)) })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={10}
                        count={users.totalCount ? users.totalCount : 0}
                        rowsPerPage={calculateRowsPerPage(userTablePageSize)}
                        page={users.pageNumber ? users.pageNumber : 0} onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        ActionsComponent={PaginatedTableActions} />
                </TableRow>
            </TableFooter>
        </Table>
    );

}

export default UserTable;