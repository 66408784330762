import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useStyles from "../../../Styles";
import { cleanStringInput } from "../../../Utility/StringUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import HourlyUnitUpdates from "../HourlyUnitUpdate/HourlyUnitUpdates";


const UnitDetail = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [unitDetails, setUnitDetails] = useState();
    const [hourlyUnitUpdates, setHourlyUnitUpdates] = useState();

    useEffect(() => {
        retrieveGeneratorDetail(params.accountId, params.location, params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const retrieveGeneratorDetail = async (accountId, location, id) => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + location + "/" + id).then(response => {
            setUnitDetails(response.data);
            retrieveHourlyUnitUpdates(accountId, location, response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const retrieveHourlyUnitUpdates = async (accountId, location, unit) => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + location + "/hourly-update?startDate=" + unit.date + "&endDate=" + unit.date + "&pageNumber=0&pageSize=25").then(response => {
            setHourlyUnitUpdates(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const generateDayAheadOffers = async () => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/" + params.location + "/da/offer?marketDay=" + unitDetails.date).then(response => {
            setHourlyUnitUpdates(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    return (
        <main className={classes.layout}>
            {unitDetails &&
                <React.Fragment>
                    <Typography variant="h5" align="center">Unit Details for {unitDetails.resourceName}</Typography>
                    <Typography variant="h6" align="center">{unitDetails.date}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField label="Location Id" id="location" value={cleanStringInput(unitDetails.location)} disabled={true} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField label="Default Commit Status" value={cleanStringInput(unitDetails.defaultCommitStatus)} disabled={true} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField label="Emergency Min." value={cleanStringInput(unitDetails.emergencyMinimum)} disabled={true} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            <TextField label="Emergency Max." value={cleanStringInput(unitDetails.emergencyMaximum)} disabled={true} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField label="Economic Min." value={cleanStringInput(unitDetails.economicMinimum)} disabled={true} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            <TextField label="Economic Max." value={cleanStringInput(unitDetails.economicMaximum)} disabled={true} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={6} sm={4} align="center">
                            <Button variant="contained" onClick={() => generateDayAheadOffers()}>Generate Offers</Button>
                        </Grid>
                        <Grid item xs={6} sm={4} />
                    </Grid>
                    <HourlyUnitUpdates hourlyUnitUpdates={hourlyUnitUpdates} accountId={params.accountId} location={params.location} date={unitDetails.date} />
                </React.Fragment>
            }
        </main>
    )

}

export default UnitDetail;