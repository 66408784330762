import { ACCOUNT_CHANGED } from "../actionTypes";
import { AVAILABLE_GENERATORS_RETREIVED, DAY_AHEAD_RESULTS_DIRECTION, DAY_AHEAD_RESULTS_ORDER_BY, DAY_AHEAD_RESULTS_PAGE_SIZE, DAY_AHEAD_RESULTS_RETRIEVED, DAY_AHEAD_SEARCH_END_DATE, DAY_AHEAD_SEARCH_START_DATE, DAY_AHEAD_SEARCH_UNIT, UNIT_DETAILS_RESULTS_DIRECTION, UNIT_DETAILS_RESULTS_ORDER_BY, UNIT_DETAILS_RESULTS_PAGE_SIZE, UNIT_DETAILS_RESULTS_RETRIEVED, UNIT_DETAILS_SEARCH_END_DATE, UNIT_DETAILS_SEARCH_START_DATE, UNIT_DETAILS_SEARCH_UNIT } from "./energyMarketActionTypes";

const initialState = {
    dayAheadResults: [],
    dayAheadSearchStartDate: null,
    dayAheadSearchEndDate: null,
    dayAheadSearchUnit: null,
    dayAheadResultsDirection: undefined,
    dayAheadResultsOrderBy: undefined,
    dayAheadResultsPageSize: 24,
    availableGenerators: [],
    unitDetailsResults: [],
    unitDetailsSearchStart: null,
    unitDetailsSearchEnd: null,
    unitDetailsSearchUnit: null,
    unitDetailsResultsDirection: undefined,
    unitDetailsResultsOrderBy: undefined,
    unitDetailsResultsPageSize: 10
}

const EnergyMarketReducer = (state = initialState, action) => {
    switch (action.type) {
        case DAY_AHEAD_RESULTS_RETRIEVED:
            return { ...state, dayAheadResults: action.dayAheadResults };
        case DAY_AHEAD_SEARCH_START_DATE:
            return { ...state, dayAheadSearchStartDate: action.dayAheadSearchStartDate };
        case DAY_AHEAD_SEARCH_END_DATE:
            return { ...state, dayAheadSearchEndDate: action.dayAheadSearchEndDate };
        case DAY_AHEAD_RESULTS_DIRECTION:
            return { ...state, dayAheadResultsDirection: action.dayAheadResultsDirection };
        case DAY_AHEAD_RESULTS_ORDER_BY:
            return { ...state, dayAheadResultsOrderBy: action.dayAheadResultsOrderBy };
        case DAY_AHEAD_RESULTS_PAGE_SIZE:
            return { ...state, dayAheadResultsPageSize: action.dayAheadResultsPageSize };
        case AVAILABLE_GENERATORS_RETREIVED:
            return { ...state, availableGenerators: action.availableGenerators };
        case DAY_AHEAD_SEARCH_UNIT:
            return { ...state, dayAheadSearchUnit: action.dayAheadSearchUnit };
        case UNIT_DETAILS_RESULTS_RETRIEVED:
            return { ...state, unitDetailsResults: action.unitDetailsResults };
        case UNIT_DETAILS_SEARCH_START_DATE:
            return { ...state, unitDetailsSearchStart: action.unitDetailsSearchStartDate };
        case UNIT_DETAILS_SEARCH_END_DATE:
            return { ...state, unitDetailsSearchEnd: action.unitDetailsSearchEndDate };
        case UNIT_DETAILS_SEARCH_UNIT:
            return { ...state, unitDetailsSearchUnit: action.unitDetailsSearchUnit };
        case UNIT_DETAILS_RESULTS_DIRECTION:
            return { ...state, unitDetailsResultsDirection: action.unitDetailsResultsDirection };
        case UNIT_DETAILS_RESULTS_ORDER_BY:
            return { ...state, unitDetailsResultsOrderBy: action.unitDetailsResultsOrderBy };
        case UNIT_DETAILS_RESULTS_PAGE_SIZE:
            return { ...state, unitDetailsResultsPageSize: action.unitDetailsResultsPageSize };
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

export default EnergyMarketReducer;