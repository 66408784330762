import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import { Block, Check } from "@material-ui/icons";
import React, { useEffect, useState } from 'react';
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../../Styles";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import { PANEL_MODULE_IS_EDIT, SET_PANEL_MODULE, SET_PANEL_MODULES } from "../../../store/solar-forecast/SolarForecastActionTypes";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const ViewPanelModulesCompnent = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const panelModules = useSelector(state => state.solarForecast.panelModules)
    const [pageSize, setPageSize] = useState(5);
    const [orderBy, setOrderBy] = useState("moduleName");
    const [direction, setDirection] = useState("asc");
    const [accountId, setAccountId] = useState('');
    const [locationDetailId, setLocationDetailId] = useState('');

    useEffect(() => {
        if (props.locationDetailId !== locationDetailId) {
            setAccountId(props.accountId);
            setLocationDetailId(props.locationDetailId);
            retrievePanelModules(props.accountId, props.locationDetailId, 5, 0, 'moduleName', 'asc');
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const retrievePanelModules = async (accountId, locationDetailId, pageSize, pageNumber, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction }
        await trackPromise(axios.get("/solar-forecast/account/" + accountId + "/location/detail/" + locationDetailId + "/modules",
            { params: { ...urlParams } }).then(response => {
                dispatch({ type: SET_PANEL_MODULES, panelModules: response.data });
            }).catch(error => {
                dispatch(onError(error.response));
            }))
    }


    const handlePageChange = (pageNumber) => {
        retrievePanelModules(accountId, locationDetailId, pageSize, pageNumber, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 5;
        }
        if (rowsPerPage <= 5) {
            return 5;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        retrievePanelModules(accountId, locationDetailId, e.target.value, 0, orderBy, direction);
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        retrievePanelModules(accountId, locationDetailId, pageSize, 0, column, sortDirection);
    }

    const handleRowClick = (panelModule) => {
        dispatch({ type: SET_PANEL_MODULE, panelModule: panelModule });
        dispatch({ type: PANEL_MODULE_IS_EDIT, panelModuleIsEdit: false });
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel active={orderBy === "moduleName"} direction={orderBy === "moduleName" ? direction : 'asc'} onClick={() => handleSort("moduleName")}>
                            Module Name
                            {orderBy === "moduleName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === "fixed"} direction={orderBy === "fixed" ? direction : 'asc'} onClick={() => handleSort("fixed")}>
                            Fixed
                            {orderBy === "fixed" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === "singleAxis"} direction={orderBy === "singleAxis" ? direction : 'asc'} onClick={() => handleSort("singleAxis")}>
                            Single Axis
                            {orderBy === "singleAxis" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === "numberOfModules"} direction={orderBy === "numberOfModules" ? direction : 'asc'} onClick={() => handleSort("numberOfModules")}>
                            Number of Modules
                            {orderBy === "numberOfModules" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === "axisTilt"} direction={orderBy === "axisTilt" ? direction : 'asc'} onClick={() => handleSort("axisTilt")}>
                            Axis Tilt
                            {orderBy === "axisTilt" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === "azimuth"} direction={orderBy === "azimuth" ? direction : 'asc'} onClick={() => handleSort("azimuth")}>
                            Azimuth
                            {orderBy === "azimuth" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {panelModules && panelModules.results && panelModules.results.length > 0 &&
                    panelModules.results.map(r => {
                        return (
                            <TableRow key={r.id} onClick={() => handleRowClick(r)} className={classes.tableRow}>
                                <TableCell>{r.moduleName}</TableCell>
                                <TableCell>{r.fixed ? <Check /> : <Block />}</TableCell>
                                <TableCell>{r.singleAxis ? <Check /> : <Block />}</TableCell>
                                <TableCell><NumberFormat value={r.numberOfModules} displayType='text' fixedDecimalScale={true} decimalScale={0} thousandSeparator /></TableCell>
                                <TableCell>{r.axisTilt ? r.axisTilt + '\u00b0' : ''}</TableCell>
                                <TableCell>{r.azimuth || r.azimuth === 0 ? r.azimuth + '\u00b0' : ''}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination count={panelModules.totalCount ? panelModules.totalCount : 0} onPageChange={handlePageChange}
                        page={panelModules.pageNumber ? panelModules.pageNumber : 0}
                        rowsPerPage={calculateRowsPerPage(panelModules.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                        rowsPerPageOptions={[5, 10, 25]} colSpan={6} ActionsComponent={PaginatedTableActions} />
                </TableRow>
            </TableFooter>
        </Table>
    )

}

export default ViewPanelModulesCompnent;