import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { cleanStringInput } from "../../../Utility/StringUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import { EQR_SUBMISSIONS_RETRIEVED, SET_EQR_SUBMISSIONS_PAGE_SIZE } from "../../../store/ferc-eqr/FercEqrActionTypes";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewEqrSubmissions = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const eqrSubmissions = useSelector(state => state.fercEqr.eqrSubmissions);
    const pageSize = useSelector(state => state.fercEqr.eqrSubmissionsPageSize);
    const [eqrYear, setEqrYear] = useState('');
    const [eqrQuarter, setEqrQuarter] = useState('');

    useEffect(() => {
        if (!eqrSubmissions || (!eqrSubmissions.results || eqrSubmissions.results.length < 1)) {
            retrieveEqrSubmissions(0, pageSize)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const retrieveEqrSubmissions = async (pageNumber, pageSize) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber };
        await trackPromise(axios.get("/ferc/eqr/eqr-account/" + params.eqrAccountId + "/submission", { params: { ...urlParams } }).then(response => {
            dispatch({ type: EQR_SUBMISSIONS_RETRIEVED, eqrSubmissions: response.data });
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const handlePageChange = (pageNumber, pageSize) => {
        retrieveEqrSubmissions(pageNumber, pageSize);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        dispatch({ type: SET_EQR_SUBMISSIONS_PAGE_SIZE, eqrSubmissionsPageSize: e.target.value });
        retrieveEqrSubmissions(0, e.target.value);
    }

    const onInputChange = (e) => {
        setEqrYear(e.target.value);
    }

    const handleEqrQuarterChange = (e) => {
        setEqrQuarter(e.target.value);
    }

    const generateEqr = async () => {
        await trackPromise(axios.post("/ferc/eqr/eqr-account/" + params.eqrAccountId + "/submission/year/" + eqrYear + "/quarter/" + eqrQuarter)).then(response => {
            dispatch(onSuccess("FERC EQR generation started."))
        }).catch(error => {
            dispatch(onError(error));
        })
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h5" align="center" style={{ marginBottom: 50 }}>EQR Filings</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={(e) => retrieveEqrSubmissions(0, pageSize)} fullWidth>Refresh</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>Quarter</TableCell>
                        <TableCell>Submission Date</TableCell>
                        <TableCell>Acceptance Date</TableCell>
                    </TableRow>
                </TableHead>
                {eqrSubmissions && eqrSubmissions.results && eqrSubmissions.results.length > 0 &&
                    <React.Fragment>
                        <TableBody>
                            {eqrSubmissions.results.map(r => {
                                return (
                                    <TableRow key={r.id} className={classes.tableRow} onClick={() => history.push("/ferc/eqr/eqr-account/" + r.eqrAccountId + "/submissions/" + r.generationUuid)}>
                                        <TableCell>{r.year}</TableCell>
                                        <TableCell>{r.quarter}</TableCell>
                                        <TableCell>{r.submittalDate}</TableCell>
                                        <TableCell>{r.acceptanceDate}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination count={eqrSubmissions.totalCount ? eqrSubmissions.totalCount : 0} onPageChange={handlePageChange}
                                    page={eqrSubmissions.pageNumber ? eqrSubmissions.pageNumber : 0}
                                    rowsPerPage={calculateRowsPerPage(eqrSubmissions.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPageOptions={[10, 25, 50]} colSpan={9} ActionsComponent={PaginatedTableActions} />
                            </TableRow>
                        </TableFooter>
                    </React.Fragment>}
            </Table>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={12} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <TextField label="Year (YYYY)" name="year" value={cleanStringInput(eqrYear)} onChange={(e) => onInputChange(e)} fullWidth type="number" />
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Quarter</InputLabel>
                        <Select value={eqrQuarter} onChange={handleEqrQuarterChange} fullWidth>
                            <MenuItem key='Q1' value='1'>Q1</MenuItem>
                            <MenuItem key='Q2' value='2'>Q2</MenuItem>
                            <MenuItem key='Q3' value='3'>Q3</MenuItem>
                            <MenuItem key='Q4' value='4'>Q4</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <Button fullWidth onClick={generateEqr} style={{ minHeight: '3em' }}>Generate EQR</Button>
                </Grid>
            </Grid>
        </main>
    )

}

export default ViewEqrSubmissions;