export const DAY_AHEAD_RESULTS_RETRIEVED = "DAY_AHEAD_RESULTS_RETRIEVED";
export const DAY_AHEAD_SEARCH_START_DATE = "DAY_AHEAD_SEARCH_START_DATE";
export const DAY_AHEAD_SEARCH_END_DATE = "DAY_AHEAD_SEARCH_END_DATE";
export const DAY_AHEAD_SEARCH_UNIT = "DAY_AHEAD_SEARCH_UNIT";
export const DAY_AHEAD_RESULTS_DIRECTION = "DAY_AHEAD_RESULTS_DIRECTION";
export const DAY_AHEAD_RESULTS_ORDER_BY = "DAY_AHEAD_RESULTS_ORDER_BY";
export const DAY_AHEAD_RESULTS_PAGE_SIZE = "DAY_AHEAD_RESULTS_PAGE_SIZE";
export const AVAILABLE_GENERATORS_RETREIVED = "AVAILABLE_GENERATORS_RETRIEVED";
export const UNIT_DETAILS_RESULTS_RETRIEVED = "UNIT_DETAILS_RESULTS_RETRIEVED";
export const UNIT_DETAILS_SEARCH_START_DATE = "UNIT_DETAILS_SEARCH_START_DATE";
export const UNIT_DETAILS_SEARCH_END_DATE = "UNIT_DETAILS_SEARCH_END_DATE";
export const UNIT_DETAILS_SEARCH_UNIT = "UNIT_DETAILS_SEARCH_UNIT";
export const UNIT_DETAILS_RESULTS_DIRECTION = "UNIT_DETAILS_RESULTS_DIRECTION";
export const UNIT_DETAILS_RESULTS_ORDER_BY = "UNIT_DETAILS_RESULTS_ORDER_BY";
export const UNIT_DETAILS_RESULTS_PAGE_SIZE = "UNIT_DETAILS_RESULTS_PAGE_SIZE";