import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const styles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const PaginatedTableActions = (props) => {

    const classes = styles();
    const handleLoadData = props.onPageChange;
    const rowsPerPage = props.rowsPerPage;
    const count = props.count;
    const page = props.page;

    const onFirstPageClick = (e) => {
        handleLoadData(0, rowsPerPage);
    }

    const onNextButtonClick = (e) => {
        handleLoadData(page + 1, rowsPerPage);
    }

    const onBackButtonClick = (e) => {
        handleLoadData(page - 1, rowsPerPage);
    }

    const onLastPageClick = (e) => {
        handleLoadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1), rowsPerPage);
    }

    return (
        <div className={classes.root}>
            <IconButton onClick={onFirstPageClick} disabled={page === 0} aria-label="first page">
                <FirstPageIcon />
            </IconButton>
            <IconButton onClick={onBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton onClick={onNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                <KeyboardArrowRight />
            </IconButton>
            <IconButton onClick={onLastPageClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                <LastPageIcon />
            </IconButton>
        </div>
    );

}

export default PaginatedTableActions;