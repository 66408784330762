import { Button, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';

const PeakManagementAppBar = () => {

  const chosenAccount = useSelector(state => state.chosenAccount.account);
  const roles = useSelector(state => state.auth.roles);
  const history = useHistory();
  const [historicalPeakAnchorEl, setHistoricalPeakAnchorEl] = useState(null);

  const handleHistoricalPeakMenuClick = (path) => {
    history.push(path);
    setHistoricalPeakAnchorEl(null);
  }

  const handleHistoricalPeakClick = (e) => {
    setHistoricalPeakAnchorEl(e.currentTarget);
  }

  const handleHistoricalPeakClose = () => {
    setHistoricalPeakAnchorEl(null);
  }

  const buildPeakManagementActionMenu = () => {
    if (hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_peak_management_peaks") || hasPermissionForAccount(roles, chosenAccount.id, "ROLE_retrieve_peak_management_peaks")) {
      return (<Button onClick={() => history.push("/peak-management")}>Peak Actions</Button>);
    }
  }

  const buildHistoricalPeaksMenu = () => {
    let menuItems = [];
    if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_historical_peaks')) {
      menuItems.push(<MenuItem key='ROLE_create_historical_peaks' onClick={() => handleHistoricalPeakMenuClick("/peak-management/historical/create")}>
        Create Historical Peaks</MenuItem>);
    }
    menuItems.push(<MenuItem key='ROLE_retrieve_historical_peaks' onClick={() => handleHistoricalPeakMenuClick("/peak-management/historical")}>
      View Historical Peaks</MenuItem>);
    if (menuItems.length === 1) {
      return (<Button onClick={() => history.push("/peak-management/historical")}>Historical Peaks</Button>);
    } else if (menuItems.length > 0) {
      return (
        <React.Fragment>
          <Button onClick={handleHistoricalPeakClick}>Historical Peaks</Button>
          <Menu open={Boolean(historicalPeakAnchorEl)} onClose={handleHistoricalPeakClose} anchorEl={historicalPeakAnchorEl} getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            {menuItems}
          </Menu>
        </React.Fragment>
      )
    }

  }

  return (
    <React.Fragment>
      {buildPeakManagementActionMenu()}
      {buildHistoricalPeaksMenu()}
    </React.Fragment>
  );

}

export default PeakManagementAppBar;