import { Button, Checkbox, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from 'react';
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";

const HourlyUnitUpdates = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [hourlyUnitUpdates, setHourlyUnitUpdates] = useState();
    const { accountId, location, date } = props;
    const [direction, setDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('hourEnding');

    const commitStatusOptions = [{ name: '', value: '' }, { name: 'Charge', value: 'Charge' }, { name: 'Continuous', value: 'Continuous' }, { name: 'Discharge', value: 'Discharge' }, { name: 'Economic', value: 'Economic' }, { name: 'Emergency', value: 'Emergency' }, { name: 'Must Run', value: 'MustRun' }, { name: 'Unavailable', value: 'Unavailable' }];

    useEffect(() => {
        setHourlyUnitUpdates(props.hourlyUnitUpdates);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, props])

    const retrieveHourlyUnitUpdates = async (column, sortDirection) => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + location + "/hourly-update?startDate=" + date + "&endDate=" + date + "&pageNumber=0&pageSize=25&orderBy=" + column + "&direction=" + sortDirection).then(response => {
            setHourlyUnitUpdates(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const submitHourlyUnitUpdates = async () => {
        await trackPromise(axios.post("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + location + "/hourly-update", JSON.stringify(hourlyUnitUpdates.results)).then(response => {
            setHourlyUnitUpdates(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setDirection(sortDirection);
        setOrderBy(column);
        retrieveHourlyUnitUpdates(column, sortDirection);
    }

    const handleHourlyUpdateChange = (e, hourEnding, fieldName) => {
        let results = hourlyUnitUpdates.results;
        for (let i = 0; i <= 23; i++) {
            if (results[i].hourEndingDateTime.hourEnding === hourEnding) {
                results[i][fieldName] = parseFloat(e.value);
                if (fieldName === 'economicMinimum' || fieldName === 'economicMaximum') {
                    if (results[i].economicMinimum === results[i].economicMaximum) {
                        results[i].commitStatus = 'MustRun';
                        results[i].fixedGen = true;
                    } else {
                        results[i].fixedGen = false;
                    }
                }
            }
        }
        hourlyUnitUpdates.results = [...results];
        setHourlyUnitUpdates({ ...hourlyUnitUpdates });
    }

    const handleCommitStatusChange = (e, value, hourEnding) => {
        let results = hourlyUnitUpdates.results;
        for (let i = 0; i <= 23; i++) {
            if (results[i].hourEndingDateTime.hourEnding === hourEnding) {
                results[i].commitStatus = value.value;
            }
        }
        hourlyUnitUpdates.results = [...results];
        setHourlyUnitUpdates({ ...hourlyUnitUpdates });
    }

    const handleFixedGenChange = (e, value, hourEnding) => {
        let results = hourlyUnitUpdates.results;
        for (let i = 0; i <= 23; i++) {
            if (results[i].hourEndingDateTime.hourEnding === hourEnding) {
                results[i].fixedGen = !results[i].fixedGen;
            }
        }
        hourlyUnitUpdates.results = [...results];
        setHourlyUnitUpdates({ ...hourlyUnitUpdates });
    }

    return (
        <React.Fragment>
            <TableContainer className={classes.tableContainer}>
                <Table sx={{ width: "max-content" }} stickyHeader className={classes.table} >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel active={orderBy === "hourEnding"} direction={orderBy === "hourEnding" ? direction : 'asc'} onClick={() => handleSort("hourEnding")}>
                                    Hour Ending
                                    {orderBy === "hourEnding" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "defaultEmergencyMinimum"} direction={orderBy === "defaultEmergencyMinimum" ? direction : 'asc'} onClick={() => handleSort("defaultEmergencyMinimum")}>
                                    Default Emergency Minimum (MW)
                                    {orderBy === "defaultEmergencyMinimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "defaultEmergencyMaximum"} direction={orderBy === "defaultEmergencyMaximum" ? direction : 'asc'} onClick={() => handleSort("defaultEmergencyMaximum")}>
                                    Default Emergency Maximum (MW)
                                    {orderBy === "defaultEmergencyMaximum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "emergencyMinimum"} direction={orderBy === "emergencyMinimum" ? direction : 'asc'} onClick={() => handleSort("emergencyMinimum")}>
                                    Emergency Minimum (MW)
                                    {orderBy === "emergencyMinimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "emergencyMaximum"} direction={orderBy === "emergencyMaximum" ? direction : 'asc'} onClick={() => handleSort("emergencyMaximum")}>
                                    Emergency Maximum (MW)
                                    {orderBy === "emergencyMiaxnimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "defaultEconomicMinimum"} direction={orderBy === "defaultEconomicMinimum" ? direction : 'asc'} onClick={() => handleSort("defaultEconomicMinimum")}>
                                    Default Economic Minimum (MW)
                                    {orderBy === "defaultEconomicMinimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "defaultEconomicMaximum"} direction={orderBy === "defaultEconomicMaximum" ? direction : 'asc'} onClick={() => handleSort("defaultEconomicMaximum")}>
                                    Default Economic Maximum (MW)
                                    {orderBy === "defaultEconomicMaximum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "economicMinimum"} direction={orderBy === "economicMinimum" ? direction : 'asc'} onClick={() => handleSort("emergencyMinimum")}>
                                    Economic Minimum (MW)
                                    {orderBy === "economicMinimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "economicMaximum"} direction={orderBy === "economicMaximum" ? direction : 'asc'} onClick={() => handleSort("economicMaximum")}>
                                    Economic Maximum (MW)
                                    {orderBy === "economicMiaxnimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={150}>
                                <TableSortLabel active={orderBy === "commitStatus"} direction={orderBy === "commitStatus" ? direction : 'asc'} onClick={() => handleSort("commitStatus")}>
                                    Commit Status
                                    {orderBy === "commitStatus" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "fixedGen"} direction={orderBy === "fixedGen" ? direction : 'asc'} onClick={() => handleSort("fixedGen")}>
                                    Fixed Gen
                                    {orderBy === "fixedGen" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hourlyUnitUpdates && hourlyUnitUpdates.results && hourlyUnitUpdates.results.length > 0 &&
                            hourlyUnitUpdates.results.map(r => {
                                return (
                                    <TableRow key={r.id}>
                                        <TableCell>{r.hourEndingDateTime.hourEnding !== 0 ? r.hourEndingDateTime.hourEnding : 24}</TableCell>
                                        <TableCell>{r.defaultEmergencyMinimum}</TableCell>
                                        <TableCell>{r.defaultEmergencyMaximum}</TableCell>
                                        <TableCell>{r.editable ? <NumberFormat value={r.emergencyMinimum} name="emergencyMinimum" customInput={TextField}
                                            thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e, hourEnding, fieldName) => handleHourlyUpdateChange(e, r.hourEndingDateTime.hourEnding, 'emergencyMinimum')} /> : r.emergencyMinimum}
                                        </TableCell>
                                        <TableCell>{r.editable ? <NumberFormat value={r.emergencyMaximum} name="emergencyMaximum" customInput={TextField}
                                            thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e, hourEnding, fieldName) => handleHourlyUpdateChange(e, r.hourEndingDateTime.hourEnding, 'emergencyMaximum')} /> : r.emergencyMaximum}</TableCell>
                                        <TableCell>{r.defaultEconomicMinimum}</TableCell>
                                        <TableCell>{r.defaultEconomicMaximum}</TableCell>
                                        <TableCell>{r.editable ?
                                            <NumberFormat value={r.economicMinimum} name="economicMinimum"
                                                customInput={TextField} thousandSeparator={true} decimalScale={1} type="text"
                                                onValueChange={(e, hourEnding, fieldName) => handleHourlyUpdateChange(e, r.hourEndingDateTime.hourEnding, 'economicMinimum')} /> :
                                            r.economicMinimum}
                                        </TableCell>
                                        <TableCell>{r.editable ?
                                            <NumberFormat value={r.economicMaximum} name="economicMaximum"
                                                customInput={TextField} thousandSeparator={true} decimalScale={1} type="text"
                                                onValueChange={(e, hourEnding, fieldName) => handleHourlyUpdateChange(e, r.hourEndingDateTime.hourEnding, 'economicMaximum')} /> :
                                            r.economicMinimum}</TableCell>
                                        <TableCell>{r.editable ? <Autocomplete options={commitStatusOptions} getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option.name}
                                            onChange={(e, value, hourEnding) => handleCommitStatusChange(e, value, r.hourEndingDateTime.hourEnding)}
                                            renderInput={(params) => <TextField {...params} label="Commit Status" />} value={r.commitStatus}
                                            getOptionSelected={(option, value) => option.value === value} /> : r.commitStatus}
                                        </TableCell>
                                        <TableCell>{r.editable ? <Checkbox checked={r.fixedGen} onChange={(e, value, hourEnding) => handleFixedGenChange(e, value, r.hourEndingDateTime.hourEnding)} disabled={false} /> : <Checkbox checked={r.fixedGen} disabled={true} />}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4} align="center">
                    {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_submit_hourly_unit_update') && <Button onClick={() => submitHourlyUnitUpdates()} variant="contained" >Submit</Button>}
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
        </React.Fragment>
    )

}

export default HourlyUnitUpdates;