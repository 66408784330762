import { Button, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../../store/actions/popupActions';
import { RETRIEVE_SUPPLIER_CUSTOMERS, SUPPLIER_CUSTOMER_DIRECTION, SUPPLIER_CUSTOMER_ORDER_BY, SUPPLIER_CUSTOMER_PAGE_SIZE } from '../../../store/gas-supply/GasSupplyActionTypes';
import PaginatedTableActions from '../../Table/PaginatedTableActions';
import UploadAccountResponse from './UploadAccountResponse';

const ViewSupplierCustomers = (props) => {

    const classes = useStyles(props);
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const roles = useSelector(state => state.auth.roles);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const pageSize = useSelector(state => state.supplierCustomer.pageSize);
    const pageNumber = useSelector(state => state.supplierCustomer.pageNumber);
    const supplierCustomers = useSelector(state => state.supplierCustomer.supplierCustomers);
    const direction = useSelector(state => state.supplierCustomer.direction);
    const orderBy = useSelector(state => state.supplierCustomer.orderBy);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        retrieveSupplierCustomers(pageNumber, pageSize, orderBy, direction);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveSupplierCustomers = async (pageNumber, pageSize, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction }
        await trackPromise(axios.get("/gas-service/supplier/" + params.supplierAccountId + "/customer", { params: { ...urlParams } }).then(response => {
            dispatch({ type: RETRIEVE_SUPPLIER_CUSTOMERS, supplierCustomers: response.data });
        }).catch(error => {
            dispatchEvent(onError(error.response));
        }))
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handlePageChange = (pageNumber) => {
        retrieveSupplierCustomers(pageNumber, pageSize, orderBy, direction);
    }

    const handleRowsPerPageChange = (e) => {
        dispatch({ type: SUPPLIER_CUSTOMER_PAGE_SIZE, pageSize: e.target.value });
        retrieveSupplierCustomers(0, e.target.value, orderBy, direction);
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        dispatch({ type: SUPPLIER_CUSTOMER_ORDER_BY, orderBy: column });
        dispatch({ type: SUPPLIER_CUSTOMER_DIRECTION, direction: sortDirection });
        retrieveSupplierCustomers(0, pageSize, column, sortDirection);
    }

    const handleCreateSupplierCustomer = () => {
        history.push("/gas/supplier/account/" + params.supplierAccountId + "/customer/create");
    }

    const handleDownloadAccountRequest = async () => {
        await trackPromise(axios.get("/gas-service/supplier/" + params.supplierAccountId + "/customer/WG/account", { responseType: 'arraybuffer' }).then(response => {
            if (response.status === 200) {
                let filenameHeader = response.headers['content-disposition'];
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filenameHeader.substring(filenameHeader.indexOf('=') + 2, filenameHeader.lastIndexOf('"')));
                document.body.appendChild(link);
                link.click();
                retrieveSupplierCustomers(pageNumber, pageSize, orderBy, direction);
            } else if (response.status === 204) {
                dispatch(onSuccess("Did not find any accounts to send to the utility."))
            }
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleAccountResponseUpload = () => {
        setIsOpen(true);
    }

    const handleAccountResponseUploadComplete = () => {
        retrieveSupplierCustomers(0, pageSize, orderBy, direction);
        setIsOpen(false);
    }

    return (
        <main className={classes.layout}>
            <Typography variant='h4' align='center' style={{ marginBottom: 25 }}>Supplier Customers</Typography>
            {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_gas_supplier_customer') &&
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={handleCreateSupplierCustomer} fullWidth>Create Supplier Customer</Button>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_generate_account_request') &&
                            <Button onClick={handleDownloadAccountRequest} fullWidth>Download Account Request</Button>
                        }
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_generate_account_request') &&
                            <Button fullWidth onClick={handleAccountResponseUpload}>Upload Account Response</Button>
                        }
                    </Grid>
                </Grid>}
            <UploadAccountResponse isOpen={isOpen} handleClose={() => setIsOpen(false)} handleUpload={handleAccountResponseUploadComplete} supplierAccountId={params.supplierAccountId} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel active={orderBy === "utilityAccountNumber"} direction={orderBy === "utilityAccountNumber" ? direction : 'asc'}
                                onClick={() => handleSort("utilityAccountNumber")}>
                                Utility Account Number
                                {orderBy === "utilityAccountNumber" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "accountName"} direction={orderBy === "accountName" ? direction : 'asc'}
                                onClick={() => handleSort("accountName")}>
                                Account Name
                                {orderBy === "accountName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "billingName"} direction={orderBy === "billingName" ? direction : 'asc'}
                                onClick={() => handleSort("billingName")}>
                                Billing Name
                                {orderBy === "billingName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "effectiveDate"} direction={orderBy === "effectiveDate" ? direction : 'asc'}
                                onClick={() => handleSort("effectiveDate")}>
                                Effective Date
                                {orderBy === "effectiveDate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "supplierCustomerStatus"} direction={orderBy === "supplierCustomerStatus" ? direction : 'asc'}
                                onClick={() => handleSort("supplierCustomerStatus")}>
                                Status
                                {orderBy === "supplierCustomerStatus" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supplierCustomers && supplierCustomers.results && supplierCustomers.results.length > 0 && supplierCustomers.results.map(supplierCustomer => {
                        return (
                            <TableRow key={supplierCustomer.id} className={classes.tableRow}
                                onClick={() => history.push("/gas/supplier/account/" + params.supplierAccountId + "/customer/" + supplierCustomer.id)}>
                                <TableCell>{supplierCustomer.utilityAccountNumber}</TableCell>
                                <TableCell>{supplierCustomer.accountName}</TableCell>
                                <TableCell>{supplierCustomer.billingName}</TableCell>
                                <TableCell>{supplierCustomer.effectiveDate}</TableCell>
                                <TableCell>{supplierCustomer.supplierCustomerStatus}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={5} count={supplierCustomers.totalCount ? supplierCustomers.totalCount : 0}
                            rowsPerPage={calculateRowsPerPage(pageSize)} page={supplierCustomers.pageNumber ? supplierCustomers.pageNumber : 0}
                            onPageChange={handlePageChange} onRowsPerPageChange={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions} />
                    </TableRow>
                </TableFooter>
            </Table>
        </main>
    )

}

export default ViewSupplierCustomers;