import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useHistory, useParams } from "react-router";
import useStyles from '../../../Styles';
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError } from "../../../store/actions/popupActions";
import { updateUser, updateUserAdmin } from '../../../store/user-management/actions/userActions';
import AccountRoleComponent from '../AccountRoleComponent';
import UserComponent from "../User/UserComponent";

const ViewUser = (props) => {

    const [user, setUser] = useState({
        username: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        accountIds: [],
        userAccountRoles: [],
        userApplicationRoles: [],
        superUser: false
    });
    const [canEdit, setCanEdit] = useState(false);
    const superUser = useSelector(state => state.auth.superUser);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();


    useEffect(() => {
        retrieveUserByUserId();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveUserByUserId = async () => {
        if (superUser) {
            await trackPromise(
                axios.get("/auth/admin/users/" + params.userId).then(response => {
                    setUser({ ...response.data });
                }).catch(error => {
                    dispatch(onError(error));
                }));
        } else {
            await trackPromise(
                axios.get("/auth/account/" + params.accountId + "/users/" + params.userId).then(response => {
                    setUser({ ...response.data })
                }).catch(error => {
                    this.props.onError(error);
                }));
        }
    }

    const onEditClick = () => {
        setCanEdit(true);
    };

    const onSaveClick = () => {
        if (superUser) {
            dispatch(updateUserAdmin(user));
        } else {
            dispatch(updateUser(params.accountId, user));
        }
        history.goBack();
    }

    const onInputChangeHandler = (inputUser) => {
        inputUser.userApplicationRoles = user.userApplicationRoles;
        inputUser.accountIds = user.accountIds;
        setUser({ ...inputUser });
    };

    const accountIdChangeHandler = (accountIds) => {
        user.accountIds = [...accountIds];
        setUser({ ...user });
    }

    const applicationRoleChangeHandler = (accountRoles) => {
        user.userApplicationRoles = [...accountRoles];
        setUser({ ...user });
    }

    const renderButtons = () => {
        if (canEdit) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onSaveClick}>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={() => history.goBack()}>Back</Button>
                    </Grid>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        {hasPermissionForAccount(roles, chosenAccount.id, "ROLE_update_user_role") &&
                            <Button onClick={onEditClick}>Edit</Button>}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={() => history.goBack()}>Back</Button>
                    </Grid>
                </React.Fragment>
            );
        }
    }

    return (
        <main className={styles.layout}>
            {user &&
                <React.Fragment>
                    <Typography variant="h4" align="center">{user ? user.firstName + " " + user.lastName : 'NA'}</Typography>
                    <UserComponent canEdit={canEdit} inputChangeHandler={onInputChangeHandler} accountId={params.accountId} user={user}
                        displayUsername={true} />
                    <AccountRoleComponent accounts={user.accountIds} userApplicationRoles={user.userApplicationRoles} canEdit={canEdit}
                        accountIdChangeHandler={accountIdChangeHandler} applicationRoleChangeHandler={applicationRoleChangeHandler} />
                    <Grid container spacing={2}>
                        {renderButtons()}
                    </Grid>
                </React.Fragment>}
        </main>
    );
}

export default ViewUser;