import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect } from 'react';
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useStyles from "../../Styles";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";
import { AVAILABLE_GENERATORS_RETREIVED, DAY_AHEAD_RESULTS_DIRECTION, DAY_AHEAD_RESULTS_ORDER_BY, DAY_AHEAD_RESULTS_PAGE_SIZE, DAY_AHEAD_RESULTS_RETRIEVED, DAY_AHEAD_SEARCH_END_DATE, DAY_AHEAD_SEARCH_START_DATE, DAY_AHEAD_SEARCH_UNIT } from "../../store/energy-markets/energyMarketActionTypes";
import PaginatedTableActions from "../Table/PaginatedTableActions";


const DayAheadMarketResults = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const results = useSelector(state => state.energyMarket.dayAheadResults);
    const startDate = useSelector(state => state.energyMarket.dayAheadSearchStartDate);
    const endDate = useSelector(state => state.energyMarket.dayAheadSearchEndDate);
    const searchUnit = useSelector(state => state.energyMarket.dayAheadSearchUnit);
    const pageSize = useSelector(state => state.energyMarket.dayAheadResultsPageSize);
    const direction = useSelector(state => state.energyMarket.dayAheadResultsDirection);
    const orderBy = useSelector(state => state.energyMarket.dayAheadResultsOrderBy);
    const availableGenerators = useSelector(state => state.energyMarket.availableGenerators);

    useEffect(() => {
        retrieveAvailableGenerators();
        let date = new Date();
        date.setDate(date.getDate() + 1);
        dispatch({ type: DAY_AHEAD_SEARCH_START_DATE, dayAheadSearchStartDate: new Date() });
        dispatch({ type: DAY_AHEAD_SEARCH_END_DATE, dayAheadSearchEndDate: date });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveAvailableGenerators = async () => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + params.accountId + "/generation/unit?pageNumber=0&pageSize=100").then(response => {
            dispatch({ type: AVAILABLE_GENERATORS_RETREIVED, availableGenerators: response.data.results });
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const startDateChangeHandler = (date) => {
        dispatch({ type: DAY_AHEAD_SEARCH_START_DATE, dayAheadSearchStartDate: date });
    }

    const endDateChangeHandler = (date) => {
        dispatch({ type: DAY_AHEAD_SEARCH_END_DATE, dayAheadSearchEndDate: date });
    }

    const doDayAheadSearch = async (pageNumber, pageSize, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction };
        urlParams['startDate'] = moment(startDate).format("YYYY-MM-DD");
        urlParams['endDate'] = moment(endDate).format("YYYY-MM-DD");
        if (searchUnit) {
            urlParams['location'] = searchUnit.location;
        }
        await trackPromise(axios.get("/marketsgateway/v2/account/" + params.accountId + "/da/generation/results", { params: { ...urlParams } })
            .then(response => {
                dispatch({ type: DAY_AHEAD_RESULTS_RETRIEVED, dayAheadResults: response.data });
            }).catch(error => {
                dispatch(onError(error));
            }));
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        dispatch({ type: DAY_AHEAD_RESULTS_DIRECTION, dayAheadResultsDirection: sortDirection });
        dispatch({ type: DAY_AHEAD_RESULTS_ORDER_BY, dayAheadResultsOrderBy: column });
        doDayAheadSearch(0, pageSize, column, sortDirection);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        doDayAheadSearch(pageNumber, pageSize, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 24;
        }
        if (rowsPerPage <= 24) {
            return 24;
        }
        if (rowsPerPage <= 48) {
            return 48;
        }
        if (rowsPerPage <= 72) {
            return 72;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        dispatch({ type: DAY_AHEAD_RESULTS_PAGE_SIZE, dayResultsPageSize: e.target.value });
        doDayAheadSearch(0, e.target.value, orderBy, direction);
    }

    return (
        <main className={classes.layout}>
            <form onSubmit={(e) => {
                e.preventDefault();
                doDayAheadSearch(0, pageSize)
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                onChange={(date) => startDateChangeHandler(date)} value={startDate} fullWidth
                                autoOk={true} maxDate={endDate ? endDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                onChange={(date) => endDateChangeHandler(date)} value={endDate} fullWidth
                                autoOk={true} minDate={startDate ? startDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <Autocomplete value={searchUnit} options={availableGenerators} getOptionLabel={(option) => option ? option.unitName : ''}
                            renderInput={(params) => <TextField {...params} label="Unit Name" />} onChange={(event, value) => dispatch({ type: DAY_AHEAD_SEARCH_UNIT, dayAheadSearchUnit: value })} />
                    </Grid>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} align="center">
                        <Button type="submit" style={{ minHeight: '3em' }} variant="contained">Search</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </form>
            <Table>
                <TableHead sx={{ width: "max-content" }}>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel active={orderBy === "hourEndingDate"} direction={orderBy === "hourEndingDate" ? direction : 'asc'} onClick={() => handleSort("hourEndingDate")}>
                                Hour Ending Date
                                {orderBy === "hourEndingDate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "hourEnding"} direction={orderBy === "hourEnding" ? direction : 'asc'} onClick={() => handleSort("hourEnding")}>
                                Hour Ending
                                {orderBy === "hourEnding" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "location"} direction={orderBy === "location" ? direction : 'asc'} onClick={() => handleSort("location")}>
                                Resource Name
                                {orderBy === "location" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "clearedMw"} direction={orderBy === "clearedMw" ? direction : 'asc'} onClick={() => handleSort("clearedMw")}>
                                Cleared MWs
                                {orderBy === "clearedMw" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "clearedPrice"} direction={orderBy === "clearedPrice" ? direction : 'asc'} onClick={() => handleSort("clearedPrice")}>
                                Clearing Price
                                {orderBy === "clearedPrice" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "totalRevenue"} direction={orderBy === "totalRevenue" ? direction : 'asc'} onClick={() => handleSort("totalRevenue")}>
                                Total
                                {orderBy === "totalRevenue" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Schedule</TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "constraintName"} direction={orderBy === "constraintName" ? direction : 'asc'} onClick={() => handleSort("constraintName")}>
                                Constraint Name
                                {orderBy === "constraintName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "contigencyName"} direction={orderBy === "contigencyName" ? direction : 'asc'} onClick={() => handleSort("contigencyName")}>
                                Contigency Name
                                {orderBy === "contigencyName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "onReason"} direction={orderBy === "onReason" ? direction : 'asc'} onClick={() => handleSort("onReason")}>
                                Reason
                                {orderBy === "onReason" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "priceCapped"} direction={orderBy === "priceCapped" ? direction : 'asc'} onClick={() => handleSort("priceCapped")}>
                                Price Capped
                                {orderBy === "priceCapped" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results && results.results && results.results.length > 0 && results.results.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell>{r.hourEndingDateTime.hourEndingDate}</TableCell>
                                <TableCell>{r.hourEndingDateTime.hourEnding}</TableCell>
                                <TableCell>{r.resourceName ? r.resourceName : r.location}</TableCell>
                                <TableCell><NumberFormat value={r.clearedMw} displayType='text' fixedDecimalScale={true} decimalScale={2} thousandSeparator />
                                </TableCell>
                                <TableCell><NumberFormat value={r.clearedPrice} displayType={"text"} fixedDecimalScale={true} decimalScale={2} prefix={"$"}
                                    thousandSeparator={true} /></TableCell>
                                <TableCell><NumberFormat value={r.totalRevenue} displayType={"text"} fixedDecimalScale={true} decimalScale={2} prefix={"$"}
                                    thousandSeparator={true} /></TableCell>
                                <TableCell>{r.scheduleName ? r.scheduleName : r.schedule}</TableCell>
                                <TableCell>{r.constraintName}</TableCell>
                                <TableCell>{r.contigencyName}</TableCell>
                                <TableCell>{r.onReason}</TableCell>
                                <TableCell>{r.priceCapped.toString()}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination count={results.totalCount ? results.totalCount : 0} onPageChange={handlePageChange}
                            page={results.pageNumber ? results.pageNumber : 0}
                            rowsPerPage={calculateRowsPerPage(results.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={[24, 48, 72]} colSpan={11} ActionsComponent={PaginatedTableActions} />
                    </TableRow>
                </TableFooter>
            </Table>
        </main>
    )
}

export default DayAheadMarketResults;