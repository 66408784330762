import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment/moment";
import React, { useEffect, useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";
import MonthlyBillExplanationComponent from "./MonthlyBillExplanationComponent";


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
        padding: "5px 0px 20px 0px"
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        background: 'white'
    }
}));

const PjmBilling = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [weeklyBillPeriods, setWeeklyBillPeriods] = useState([]);
    const [weeklyPeriod, setWeeklyPeriod] = useState({});
    const [includeMonthToDate, setIncludeMonthToDate] = useState(false);
    const [billingYear, setBillingYear] = useState('');
    const [billingMonth, setBillingMonth] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [monthlyBill, setMonthlyBill] = useState();

    const billingMonthMenuItems = [{ "name": "January", "value": 1 }, { "name": "February", "value": 2 }, { "name": "March", "value": 3 }, { "name": "April", "value": 4 }, { "name": "May", "value": 5 }, { "name": "June", "value": 6 }, { "name": "July", "value": 7 }, { "name": "August", "value": 8 }, { "name": "September", "value": 9 }, { "name": "October", "value": 10 }, { "name": "November", "value": 11 }, { "name": "December", "value": 12 }];

    useEffect(() => {
        setStartDate(new Date());
        setEndDate(new Date());
        retrieveWeeklyBillPeriods(new Date().getFullYear());
        setMonthlyBill();
        // eslint-disable-next-line
    }, [params.accountId]);

    const retrieveWeeklyBillPeriods = async (year) => {
        await trackPromise(axios.get("/pjm/billing/weekly/period?year=" + year)).then(response => {
            if (response.data) {
                setWeeklyBillPeriods(response.data);
            }
        }).catch(error => {
            dispatch(onError(error));
        });
    }

    const renderWeeklyPeriodValue = (value) => {
        if (!value || !value.startDate) {
            return '';
        }
        return value.startDate + " - " + value.endDate;
    }

    const renderWeeklyBillPeriodsOptions = () => {
        let options = [];
        if (weeklyBillPeriods && weeklyBillPeriods.length > 0) {
            weeklyBillPeriods.forEach(wbp => {
                options.push(<MenuItem key={wbp.startDate} value={wbp}>{wbp.startDate + " - " + wbp.endDate}</MenuItem>);
            })
        }
        return options;
    }

    const handleWeeklyBillPeriodChange = (e) => {
        let selectedPeriod = e.target.value;
        setStartDate(moment(selectedPeriod.startDate));
        setEndDate(moment(selectedPeriod.endDate));
        setWeeklyPeriod(selectedPeriod);
        if (includeMonthToDate) {
            setStartDate(moment(selectedPeriod.startDate).startOf('month'));
        }
        setBillingYear('');
        setBillingMonth({});
    }

    const determineWeeklyPeriodValue = () => {
        if (!weeklyPeriod || !weeklyPeriod.startDate) {
            return '';
        }
        return weeklyPeriod;
    }

    const handleIncludeMonthToDateChange = (e) => {
        if (e.target.checked === false) {
            setIncludeMonthToDate(false);
            if (weeklyPeriod && weeklyPeriod.startDate) {
                setStartDate(moment(weeklyPeriod.startDate));
            }
        }
        if (e.target.checked === true) {
            setIncludeMonthToDate(true);
            if (weeklyPeriod && weeklyPeriod.startDate) {
                setStartDate(startDate.startOf('month'));
            }
        }
    }

    const renderBillingYearOptions = () => {
        const currentYear = new Date().getFullYear();
        let startingYear = currentYear - 4;
        let yearOptions = [];
        while (startingYear <= currentYear) {
            yearOptions.push(<MenuItem key={startingYear} value={startingYear}>{startingYear}</MenuItem>);
            startingYear++;
        }
        return yearOptions;
    }

    const handleBillingMonthChange = (e) => {
        setBillingMonth(e.target.value);
        if (billingYear) {
            let start = moment({ year: billingYear, month: e.target.value.value - 1, day: 1 })
            setStartDate(start);
            setEndDate(start.clone().endOf('month'));
        }
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
    }

    const handleBillingYearChange = (e) => {
        setBillingYear(e.target.value);
        if (billingMonth && billingMonth.name) {
            let start = moment({ year: e.target.value, month: billingMonth.value - 1, day: 1 })
            setStartDate(start);
            setEndDate(start.clone().endOf('month'));
        }
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
    }

    const renderBillingMonthValue = (value) => {
        if (value && value.name) {
            return value.name;
        }
        return '';
    }

    const determineBillingMonthValue = () => {
        if (billingMonth && billingMonth.name) {
            return billingMonth;
        }
        return '';
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
        setBillingYear('');
        setBillingMonth({});
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
        setBillingYear('');
        setBillingMonth({});
    }

    const doSearch = async () => {
        let current = new Date();
        setMonthlyBill({});
        if (billingYear && billingMonth && ((billingYear === current.getFullYear() && billingMonth.value <= current.getMonth()) ||
            (billingYear < current.getFullYear()))) {
            await trackPromise(axios.get("/account/" + params.accountId + "/v1/pjm/billing/monthly/explanation/billingYear/" + billingYear + "/billingMonth/" + billingMonth.name).then(response => {
                setMonthlyBill(response.data);
            }).catch(error => {
                dispatch(onError(error));
            }));
        }
    }



    return (
        <main className={classes.layout}>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Weekly Billing Period</InputLabel>
                        <Select value={determineWeeklyPeriodValue()} renderValue={(value) => renderWeeklyPeriodValue(value)} label="Weekly Billing Period" onChange={handleWeeklyBillPeriodChange} >
                            {renderWeeklyBillPeriodsOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel control={<Checkbox name="isMonthToDate" checked={includeMonthToDate}
                        onChange={handleIncludeMonthToDateChange} />} label="Include Month to Date" />
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Billing Year</InputLabel>
                        <Select value={billingYear} label="Billing Year" onChange={handleBillingYearChange}>
                            {renderBillingYearOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Billing Month</InputLabel>
                        <Select value={determineBillingMonthValue()} label="Billing Month" renderValue={(value) => renderBillingMonthValue(value)}
                            onChange={handleBillingMonthChange}>
                            {
                                billingMonthMenuItems.map(menuItem => {
                                    return (
                                        <MenuItem value={menuItem} key={menuItem.value}>{menuItem.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={(date) => handleStartDateChange(date)}
                            value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate" onChange={(date) => handleEndDateChange(date)}
                            value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={5} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={doSearch}>Search</Button>
                </Grid>
                <Grid item xs={6} sm={5} />
            </Grid>
            {monthlyBill && <MonthlyBillExplanationComponent monthlyBill={monthlyBill} accountId={params.accountId} billingMonth={billingMonth} billingYear={billingYear} />}
        </main >
    )

};

export default PjmBilling;