import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles, withTheme } from '@material-ui/core/styles';
import { default as logo } from "../../images/WhitePineJustName.svg";
import { APPLICATION_CHOSEN } from "../../store/actionTypes";
import { chooseAccount } from "../../store/actions/chosenAccountActions";
import EnergyMarketAppBar from '../EnergyMarkets/EnegyMarketAppBar/EnergyMarketAppBar';
import FercEqrAppBar from '../FercEqr/FercEqrAppBar/FercEqrAppBar';
import GasSupplierAppBar from '../GasSupplier/GasSupplierAppBar';
import SolarForecastAppBar from '../SolarForecast/SolarForecastAppBar/SolarForecastAppBar';
import CapacityExchangeAppBar from "../capacity-exchange/CapacityExchangeAppBar/CapacityExchangeAppBar";
import PeakManagementAppBar from "../peak-management/PeakManagementAppBar/PeakManagementAppBar";
import PjmBillingAppBar from '../pjm-billing/PjmBillingAppBar';
import UserManagementAppBar from '../user-management/AppBar/UserManagementAppBar';
import AccountChooser from "./AccountChooser";
import ApplicationDrawer from "./ApplicationDrawer";
import UserProfile from "./UserProfile";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    grow: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
});

const WPECAppBar = (props) => {

    const { classes } = props;

    useEffect(() => {
        if (props.accounts.length >= 1) {
            props.chooseAccount(props.accounts[0], true);
        }
    });

    const displayApplicationBar = () => {
        if (props.location.pathname.search("user-management") !== -1) {
            return (<UserManagementAppBar />);
        }
        if (props.location.pathname.search("capacity") !== -1) {
            return (<CapacityExchangeAppBar />);
        }
        if (props.location.pathname.search("peak-management") !== -1) {
            return (<PeakManagementAppBar />);
        }
        if (props.location.pathname.search("billing") !== -1) {
            return (<PjmBillingAppBar />);
        }
        if (props.location.pathname.search("ferc/eqr") !== -1) {
            return (<FercEqrAppBar />);
        }
        if (props.location.pathname.search("markets") !== -1) {
            return (<EnergyMarketAppBar />);
        }
        if (props.location.pathname.search("gas/supplier") !== -1) {
            return (<GasSupplierAppBar />);
        }
        if (props.location.pathname.search("solar-forecast") !== -1) {
            return (<SolarForecastAppBar />)
        }
    }

    let applicationBar = displayApplicationBar();

    return (
        <AppBar position="relative" color='default'>
            <Toolbar>
                <img src={logo} alt='' height='50px' />
                <div>
                    <ApplicationDrawer />
                    {applicationBar}
                </div>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    <AccountChooser />
                    <UserProfile />
                </div>
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles,
        accounts: state.auth.accounts,
        chosenApplication: state.chosenApplication
    };
};

const mapDispatchToProps = dispatch => {
    return {
        chooseAccount: (account, isDefault) => dispatch(chooseAccount(account, isDefault)),
        chooseDefaultApplication: () => dispatch({ type: APPLICATION_CHOSEN, application: 'pricing-service' })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(withRouter(WPECAppBar))));