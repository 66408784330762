import { Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import { RETRIEVE_EQR_ACCOUNTS, SELECT_EQR_ACCOUNT } from "../../../store/ferc-eqr/FercEqrActionTypes";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewEqrAccounts = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const eqrAccounts = useSelector(state => state.fercEqr.eqrAccounts);

    useEffect(() => {
        if (!eqrAccounts || eqrAccounts.length < 1) {
            retrieveEqrAccounts();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveEqrAccounts = async () => {
        await trackPromise(axios.get("/ferc/eqr/eqr-account").then(response => {
            dispatch({ type: RETRIEVE_EQR_ACCOUNTS, eqrAccounts: response.data });
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const handleRowClick = (e, eqrAccount) => {
        dispatch({ type: SELECT_EQR_ACCOUNT, eqrAccount: eqrAccount });
        history.push("/ferc/eqr/eqr-account/" + eqrAccount.id);
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h5" align="center" style={{ marginBottom: 50 }}>FERC EQR Accounts</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>EQR Company Filing Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eqrAccounts && eqrAccounts.length > 0 && eqrAccounts.map(r => {
                        return (
                            <TableRow key={r.id} className={classes.tableRow} onClick={(e, eqrAccount) => { handleRowClick(e, r) }}>
                                <TableCell>{r.companyName}</TableCell>
                            </TableRow>
                        )
                    })
                    }
                </TableBody>
            </Table>
        </main>

    )
}

export default ViewEqrAccounts;