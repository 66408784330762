import { Button, FormControl, Grid, InputAdornment, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import axios from "../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../store/actions/popupActions";
import { cleanStringInput } from "../../Utility/StringUtil";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
        padding: "5px 0px 20px 0px"
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        background: 'white'
    }
}));

const CreateInvoiceReconciliation = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [invoiceRecon, setInvoiceRecon] = useState({});

    const billingMonthMenuItems = [{ "name": "January", "value": 1 }, { "name": "February", "value": 2 }, { "name": "March", "value": 3 }, { "name": "April", "value": 4 }, { "name": "May", "value": 5 }, { "name": "June", "value": 6 }, { "name": "July", "value": 7 }, { "name": "August", "value": 8 }, { "name": "September", "value": 9 }, { "name": "October", "value": 10 }, { "name": "November", "value": 11 }, { "name": "December", "value": 12 }];

    const onInputChange = (e, index) => {
        let recon = invoiceRecon;
        if (e.target.type === "number") {
            if (e.target.value) {
                recon[e.target.name] = Number.parseFloat(e.target.value);
            } else {
                recon[e.target.name] = e.target.value;
            }
        } else {
            recon[e.target.name] = e.target.value;
        }
        setInvoiceRecon({ ...recon });
    }

    const renderBillingMonthValue = (value) => {
        if (value && value.name) {
            return value.name;
        }
        return '';
    }

    const determineBillingMonthValue = () => {
        if (invoiceRecon && invoiceRecon.billingMonth && invoiceRecon.billingMonth.name) {
            return invoiceRecon.billingMonth;
        }
        return '';
    }

    const handleBillingMonthChange = (e) => {
        let recon = invoiceRecon;
        recon.billingMonth = e.target.value;
        setInvoiceRecon({ ...recon });
    }

    const saveInvoiceReconciliation = async () => {
        let recon = invoiceRecon;
        recon.billingMonth = invoiceRecon.billingMonth.name;
        await trackPromise(axios.post("/account/" + params.accountId + "/v1/pjm/billing/invoice/reconciliation", JSON.stringify(recon)).then(response => {
            dispatch(onSuccess("Invoice Reconciliation created successfully"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={3}>
                    <TextField label="Year (yyyy)" name="billingYear" value={cleanStringInput(invoiceRecon.billingYear)}
                        onChange={onInputChange} fullWidth type="number" required />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth required>
                        <InputLabel>Month</InputLabel>
                        <Select value={determineBillingMonthValue()} label="Billing Month" renderValue={(value) => renderBillingMonthValue(value)}
                            onChange={handleBillingMonthChange}>
                            {
                                billingMonthMenuItems.map(menuItem => {
                                    return (
                                        <MenuItem value={menuItem} key={menuItem.value}>{menuItem.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={3}>
                    <TextField label="Distribution Load" name="transmissionMonthEndLoad" value={cleanStringInput(invoiceRecon.transmissionMonthEndLoad)} onChange={onInputChange} fullWidth type="number" InputProps={{ endAdornment: <InputAdornment position="start">MWhs</InputAdornment> }} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={3}>
                    <TextField label="Indepenent Meter Read" name="thirdPartyMeterRead" value={cleanStringInput(invoiceRecon.thirdPartyMeterRead)} onChange={onInputChange} fullWidth type="number" InputProps={{ endAdornment: <InputAdornment position="start">MWhs</InputAdornment> }} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={3}>
                    <TextField label="Distribution Company" name="transmissionProvider" value={cleanStringInput(invoiceRecon.transmissionProvider)} onChange={onInputChange} fullWidth />
                </Grid>
                <Grid item xs={6} sm={5} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={3}>
                    <Button onClick={saveInvoiceReconciliation} fullWidth>Save</Button>
                </Grid>
            </Grid>
        </main>
    );

}

export default CreateInvoiceReconciliation;