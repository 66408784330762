import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import Plot from "react-plotly.js";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    minMaxPeakText: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const LoadComponent = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [pjmZones, setPjmZones] = useState();
    const [zones, setZones] = useState([]);
    const [includeForecast, setIncludeForecast] = useState(false);
    const [includeInstantaneous, setIncludeInstantaneous] = useState(false);
    const [includeMetered, setIncludeMetered] = useState(false);
    const [instantaneousPlotData, setInstantaneousPlotData] = useState([]);
    const [forecastPlotData, setForecastPlotData] = useState([]);
    const [meteredPlotData, setMeteredPlotData] = useState([]);

    useEffect(() => {
        setStartDate(new Date());
        setEndDate(new Date());
        retrieveZones();
        // eslint-disable-next-line
    }, [])

    const retrieveZones = async () => {
        await trackPromise(axios.get("/operational-data/pjm/v1/dataminer-zones")).then(response => {
            setPjmZones(response.data.sort((a, b) => a.zoneCommonName.localeCompare(b.zoneCommonName)));
        }).catch(error => {
            dispatch(onError(error));
        })
    }

    const doSearch = async () => {
        if (zones && zones.length > 0) {
            const startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            const endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
            const duration = (Math.abs(startDateUtc - endDateUtc)) / (1000 * 60 * 60);
            let urlParams = "?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD") + "&pageNumber=0" +
                "&pageSize=" + zones.length * duration + "&zones=" + zones.map(r => r.zoneCommonName);
            setForecastPlotData([]);
            setInstantaneousPlotData([]);
            setMeteredPlotData([]);
            if (includeInstantaneous) {
                retrieveInstantaneousLoad(urlParams);
            }
            if (includeForecast) {
                retrieveForecastedLoad(urlParams);
            }
            if (includeMetered) {
                retrieveMeteredLoad(urlParams);
            }
        }
    }

    const retrieveInstantaneousLoad = async (urlParams) => {
        await trackPromise(axios.get("/operational-data/pjm/v1/load/instantaneous/hourly" + urlParams)).then(response => {
            if (response.data && response.data.results && response.data.results.length > 0) {
                let loads = response.data.results;
                let loadByZone = loads.reduce((r, a) => {
                    r[a.area] = r[a.area] || [];
                    r[a.area].push(a);
                    return r;
                }, Object.create(null));
                let keys = Object.keys(loadByZone);
                let data = [];
                keys.forEach(key => data.push(
                    {
                        x: loadByZone[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                        y: loadByZone[key].map(r => r.instantaneousLoad),
                        type: 'line',
                        connectgaps: false,
                        name: key + " Instantaneous Load",
                        showlegend: true
                    }
                ))
                setInstantaneousPlotData(data);
            }
        }).catch(error => {
            dispatch(onError(error));
        })
    }

    const retrieveForecastedLoad = async (urlParams) => {
        urlParams = urlParams + "&version=LATEST&groupByMarketRegion=false";
        await trackPromise(axios.get("/operational-data/pjm/v1/load/forecast" + urlParams)).then(response => {
            if (response.data && response.data.results && response.data.results.length > 0) {
                let forecasts = response.data.results;
                let forecastByZone = forecasts.reduce((r, a) => {
                    r[a.area] = r[a.area] || [];
                    r[a.area].push(a);
                    return r;
                }, Object.create(null));
                let keys = Object.keys(forecastByZone);
                let data = [];
                keys.forEach(key => {
                    data.push({
                        x: forecastByZone[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                        y: forecastByZone[key].map(r => r.load),
                        type: 'line',
                        connectgaps: false,
                        name: key + " Forecast Load",
                        showlegend: true
                    })
                });
                setForecastPlotData(data);
            }
        }).catch(error => {
            dispatch(onError(error));
        })
    }

    const retrieveMeteredLoad = async (urlParams) => {
        await trackPromise(axios.get("/operational-data/pjm/v1/load/metered" + urlParams)).then(response => {
            if (response.data && response.data.results && response.data.results.length > 0) {
                let loads = response.data.results;
                let loadByZone = loads.reduce((r, a) => {
                    r[a.zone] = r[a.zone] || [];
                    r[a.zone].push(a);
                    return r;
                }, Object.create(null));
                let keys = Object.keys(loadByZone);
                let data = [];
                keys.forEach(key => {
                    data.push({
                        x: loadByZone[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                        y: loadByZone[key].map(r => r.load),
                        type: 'line',
                        connectgaps: false,
                        name: key + " Metered load",
                        showlegend: true
                    })
                });
                setMeteredPlotData(data);
            }
        }).catch(error => {
            dispatch(onError(error));
        })
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={(date) => setStartDate(date)}
                            value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate" onChange={(date) => setEndDate(date)}
                            value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={6}>
                    {pjmZones && pjmZones.length > 0 &&
                        <Autocomplete multiple options={pjmZones} renderInput={(params) => <TextField {...params} label="Zones" />} getOptionLabel={(option) => option.zonePrettyName + " (" + option.zoneCommonName + ")"} onChange={(event, value) => setZones([...value])} value={zones} fullWidth />
                    }
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={5}>
                    <FormGroup row>
                        <FormControlLabel control={<Checkbox checked={includeForecast} onChange={() => setIncludeForecast(!includeForecast)} />} label="Forecasted Load" />
                        <FormControlLabel control={<Checkbox checked={includeInstantaneous} onChange={() => setIncludeInstantaneous(!includeInstantaneous)} />} label="Instaneous Load" />
                        <FormControlLabel control={<Checkbox checked={includeMetered} onChange={() => setIncludeMetered(!includeMetered)} />} label="Metered Load" />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={doSearch} fullWidth>Search</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={12}>
                    {((instantaneousPlotData && instantaneousPlotData.length > 0) || (forecastPlotData && forecastPlotData.length > 0) || (meteredPlotData && meteredPlotData.length > 0)) &&
                        <Plot data={[...instantaneousPlotData, ...forecastPlotData, ...meteredPlotData]}
                            layout={{
                                width: 1200,
                                height: 1000,
                                title: 'Hourly Load',
                                showLegend: true,
                                xaxis: { title: { text: 'Date (Hour Ending)' } },
                                yaxis: { title: { text: 'Load (MWs)' } },
                                legend: { orientation: "h" }
                            }} config={{ displaylogo: false }} />
                    }
                </Grid>
            </Grid>
        </main>);

}

export default LoadComponent;