import { Button } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";


const EnergyMarketAppBar = () => {

    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const history = useHistory();

    const buildDayAheadMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, "ROLE_retrieve_da_generation")) {
            return (<Button onClick={() => history.push("/markets/account/" + chosenAccount.id + "/energy/day-ahead")}>Day Ahead Results</Button>);
        }
    }

    const buildDataRetrievalMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, "ROLE_trigger_data_retrieval")) {
            return (<Button onClick={() => history.push("/markets/account/" + chosenAccount.id + "/energy/data-retrieval")}>Data Retreivals</Button>);
        }
    }

    const buildGeneratorUnitMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_generator_unit") || hasPermissionForAccount(roles, chosenAccount.id, "ROLE_retrieve_generator_unit")) {
            return (<Button onClick={() => history.push("/markets/account/" + chosenAccount.id + "/energy/generator/unit")}>Generators</Button>);
        }
    }

    const buildUnitDetailsMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, "ROLE_retrieve_unit_details")) {
            return (<Button onClick={() => history.push("/markets/account/" + chosenAccount.id + "/energy/generator/unit/details")}>Unit Details</Button>);
        }
    }

    return (
        <React.Fragment>
            {buildDayAheadMenu()}
            {buildUnitDetailsMenu()}
            {buildGeneratorUnitMenu()}
            {buildDataRetrievalMenu()}
        </React.Fragment>
    )
}

export default EnergyMarketAppBar;