import { List, SwipeableDrawer } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { useEffect } from "react";
import { hasApplicationAccessForCurrentAccount, hasPermissionForAccount } from "../../Utility/PermissionsUtil";
import { APPLICATION_CHOSEN } from "../../store/actionTypes";
import { retrieveFercEqrAccountForWpecAccount } from "../../store/ferc-eqr/FercEqrActions";

const ApplicationDrawer = (props) => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const roles = useSelector(state => state.auth.roles);
    const chosenEqrAccount = useSelector(state => state.fercEqr.chosenEqrAccount);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const dispatch = useDispatch();
    const classes = (props);
    const history = useHistory();

    useEffect(() => {
        if (hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "ferc-eqr-service")) {
            dispatch(retrieveFercEqrAccountForWpecAccount(chosenAccount.id));
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenAccount])

    const handleDrawerClick = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(!drawerOpen);
    };

    const determineEqrServicePath = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_retrieve_eqr_account')) {
            return "/ferc/eqr/eqr-account";
        }
        return "/ferc/eqr/eqr-account/" + chosenEqrAccount.id + "/submissions";
    }

    return (
        <IconButton onClick={handleDrawerClick}>
            <MenuIcon />
            <SwipeableDrawer anchor='left' onOpen={handleDrawerClick} onClose={handleDrawerClose} open={drawerOpen}>
                <List className={classes.list}>
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "account-service") &&
                        <ListItem button key='AccountManagement' name="account-management" onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'account-management' });
                            history.push("/account-management");
                        }}>
                            <ListItemText>Account Management</ListItemText>
                        </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "capacity-exchange") &&
                        <ListItem button key='CapacityExchange' name="capacity-exchange" onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'capacity-exchange' });
                            history.push("/capacity/deliveryYearAuctions/view");
                        }}>
                            <ListItemText>Capacity Exchange</ListItemText>
                        </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "markets-gateway-service") &&
                        <ListItem button key='Energy Markets' name="energy-markets" onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'energy-markets' });
                            history.push("/markets/account/" + chosenAccount.id + "/energy/day-ahead");
                        }}>
                            <ListItemText>Energy Markets</ListItemText>
                        </ListItem>

                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "ferc-eqr-service") &&
                        <ListItem button key='FercEqrService' name="ferc-eqr-service"
                            onClick={() => {
                                dispatch({ type: APPLICATION_CHOSEN, application: 'ferc-eqr-service' });
                                history.push(determineEqrServicePath());
                            }}>
                            <ListItemText>FERC EQRs</ListItemText>
                        </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, 'gas-supplier-service') &&
                        <ListItem button key="GasSupplierService" name="gas-supplier" onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'gas-supplier' });
                            history.push("/gas/supplier/account");
                        }}>
                            <ListItemText>Gas Supplier</ListItemText>
                        </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, 'tangent-service') &&
                        <ListItem button key='MeterReading' name="meter-reading" onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'meter-reading' });
                            history.push("/meter-reading/account/" + chosenAccount.id);
                        }}>
                            <ListItemText>Meter Reading</ListItemText>
                        </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, 'peak-management-service') &&
                        <ListItem button key='PeakManagementService' name="peak-management-service" onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'peak-management-service' });
                            history.push("/peak-management");
                        }}>
                            <ListItemText>Peak Management</ListItemText>
                        </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "billing-estimator") &&
                        <ListItem button key="BillingManagement" name="pjm-billing" onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: "pjm-billing" });
                            history.push("/billing/account/" + chosenAccount.id + "/pjm");
                        }}>
                            <ListItemText>PJM Billing</ListItemText>
                        </ListItem>
                    }
                    {
                        <ListItem button key='PricingService' name='pricing-service' onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: "pricing-service" });
                            history.push("/pricing");
                        }
                        }>
                            <ListItemText>Pricing</ListItemText>
                        </ListItem>
                    }
                    {
                        <ListItem button key='LoadService' name='load-service' onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: "load-service" });
                            history.push("/load");
                        }
                        }>
                            <ListItemText>RTO Load</ListItemText>
                        </ListItem>
                    }
                    {
                        hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "solar-forecast-service") &&
                        <ListItem button key="SolarForecastService" name='solar-forecast-service' onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'solar-forecast-service' });
                            history.push("/solar-forecast/account/" + chosenAccount.id + "/location");
                        }}>
                            <ListItemText>Solar Forecast</ListItemText>
                        </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "user-service") &&
                        <ListItem button key='UserManagement' name='user-management' onClick={() => {
                            dispatch({ type: APPLICATION_CHOSEN, application: 'user-management' });
                            history.push("/user-management");
                        }}>
                            <ListItemText>User Management</ListItemText>
                        </ListItem>
                    }
                </List>
            </SwipeableDrawer>
        </IconButton>
    );
};

export default ApplicationDrawer;