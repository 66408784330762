import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import { retrieveDeliveryYearAuctions } from "../../../store/capacity-exchange/actions/CapacityExchangeActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const AvailableResources = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const params = useParams();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const [deliveryYear, setDeliveryYear] = useState('');
    const [availableResources, setAvailableResources] = useState([]);
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();


    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                    getOptionLabel={(option) => option} id="deliveryYear"
                    renderInput={(params) => <TextField {...params} label="Delivery Year" />}
                    onChange={(event, value) => onDeliveryYearSelectChange(event, value)} />
            );
        }
    }

    const onDeliveryYearSelectChange = (event, value) => {
        setAvailableResources(null);
        if (value) {
            setDeliveryYear(value);
            retrieveAvailableResources(value);
        } else {
            setDeliveryYear('');
        }
    }

    const retrieveAvailableResources = async (deliveryYear) => {
        console.log(deliveryYear, deliveryYear < '2023/2024');
        await trackPromise(axios.get("/capacity/account/" + params.accountId + "/offer/available?deliveryYear=" + deliveryYear.replace("/", "-")).then(response => {
            setAvailableResources(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const handleSort = (column, columnType) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        if (sortDirection === 'asc') {
            if (columnType === 'text') {
                availableResources.sort((c1, c2) => c1[column].localeCompare(c2[column]));
            } else {
                availableResources.sort((c1, c2) => c1[column] - c2[column]);
            }
        } else {
            if (columnType === 'text') {
                availableResources.sort((c1, c2) => c2[column].localeCompare(c1[column]));
            } else {
                availableResources.sort((c1, c2) => c2[column] - c1[column]);
            }
        }
    }

    const renderResources = () => {
        if (availableResources && availableResources.length > 0) {
            return (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Delivery Year</TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'auction'} direction={orderBy === 'auction' ? direction : 'asc'} onClick={() => handleSort("auction", "text")}>
                                    Auction
                                    {orderBy === 'auction' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'resourceName'} direction={orderBy === 'resourceName' ? direction : 'asc'} onClick={() => handleSort("resourceName", "text")}>
                                    Resource Name
                                    {orderBy === 'resourceName' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'resourceType'} direction={orderBy === 'resouceType' ? direction : 'asc'} onClick={() => handleSort("resourceType", "text")}>
                                    Resource Type
                                    {orderBy === 'resourceType' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            {deliveryYear < '2023/2024' &&
                                <TableCell>
                                    <TableSortLabel active={orderBy === 'moprType'} direction={orderBy === 'moprType' ? direction : 'asc'} onClick={() => handleSort("moprType", "text")}>
                                        MOPR Type
                                        {orderBy === 'moprType' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                            }
                            <TableCell>
                                <TableSortLabel active={orderBy === 'minMws'} direction={orderBy === 'minMws' ? direction : 'asc'} onClick={() => handleSort("minMws", "number")}>
                                    ICAP Min. MWs
                                    {orderBy === 'minMws' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'maxMws'} direction={orderBy === 'maxMws' ? direction : 'asc'} onClick={() => handleSort("maxMws", "number")}>
                                    ICAP Max. MWs
                                    {orderBy === 'maxMws' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'existingMws'} direction={orderBy === 'existingMws' ? direction : 'asc'} onClick={() => handleSort("existingMws", "number")}>
                                    Existing MWs
                                    {orderBy === 'existingMws' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'plannedMws'} direction={orderBy === 'plannedMws' ? direction : 'asc'} onClick={() => handleSort("plannedMws", "number")}>
                                    Planned MWs
                                    {orderBy === 'plannedMws' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'maxOfferEford'} direction={orderBy === 'maxOfferEford' ? direction : 'asc'} onClick={() => handleSort("maxOfferEford", "number")}>
                                    Max. EFORd
                                    {orderBy === 'maxOfferEford' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'minOfferMws'} direction={orderBy === 'minOfferMws' ? direction : 'asc'} onClick={() => handleSort("minOfferMWs", "number")}>
                                    UCAP Min. MWs
                                    {orderBy === 'minOfferMws' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'maxOfferMws'} direction={orderBy === 'maxOfferMws' ? direction : 'asc'} onClick={() => handleSort("maxOfferMws", "number")}>
                                    UCAP Max. MWs
                                    {orderBy === 'maxOfferMws' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'moprPrice'} direction={orderBy === 'moprPrice' ? direction : 'asc'} onClick={() => handleSort("moprPrice", "number")}>
                                    MOPR Price ($/MW-day)
                                    {orderBy === 'moprPrice' ? (
                                        <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {availableResources.map(r => {
                            return (
                                <TableRow key={r.id}>
                                    <TableCell>{r.deliveryYear}</TableCell>
                                    <TableCell>{r.auction}</TableCell>
                                    <TableCell>{r.resourceName}</TableCell>
                                    <TableCell>{r.resourceType}</TableCell>
                                    {deliveryYear < '2023/2024' &&
                                        <TableCell>{r.moprType}</TableCell>
                                    }
                                    <TableCell><NumberFormat value={r.minMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1} thousandSeparator={true} /></TableCell><TableCell><NumberFormat value={r.maxMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={r.existingMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={r.plannedMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={r.maxOfferEford} displayType={'text'} fixedDecimalScale={true} decimalScale={5} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={r.minOfferMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={r.maxOfferMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={r.moprPrice} displayType={'text'} fixedDecimalScale={true} decimalScale={1} thousandSeparator={true} /></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            )
        }
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    {deliveryYearAuctions && renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
            {renderResources()}
        </main>
    )
}

export default AvailableResources;