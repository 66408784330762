import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const EqrTransactionsTable = (props) => {

    const classes = useStyles();
    const eqrTransactions = props.eqrTransactions;
    const retrieveEqrTransactions = props.retrieveEqrTransactions;
    const [pageSize, setPageSize] = useState(10);
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();


    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setDirection(sortDirection);
        setOrderBy(column);
        retrieveEqrTransactions(0, pageSize, column, sortDirection);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        retrieveEqrTransactions(pageNumber, pageSize, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        retrieveEqrTransactions(0, e.target.value, orderBy, direction);
    }

    return (
        <TableContainer>
            <Table sx={{ width: "max-content" }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel active={orderBy === "year"} direction={orderBy === "year" ? direction : 'asc'} onClick={() => handleSort("year")}>
                                Year
                                {orderBy === "year" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "quarter"} direction={orderBy === "quarter" ? direction : 'asc'} onClick={() => handleSort("quarter")}>
                                Quarter
                                {orderBy === "quarter" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "transactionUniqueIdentifier"} direction={orderBy === "transactionUniqueIdentifier" ? direction : 'asc'} onClick={() => handleSort("transactionUniqueIdentifier")}>
                                Transaction Unique Identifier
                                {orderBy === "transactionUniqueIdentifier" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "sellerCompanyName"} direction={orderBy === "sellerCompanyName" ? direction : 'asc'} onClick={() => handleSort("sellerCompanyName")}>
                                Seller Company Name
                                {orderBy === "sellerCompanyName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "customerCompanyName"} direction={orderBy === "customerCompanyName" ? direction : 'asc'} onClick={() => handleSort("customerCompanyName")}>
                                Customer Company Name
                                {orderBy === "customerCompanyName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "fercTariffReference"} direction={orderBy === "fercTariffReference" ? direction : 'asc'} onClick={() => handleSort("fercTariffReference")}>
                                FERC Tariff Reference
                                {orderBy === "fercTariffReference" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "contractServiceAgreementId"} direction={orderBy === "contractServiceAgreementId" ? direction : 'asc'} onClick={() => handleSort("contractServiceAgreementId")}>
                                Contract Service Agreement Id
                                {orderBy === "contractServiceAgreementId" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "transactionUniqueId"} direction={orderBy === "transactionUniqueId" ? direction : 'asc'} onClick={() => handleSort("transactionUniqueId")}>
                                Transaction Unique Id
                                {orderBy === "transactionUniqueId" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "transactionBeginDate"} direction={orderBy === "transactionBeginDate" ? direction : 'asc'} onClick={() => handleSort("transactionBeginDate")}>
                                Transaction Begin Date
                                {orderBy === "transactionBeginDate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "transactionEndDate"} direction={orderBy === "transactionEndDate" ? direction : 'asc'} onClick={() => handleSort("transactionEndDate")}>
                                Transaction End Date
                                {orderBy === "transactionEndDate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "tradeDate"} direction={orderBy === "tradeDate" ? direction : 'asc'} onClick={() => handleSort("tradeDate")}>
                                Trade Date
                                {orderBy === "tradeDate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "exchangeBrokerageService"} direction={orderBy === "exchangeBrokerageService" ? direction : 'asc'} onClick={() => handleSort("exchangeBrokerageService")}>
                                Exchange Brokerage Service
                                {orderBy === "exchangeBrokerageService" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "typeOfRate"} direction={orderBy === "typeOfRate" ? direction : 'asc'} onClick={() => handleSort("typeOfRate")}>
                                Type of Rate
                                {orderBy === "typeOfRate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "timeZone"} direction={orderBy === "timeZone" ? direction : 'asc'} onClick={() => handleSort("timeZone")}>
                                Time Zone
                                {orderBy === "timeZone" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "pointOfDeliveryBalancingAuthority"} direction={orderBy === "pointOfDeliveryBalancingAuthority" ? direction : 'asc'} onClick={() => handleSort("pointOfDeliveryBalancingAuthority")}>
                                Point of Delivery Balancing Authority
                                {orderBy === "pointOfDeliveryBalancingAuthority" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "pointOfDeliverySpecificLocation"} direction={orderBy === "pointOfDeliverySpecificLocation" ? direction : 'asc'} onClick={() => handleSort("pointOfDeliverySpecificLocation")}>
                                Point of Delivery Specific Location
                                {orderBy === "pointOfDeliverySpecificLocation" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "className"} direction={orderBy === "className" ? direction : 'asc'} onClick={() => handleSort("className")}>
                                Class Name
                                {orderBy === "className" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "termName"} direction={orderBy === "termName" ? direction : 'asc'} onClick={() => handleSort("termName")}>
                                Term Name
                                {orderBy === "className" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "incrementName"} direction={orderBy === "incrementName" ? direction : 'asc'} onClick={() => handleSort("incrementName")}>
                                Increment Name
                                {orderBy === "incrementName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "incrementPeakingName"} direction={orderBy === "incrementPeakingName" ? direction : 'asc'} onClick={() => handleSort("incrementName")}>
                                Increment Peaking Name
                                {orderBy === "incrementPeakingName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "productName"} direction={orderBy === "productName" ? direction : 'asc'} onClick={() => handleSort("productName")}>
                                Product Name
                                {orderBy === "productName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "transactionQuantity"} direction={orderBy === "transactionQuantity" ? direction : 'asc'} onClick={() => handleSort("transactionQuantity")}>
                                Transaction Quantity
                                {orderBy === "transactionQuantity" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "price"} direction={orderBy === "price" ? direction : 'asc'} onClick={() => handleSort("price")}>
                                Price
                                {orderBy === "price" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "rateUnits"} direction={orderBy === "rateUnits" ? direction : 'asc'} onClick={() => handleSort("rateUnits")}>
                                Rate Units
                                {orderBy === "rateUnits" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "standardizedQuantity"} direction={orderBy === "standardizedQuantity" ? direction : 'asc'} onClick={() => handleSort("standardizedQuantity")}>
                                Standardized Quantity
                                {orderBy === "standardizedQuantity" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "standardizedPrice"} direction={orderBy === "standardizedPrice" ? direction : 'asc'} onClick={() => handleSort("standardizedPrice")}>
                                Standardized Price
                                {orderBy === "standardizedPrice" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "totalTransmissionCharge"} direction={orderBy === "totalTransmissionCharge" ? direction : 'asc'} onClick={() => handleSort("totalTransmissionCharge")}>
                                Total Transmission Charge
                                {orderBy === "totalTransmissionCharge" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "totalTransactionCharge"} direction={orderBy === "totalTransactionCharge" ? direction : 'asc'} onClick={() => handleSort("totalTransactionCharge")}>
                                Total Transaction Charge
                                {orderBy === "totalTransactionCharge" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {eqrTransactions && eqrTransactions.results && eqrTransactions.results.length > 0 &&
                    <React.Fragment>
                        <TableBody>
                            {eqrTransactions.results.map(r => {
                                return (
                                    <TableRow key={r.id}>
                                        <TableCell>{r.year}</TableCell>
                                        <TableCell>{r.quarter}</TableCell>
                                        <TableCell>{r.transactionUniqueIdentifier}</TableCell>
                                        <TableCell>{r.sellerCompanyName}</TableCell>
                                        <TableCell>{r.customerCompanyName}</TableCell>
                                        <TableCell>{r.fercTariffReference}</TableCell>
                                        <TableCell>{r.contractServiceAgreementId}</TableCell>
                                        <TableCell>{r.transactionUniqueId}</TableCell>
                                        <TableCell>{r.transactionBeginDate}</TableCell>
                                        <TableCell>{r.transactionEndDate}</TableCell>
                                        <TableCell>{r.tradeDate}</TableCell>
                                        <TableCell>{r.exchangeBrokerageService}</TableCell>
                                        <TableCell>{r.typeOfRate}</TableCell>
                                        <TableCell>{r.timeZone}</TableCell>
                                        <TableCell>{r.pointOfDeliveryBalancingAuthority}</TableCell>
                                        <TableCell>{r.pointOfDeliverySpecificLocation}</TableCell>
                                        <TableCell>{r.className}</TableCell>
                                        <TableCell>{r.termName}</TableCell>
                                        <TableCell>{r.incrementName}</TableCell>
                                        <TableCell>{r.incrementPeakingName}</TableCell>
                                        <TableCell>{r.productName}</TableCell>
                                        <TableCell>{r.transactionQuantity}</TableCell>
                                        <TableCell>{r.price}</TableCell>
                                        <TableCell>{r.rateUnits}</TableCell>
                                        <TableCell>{r.standardizedQuantity}</TableCell>
                                        <TableCell>{r.standardizedPrice}</TableCell>
                                        <TableCell>{r.totalTransmissionCharge}</TableCell>
                                        <TableCell>{r.totalTransactionCharge}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination count={eqrTransactions.totalCount ? eqrTransactions.totalCount : 0} onPageChange={handlePageChange}
                                    page={eqrTransactions.pageNumber ? eqrTransactions.pageNumber : 0}
                                    rowsPerPage={calculateRowsPerPage(eqrTransactions.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPageOptions={[10, 25, 50]} colSpan={28} ActionsComponent={PaginatedTableActions} />
                            </TableRow>
                        </TableFooter>
                    </React.Fragment>
                }
            </Table >
        </TableContainer >
    )

}

export default EqrTransactionsTable;

