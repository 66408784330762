import { trackPromise } from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';
import { SUCCESS } from "../../actionTypes";
import { onError } from '../../actions/popupActions';
import { RETRIEVE_USERS_SUCCESS } from '../userManagementActionTypes';

export const retrieveUsersSuccess = (users) => {
    return {
        type: RETRIEVE_USERS_SUCCESS,
        users: users
    };
};

export function saveUserSuccess(message) {
    return {
        type: SUCCESS,
        message: message
    }
}

export function retrieveUsers(accountId, pageNumber, pageSize) {
    return async (dispatch) => {
        await trackPromise(axios.get("/auth/account/" + accountId + "/users?pageNumber=" + pageNumber + "&pageSize=" + pageSize).then(response => {
            return dispatch(retrieveUsersSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveUsersAsSuperUser(pageNumber, pageSize) {
    return async (dispatch) => {
        await trackPromise(axios.get("/auth/admin/users?pageNumber=" + pageNumber + "&pageSize=" + pageSize).then(response => {
            return dispatch(retrieveUsersSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

export function saveUser(accountId, user) {
    return async (dispatch) => {
        await trackPromise(axios.post("/auth/account/" + accountId + "/users", JSON.stringify(user)).then(response => {
            dispatch(saveUserSuccess("Saved user " + user.username));
            return dispatch(retrieveUsers(accountId, 0, 10));
        }).catch(error => {
            dispatch(onError(error.response))
        }));
    };
}

export function saveUserAdmin(user) {
    return async (dispatch) => {
        await trackPromise(axios.post("/auth/admin/users", JSON.stringify(user)).then(response => {
            dispatch(saveUserSuccess("Saved user " + user.username));
            return dispatch(retrieveUsersAsSuperUser(0, 10));
        }).catch(error => {
            dispatch(onError(error.response))
        }));
    };
}

export function updateUser(accountId, user) {
    return async (dispatch) => {
        await trackPromise(axios.put("/auth/account/" + accountId + "/users/" + user.id, JSON.stringify(user)).then(response => {
            dispatch(saveUserSuccess("Updated user " + user.username));
            return dispatch(retrieveUsers(accountId, 0, 10));
        }).catch(error => {
            dispatch(onError(error.response))
        }));
    };
}

export function updateUserAdmin(user) {
    return async (dispatch) => {
        await trackPromise(axios.put("/auth/admin/users/" + user.id, JSON.stringify(user)).then(response => {
            dispatch(saveUserSuccess("Updated user " + user.username));
            return dispatch(retrieveUsersAsSuperUser(0, 10));
        }).catch(error => {
            dispatch(onError(error.response))
        }));
    };
}

