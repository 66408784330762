import { Button, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../store/actions/popupActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const UploadMeterReadingComponent = (props) => {

    const account = useSelector(state => state.chosenAccount.account);
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const [uploadedMeterReadingName, setUploadedMeterReadingName] = useState('');
    const [uploadedMeterReadingFile, setUploadedMeterReadingFile] = useState();

    const fileChosen = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        setUploadedMeterReadingName(e.target.files[0].name);
        setUploadedMeterReadingFile(formData);
    }

    const uploadMeterReading = async () => {
        await trackPromise(axios.post("/tangent/v2/account/" + account.id + "/meter/values", uploadedMeterReadingFile).then(response => {
            dispatch(onSuccess("Meter reading uploaded successfully"))
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h5" align="center">Upload Meter Readings</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <label htmlFor="uploadMeterReading">
                        <TextField value={uploadedMeterReadingName} label="Upload Meter Reading"
                            InputProps={{ startAdornment: <Button component="span">Upload File</Button> }} fullWidth mt={3} />
                        <input style={{ display: "none" }} id="uploadMeterReading" name="uploadMeterReading" type="file" onChange={fileChosen} />
                    </label>
                </Grid>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={uploadMeterReading} fullWidth>Upload</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
        </main>
    )

}

export default UploadMeterReadingComponent;