import { Button, Grid, InputAdornment, TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import TransactionComments from "./TransactionComments";
import TransactionEvents from "./TransactionEvents";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const UcapTransferTransaction = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const [transaction, setTransaction] = useState();

    useEffect(() => {
        retrieveUcapTransferTransaction();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveUcapTransferTransaction = async () => {
        await trackPromise(axios.get("/capacity/account/" + params.accountId + "/transactions/ucaptransfer/" + params.transactionId).then(response => {
            setTransaction(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    return (
        <main className={classes.layout}>
            {transaction &&
                <React.Fragment>
                    <Typography variant="h5" align="center" style={{ marginBottom: 50 }}>{transaction.companyName} Locational UCAP Transfer Transaction {transaction.transactionId}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.startDate} disabled={true} label="Start Date" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.endDate} disabled={true} label="Stop Date" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.status} disabled={true} label="Status" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.resourceName} disabled={true} label="Resource Name" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.sellerName} disabled={true} label="Seller Name" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.buyerName} disabled={true} label="Buyer Name" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.megawatts} disabled={true} label="Capacity" fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                            }} />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.productType} disabled={true} label="Product Type" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.moprType} disabled={true} label="MOPR Type" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={5} />
                        <Grid item xs={6} sm={3}>
                            <TextField value={transaction.lda} disabled={true} label="LDA" fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={4} />
                    </Grid>
                    <TransactionEvents accountId={transaction.accountId} transactionType="ucaptransfer" transactionId={transaction.transactionId} />
                    <TransactionComments accountId={transaction.accountId} transactionType="ucaptransfer" transactionId={transaction.transactionId} />
                </React.Fragment>
            }
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <Button fullWidth onClick={() => history.goBack()} style={{ marginTop: 50 }}>Back</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
        </main>
    )

}

export default UcapTransferTransaction;