import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import { retrieveFercEqrAccountForWpecAccount } from "../../../store/ferc-eqr/FercEqrActions";

const FercEqrAppBar = () => {
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const chosenEqrAccount = useSelector(state => state.fercEqr.chosenEqrAccount);
    const roles = useSelector(state => state.auth.roles);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveFercEqrAccountForWpecAccount(chosenAccount.id));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenAccount]);

    const buildViewEqrAccountMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_retrieve_eqr_account')) {
            return (<Button onClick={() => history.push("/ferc/eqr/eqr-account")}>EQR Accounts</Button>);
        }
    }

    const buildViewEqrSubmissionsMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_retrieve_eqr_submission')) {
            return (<Button onClick={() => history.push("/ferc/eqr/eqr-account/" + chosenEqrAccount.id + "/submissions")}>EQR Submissions</Button>);
        }
    }

    const buildEqrTransactionsMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_upload_eqr_transactions') || hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_retrieve_eqr_transactions')) {
            return (<Button onClick={() => history.push("/ferc/eqr/eqr-account/" + chosenEqrAccount.id + "/transactions")}>EQR Transactions</Button>);
        }
    }

    return (
        <React.Fragment>
            {buildViewEqrAccountMenu()}
            {buildViewEqrSubmissionsMenu()}
            {buildEqrTransactionsMenu()}
        </React.Fragment>
    )
}

export default FercEqrAppBar;