import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import { retrieveDeliveryYearAuctions } from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const CapacityDataRetrieval = (props) => {

    const dispatch = useDispatch();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const classes = useStyles(props);
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const [search, setSearch] = useState({ deliveryYear: '', auction: '' });
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={search.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                    getOptionLabel={(option) => option} id="deliveryYear"
                    renderInput={(params) => <TextField {...params} label="Delivery Year" />}
                    onChange={(event, value) => onDeliveryYearSelectChange(event, value)} />
            );
        }
    }

    const renderAuctionSelectBox = () => {
        return (
            <Autocomplete value={search.auction} options={['', 'BASE', 'FIRST', 'SECOND', 'THIRD']} getOptionLabel={(option) => option} id="auction"
                renderInput={(params) => <TextField {...params} label="Auction" />}
                onChange={(event, value) => onAuctionSelectChange(event, value)} />
        );
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            search.deliveryYear = value;
        } else {
            search.deliveryYear = '';
        }
        setSearch({ ...search });
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            search.auction = value;
        } else {
            search.auction = '';
        }
        setSearch({ ...search });
    }

    const retrieveCapacityBids = async () => {
        await trackPromise(axios.post("/capacity/account/" + chosenAccount.id + "/bid?deliveryYear=" + search.deliveryYear.replace("/", "-") + "&auctionType=" + search.auction).then(response => {
            dispatch(onSuccess("Capacity Bids have been updated"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrieveCapacityBidResults = async () => {
        await trackPromise(axios.post("/capacity/account/" + chosenAccount.id + "/bid/results?deliveryYear=" + search.deliveryYear.replace("/", "-") + "&auctionType=" + search.auction).then(response => {
            dispatch(onSuccess("Capacity Bid Results have been updated"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrieveCapacityTransactions = async () => {
        await trackPromise(axios.post("/capacity/account/" + chosenAccount.id + "/transactions?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            dispatch(onSuccess("Capacity Transaction have been updated"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrieveGeneratorPosition = async () => {
        await trackPromise(axios.post("/capacity/account/" + chosenAccount.id + "/generation/position?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            dispatch(onSuccess("Generation Position has been updated"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrievePeakLoadObligation = async () => {
        await trackPromise(axios.post("/capacity/account/" + chosenAccount.id + "/load/obligation?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            dispatch(onSuccess("Peak Load Obligation has been updated"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrieveResourceOffers = async () => {
        await trackPromise(axios.post("/capacity/account/" + chosenAccount.id + "/offer?deliveryYear=" + search.deliveryYear.replace("/", "-") + "&auctionType=" + search.auction).then(response => {
            dispatch(onSuccess("Resource Offers have been updated"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrieveResourceOfferResults = async () => {
        await trackPromise(axios.post("/capacity/account/" + chosenAccount.id + "/offer/results?deliveryYear=" + search.deliveryYear.replace("/", "-") + "&auctionType=" + search.auction).then(response => {
            dispatch(onSuccess("Resource Offers have been updated"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_capacity_bid') &&
                    <React.Fragment>
                        <Grid item xs={6} sm={2}><Typography>Capacity Bids</Typography></Grid>
                        <Grid item xs={6} sm={2}>{renderDeliveryYearSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}>{renderAuctionSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}><Button onClick={() => retrieveCapacityBids()}>Retrieve</Button></Grid>
                        <Grid item xs={6} sm={2} />
                    </React.Fragment>
                }
                {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_capacity_bid_results') &&
                    <React.Fragment>
                        <Grid item xs={6} sm={2}><Typography>Capacity Bid results</Typography></Grid>
                        <Grid item xs={6} sm={2}>{renderDeliveryYearSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}>{renderAuctionSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}><Button onClick={() => retrieveCapacityBidResults()}>Retrieve</Button></Grid>
                        <Grid item xs={6} sm={2} />
                    </React.Fragment>
                }
                {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_capacity_transaction') &&
                    <React.Fragment>
                        <Grid item xs={6} sm={2}><Typography>Capacity Transactions</Typography></Grid>
                        <Grid item xs={6} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                    onChange={(date) => setStartDate(date)} value={startDate} fullWidth autoOk={true} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                    onChange={(date) => setEndDate(date)} value={endDate} fullWidth autoOk={true} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}><Button onClick={() => retrieveCapacityTransactions()}>Retrieve</Button></Grid>
                        <Grid item xs={6} sm={2} />
                    </React.Fragment>
                }
                {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_generator_position') &&
                    <React.Fragment>
                        <Grid item xs={6} sm={2}><Typography>Generator Position</Typography></Grid>
                        <Grid item xs={6} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                    onChange={(date) => setStartDate(date)} value={startDate} fullWidth autoOk={true} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                    onChange={(date) => setEndDate(date)} value={endDate} fullWidth autoOk={true} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}><Button onClick={() => retrieveGeneratorPosition()}>Retrieve</Button></Grid>
                        <Grid item xs={6} sm={2} />
                    </React.Fragment>
                }
                {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_peak_load_summary') &&
                    <React.Fragment>
                        <Grid item xs={6} sm={2}><Typography>Peak Load Obligation</Typography></Grid>
                        <Grid item xs={6} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                    onChange={(date) => setStartDate(date)} value={startDate} fullWidth autoOk={true} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                    onChange={(date) => setEndDate(date)} value={endDate} fullWidth autoOk={true} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}><Button onClick={() => retrievePeakLoadObligation()}>Retrieve</Button></Grid>
                        <Grid item xs={6} sm={2} />
                    </React.Fragment>
                }
                {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_resource_offer') &&
                    <React.Fragment>
                        <Grid item xs={6} sm={2}><Typography>Resource Offer</Typography></Grid>
                        <Grid item xs={6} sm={2}>{renderDeliveryYearSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}>{renderAuctionSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}><Button onClick={() => retrieveResourceOffers()}>Retrieve</Button></Grid>
                        <Grid item xs={6} sm={2} />
                    </React.Fragment>
                }
                {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_resource_offer_result') &&
                    <React.Fragment>
                        <Grid item xs={6} sm={2}><Typography>Resource Offer Results</Typography></Grid>
                        <Grid item xs={6} sm={2}>{renderDeliveryYearSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}>{renderAuctionSelectBox()}</Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={2}><Button onClick={() => retrieveResourceOfferResults()}>Retrieve</Button></Grid>
                        <Grid item xs={6} sm={2} />
                    </React.Fragment>
                }
            </Grid>
        </main>
    );

}

export default CapacityDataRetrieval;