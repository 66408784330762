import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import useStyles from '../../../Styles';
import axios from '../../../axios/AxiosInterceptors';
import { onError } from "../../../store/actions/popupActions";
import SolarForecastGraph from "./SolarForecastGraph";
import SolarForecastResults from "./SolarForecastResults";


const SolarForecast = (props) => {

    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const [locationDetails, setLocationDetails] = useState([]);
    const [chosenLocation, setChosenLocation] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [solarForecasts, setSolarForecasts] = useState([]);
    const [includeCloudCover, setIncludeCloudCover] = useState(true);
    const [includeTemperateEffects, setIncludeTemperatureEffects] = useState(true);
    const [includePanelDeterioration, setIncludePanelDeterioration] = useState(true);
    const [includeModuleDetails, setIncludeModuleDetails] = useState(false);

    useEffect(() => {
        retrieveLocationDetails();
        setSolarForecasts([]);
        setIncludeCloudCover(true);
        setIncludeTemperatureEffects(true);
        setIncludePanelDeterioration(true);
        setIncludeModuleDetails(false);
        setChosenLocation({});
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveLocationDetails = async () => {
        setLocationDetails([]);
        await trackPromise(axios.get("/solar-forecast/account/" + params.accountId + "/location/detail?pageNumber=0&pageSize=100").then(response => {
            if (response.data && response.data.results) {
                setLocationDetails(response.data.results);
                setChosenLocation(response.data.results[0]);
            }
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const generateSolarForecast = async () => {
        setSolarForecasts([]);
        await trackPromise(axios.get("/solar-forecast/account/" + params.accountId + "/location/detail/" + chosenLocation.id + "/forecast?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD") + "&includeCloudCover=" + includeCloudCover + "&includeTemperature=" + includeTemperateEffects + "&includePanelDeterioration=" + includePanelDeterioration).then(response => {
            setSolarForecasts(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const downloadSolarForecast = async () => {
        await trackPromise(axios.get("/solar-forecast/account/" + params.accountId + "/location/detail/" + chosenLocation.id + "/forecast?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD") + "&includeCloudCover=" + includeCloudCover + "&includeTemperature=" + includeTemperateEffects + "&includePanelDeterioration=" + includePanelDeterioration + "&includeModuleDetail=" + includeModuleDetails, { headers: { 'Accept': 'text/csv' } }).then(response => {
            let filename = 'solar-forecast.csv';
            let contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
                filename = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9);
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleLocationDetailChange = (event, value) => {
        setChosenLocation(value);
    }

    const handleIncludeCloudCoverChange = () => {
        setIncludeCloudCover(!includeCloudCover);
    }

    const handleIncludeTemperatureEffects = () => {
        setIncludeTemperatureEffects(!includeTemperateEffects);
    }

    const handleIncludePanelDeterioration = () => {
        setIncludePanelDeterioration(!includePanelDeterioration);
    }

    return (
        <main className={classes.layout}>
            <form onSubmit={(e) => {
                e.preventDefault();
                generateSolarForecast();
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                onChange={(date) => setStartDate(date)} value={startDate} fullWidth
                                autoOk={true} maxDate={endDate ? endDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                onChange={(date) => setEndDate(date)} value={endDate} fullWidth
                                autoOk={true} minDate={startDate ? startDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <Autocomplete value={chosenLocation} options={locationDetails} getOptionLabel={(option) => option && option.name ? option.name : ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Location Detail" />} onChange={(event, value) => handleLocationDetailChange(event, value)} />
                    </Grid>
                    <Grid item xs={6} sm={12} align="center">
                        <FormGroup row style={{ justifyContent: 'center' }}>
                            <FormControlLabel control={<Checkbox checked={includeCloudCover} onChange={handleIncludeCloudCoverChange} />} label="Include Cloud Coverage" />
                            <FormControlLabel control={<Checkbox checked={includeTemperateEffects} onChange={handleIncludeTemperatureEffects} />} label="Include Temperature Effects" />
                            <FormControlLabel control={<Checkbox checked={includePanelDeterioration} onChange={handleIncludePanelDeterioration} />} label="Include Panel Deterioration" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} align="center">
                        <Button type="submit" style={{ minHeight: '3em' }} variant="contained">Generate</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </form>
            {solarForecasts && solarForecasts.length > 0 && <SolarForecastGraph solarForecast={solarForecasts} />}
            <SolarForecastResults solarForecasts={solarForecasts} />
            {solarForecasts && solarForecasts.length > 0 &&
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} align="center">
                        <Button style={{ minHeight: '3em', marginTop: 10 }} variant="contained" onClick={downloadSolarForecast}>Download CSV</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} align="center">
                        <FormControlLabel control={<Checkbox checked={includeModuleDetails}
                            onChange={() => { setIncludeModuleDetails(!includeModuleDetails) }} />} label="Include Module Details" />
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            }
        </main >
    )
}

export default SolarForecast;