import { trackPromise } from 'react-promise-tracker';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../actions/popupActions';
import { RETRIEVE_ACCOUNTS_SUCCESS } from "../accountManagementActionTypes";

export const retrieveAccountsSuccess = (accounts) => {
    return {
        type: RETRIEVE_ACCOUNTS_SUCCESS,
        accounts: accounts
    }
}

export const retrieveAccounts = (pageNumber, pageSize) => {
    return async (dispatch) => {
        await trackPromise(axios.get("/account-service/v5/account?pageNumber=" + pageNumber + "&pageSize=" + pageSize).then(response => {
            return dispatch(retrieveAccountsSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

export const saveAccount = (account) => {
    return async (dispatch) => {
        if (account.iso === '') {
            account.iso = null;
        }
        await trackPromise(axios.post("/account-service/v5/account", JSON.stringify(account)).then(response => {
            dispatch(onSuccess("Saved account " + account.customerAccount));
            return dispatch(retrieveAccounts(0, 10));
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}

export const updateAccount = (accountId, account) => {
    return async (dispatch) => {
        if (account.iso === '') {
            account.iso = null;
        }
        await trackPromise(axios.put("/account-service/v5/account/" + accountId, JSON.stringify(account)).then(response => {
            dispatch(onSuccess("Updated account " + account.customerAccount));
            return dispatch(retrieveAccounts(0, 10));
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}