import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useStyles from "../../Styles";
import axios from "../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../store/actions/popupActions";

const EnergyMarketDataRetrievals = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const retrieveDaGeneration = async () => {
        await trackPromise(axios.post("/marketsgateway/v2/account/" + params.accountId + "/da/generation/retrieve?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            dispatch(onSuccess("Day Ahead Generation results have been updated"));
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const retrieveUnitSchedules = async () => {
        await trackPromise(axios.post("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/schedules/retrieve").then(response => {
            dispatch(onSuccess("Unit Schedules have been retrieved"));
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const retrieveUnitDetails = async () => {
        await trackPromise(axios.post("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/details/retrieve?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            dispatch(onSuccess("Unit Schedules have been retrieved"));
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const retrieveHourlyUpdates = async () => {
        await trackPromise(axios.post("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/hourly-update/retrieve?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            dispatch(onSuccess("Hourly updates have been retrieved"));
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const retrieveScheduleOffers = async () => {
        await trackPromise(axios.post("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/schedule-offer/retrieve?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            dispatch(onSuccess("Schedule Offers have been retrieved"));
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={2}><Typography>Day Ahead Generation Results</Typography></Grid>
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                            onChange={(date) => setStartDate(date)} value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                            onChange={(date) => setEndDate(date)} value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={() => retrieveDaGeneration()} style={{ minHeight: '3em' }}>Retrieve</Button>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={2}><Typography>Hourly Detail Updates</Typography></Grid>
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                            onChange={(date) => setStartDate(date)} value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                            onChange={(date) => setEndDate(date)} value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={() => retrieveHourlyUpdates()} style={{ minHeight: '3em' }}>Retrieve</Button>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={2}><Typography>Schedule Offers</Typography></Grid>
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                            onChange={(date) => setStartDate(date)} value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                            onChange={(date) => setEndDate(date)} value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={() => retrieveScheduleOffers()} style={{ minHeight: '3em' }}>Retrieve</Button>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={2}><Typography>Unit Details</Typography></Grid>
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                            onChange={(date) => setStartDate(date)} value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                            onChange={(date) => setEndDate(date)} value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={() => retrieveUnitDetails()} style={{ minHeight: '3em' }}>Retrieve</Button>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={2}>
                    <Typography>Unit Schedules</Typography>
                </Grid>
                <Grid item xs={6} sm={6} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={() => retrieveUnitSchedules()} style={{ minHeight: '3em' }}>Retrieve</Button>
                </Grid>
                <Grid item xs={6} sm={2} />
            </Grid>
        </main>
    )
}

export default EnergyMarketDataRetrievals;