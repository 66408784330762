import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router";
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import GeneratorUnitComponent from "./GeneratorUnitComponent";

const ViewGeneatorUnit = (props) => {

    const classes = useStyles(props);
    const params = useParams();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);

    return (
        <main className={classes.layout}>
            <GeneratorUnitComponent accountId={params.accountId} generatorId={params.generatorId} isEdit={hasPermissionForAccount(roles, chosenAccount.id, "ROLE_update_generator_unit")} />
        </main>
    )

}

export default ViewGeneatorUnit;