import { Button, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const ViewGeneratorUnits = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const [generatorUnits, setGeneratorUnits] = useState([]);
    const [orderBy, setOrderBy] = useState("unitName");
    const [direction, setDirection] = useState("asc");
    const [pageSize, setPageSize] = useState(10);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);

    useEffect(() => {
        doSearch(0, pageSize, orderBy, direction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.accountId])

    const doSearch = async (pageNumber, pageSize, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction };
        await trackPromise(axios.get("/marketsgateway/v1/account/" + params.accountId + "/generation/unit", { params: { ...urlParams } }).then(response => {
            setGeneratorUnits(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setDirection(sortDirection);
        setOrderBy(column);
        doSearch(0, pageSize, column, sortDirection);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        doSearch(pageNumber, pageSize, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        doSearch(0, e.target.value, orderBy, direction);
    }

    return (
        <main className={classes.layout}>
            <React.Fragment>
                <Typography variant="h4" align="center"> Generation Units</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        {hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_generator_unit") && <Button align="center" onClick={() => history.push("/markets/account/" + params.accountId + "/energy/generator/unit/create")} fullWidth>Create Generator</Button>}
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>

                <Table>
                    <TableHead sx={{ width: "max-content" }}>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel active={orderBy === "unitName"} direction={orderBy === "unitName" ? direction : 'asc'} onClick={() => handleSort("unitName")}>
                                    Unit Name
                                    {orderBy === "unitName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === "location"} direction={orderBy === "location" ? direction : 'asc'} onClick={() => handleSort("location")}>
                                    Location Id
                                    {orderBy === "location" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {generatorUnits && generatorUnits.results && generatorUnits.results.length > 0 &&
                            generatorUnits.results.map(r => {
                                return (
                                    <TableRow key={r.id} className={classes.tableRow}
                                        onClick={() => history.push("/markets/account/" + params.accountId + "/energy/generator/unit/" + r.id)}>
                                        <TableCell>{r.unitName}</TableCell>
                                        <TableCell>{r.location}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination count={generatorUnits.totalCount ? generatorUnits.totalCount : 0} onPageChange={handlePageChange}
                                page={generatorUnits.pageNumber ? generatorUnits.pageNumber : 0}
                                rowsPerPage={calculateRowsPerPage(generatorUnits.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                                rowsPerPageOptions={[10, 25, 50]} colSpan={2} ActionsComponent={PaginatedTableActions} />
                        </TableRow>
                    </TableFooter>
                </Table>
            </React.Fragment>
        </main >
    )

}

export default ViewGeneratorUnits;