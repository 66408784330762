import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import { EQR_SUBMISSION_UPDATED } from "../../../store/ferc-eqr/FercEqrActionTypes";
import EqrTransactionsTable from "../EqrTransactions/EqrTransactionsTable";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewEqrSubmissionDetails = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [eqrDetails, setEqrDetails] = useState({ eqrFiling: {} });
    const [submissionDate, setSubmissionDate] = useState();
    const [acceptanceDate, setAcceptanceDate] = useState();


    useEffect(() => {
        retrieveEqrDetails(0, 10);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveEqrDetails = async (pageNumber, pageSize, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction };
        await trackPromise(axios.get("/ferc/eqr/eqr-account/" + params.eqrAccountId + "/submission/" + params.generationUuid, { params: { ...urlParams } }).then(response => {
            setEqrDetails(response.data);
            setSubmissionDate(response.data.eqrFiling.submittalDate);
            setAcceptanceDate(response.data.eqrFiling.acceptanceDate);
        }).catch(error => {
            dispatch(onError(error));
        }))
    };

    const downloadEqrSubmission = async () => {
        await trackPromise(axios.get("/ferc/eqr/eqr-account/" + params.eqrAccountId + "/submission/" + params.generationUuid + "/generate", { responseType: 'arraybuffer' }).then(response => {
            let filename = 'filename.zip'
            let contentDisposition = response.headers['content-disposition']
            if (contentDisposition) {
                filename = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9);
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const submissionDateHandler = (date) => {
        setSubmissionDate(date);
    }

    const acceptanceDateHandler = (date) => {
        setAcceptanceDate(date);
    }

    const updateEqrFiling = async () => {
        let eqrFiling = eqrDetails.eqrFiling;
        if (acceptanceDate) {
            eqrFiling.acceptanceDate = moment(acceptanceDate).format("YYYY-MM-DD");
        } else {
            eqrFiling.acceptanceDate = null;
        }
        if (submissionDate) {
            eqrFiling.submittalDate = moment(submissionDate).format("YYYY-MM-DD");
        } else {
            eqrFiling.submittalDate = null;
        }
        await trackPromise(axios.put("/ferc/eqr/eqr-account/" + params.eqrAccountId + "/submission/" + params.generationUuid, eqrDetails.eqrFiling).then(response => {
            dispatch(onSuccess("EQR Filing updated successfully"));
            dispatch({ type: EQR_SUBMISSION_UPDATED, eqrSubmission: response.data });
            history.goBack();
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h6" align="center" style={{ marginBottom: 25, marginTop: 50 }}>
                EQR Submission for {eqrDetails.eqrFiling.quarter}Q{eqrDetails.eqrFiling.year}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Submission Date" name="submissionDate"
                            onChange={(date) => submissionDateHandler(date)} value={submissionDate} fullWidth
                            autoOk={true} disableFuture disabled={!hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_eqr_filing')} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Acceptance Date" name="acceptanceDate"
                            onChange={(date) => acceptanceDateHandler(date)} value={acceptanceDate} fullWidth
                            autoOk={true} disableFuture disabled={!hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_eqr_filing')} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_eqr_filing') &&
                        <Button onClick={updateEqrFiling} fullWidth>Update</Button>
                    }
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
            <Typography variant="h6" align="center" style={{ marginBottom: 25, marginTop: 50 }}>Submitted EQR Identities</Typography>
            <TableContainer>
                <Table sx={{ width: "max-content" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Filer Unique Id</TableCell>
                            <TableCell>Company Name</TableCell>
                            <TableCell>Company Identifier</TableCell>
                            <TableCell>Contact Name</TableCell>
                            <TableCell>Contact Title</TableCell>
                            <TableCell>Contact Address</TableCell>
                            <TableCell>Contact City</TableCell>
                            <TableCell>Contact State</TableCell>
                            <TableCell>Contact Zip</TableCell>
                            <TableCell>Contact Country</TableCell>
                            <TableCell>Contact Phone</TableCell>
                            <TableCell>Contact E-Mail</TableCell>
                            <TableCell>Report To Index Price Publishers</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eqrDetails && eqrDetails.submittedEqrIdentities && eqrDetails.submittedEqrIdentities.length > 0 && eqrDetails.submittedEqrIdentities.map(r => {
                            return (
                                <TableRow key={r.id}>
                                    <TableCell>{r.filerUniqueId}</TableCell>
                                    <TableCell>{r.companyName}</TableCell>
                                    <TableCell>{r.companyIdentifier}</TableCell>
                                    <TableCell>{r.contactName}</TableCell>
                                    <TableCell>{r.contactTitle}</TableCell>
                                    <TableCell>{r.contactAddress}</TableCell>
                                    <TableCell>{r.contactCity}</TableCell>
                                    <TableCell>{r.contactState}</TableCell>
                                    <TableCell>{r.contactZip}</TableCell>
                                    <TableCell>{r.contactCountryName}</TableCell>
                                    <TableCell>{r.contactPhone}</TableCell>
                                    <TableCell>{r.contactEmail}</TableCell>
                                    <TableCell>{r.transactionReportToIndexPricePublishers}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" align="center" style={{ marginBottom: 25, marginTop: 50 }}>Submitted EQR Contracts</Typography>
            <TableContainer>
                <Table sx={{ width: "max-content" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Contract Unique Id</TableCell>
                            <TableCell>Seller Company Name</TableCell>
                            <TableCell>Customer Company Name</TableCell>
                            <TableCell>Contract Affiliate</TableCell>
                            <TableCell>FERC Tariff Ref.</TableCell>
                            <TableCell>Contract Service Agreement Id</TableCell>
                            <TableCell>Contract Execution Date</TableCell>
                            <TableCell>Commencement Date of Contract Term</TableCell>
                            <TableCell>Contract Termination Date</TableCell>
                            <TableCell>Actual Termination Date</TableCell>
                            <TableCell>Extention Provision Description</TableCell>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Term Name</TableCell>
                            <TableCell>Increment Name</TableCell>
                            <TableCell>Increment Peaking Name</TableCell>
                            <TableCell>Product Type Name</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Units</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Rate Min.</TableCell>
                            <TableCell>Rate Max.</TableCell>
                            <TableCell>Rate Desc.</TableCell>
                            <TableCell>Rate Units</TableCell>
                            <TableCell>Receipt Balancing Authority</TableCell>
                            <TableCell>Receipt Specific Location</TableCell>
                            <TableCell>Delivery Balancing Authority</TableCell>
                            <TableCell>Delivery Specific Location</TableCell>
                            <TableCell>Begin Date</TableCell>
                            <TableCell>End Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eqrDetails.submittedEqrContracts && eqrDetails.submittedEqrContracts.length > 0 && eqrDetails.submittedEqrContracts.map(r => {
                            return (
                                <TableRow key={r.id}>
                                    <TableCell>{r.contractUniqueId}</TableCell>
                                    <TableCell>{r.sellerCompanyName}</TableCell>
                                    <TableCell>{r.customerCompanyName}</TableCell>
                                    <TableCell>{r.contractAffiliate}</TableCell>
                                    <TableCell>{r.fercTariffReference}</TableCell>
                                    <TableCell>{r.contractServiceAgreementId}</TableCell>
                                    <TableCell>{r.contractExecutionDate}</TableCell>
                                    <TableCell>{r.commencementDateOfContractTerm}</TableCell>
                                    <TableCell>{r.contractTerminationDate}</TableCell>
                                    <TableCell>{r.actualTerminationDate}</TableCell>
                                    <TableCell>{r.extenstionProvisionDescription}</TableCell>
                                    <TableCell>{r.className}</TableCell>
                                    <TableCell>{r.termName}</TableCell>
                                    <TableCell>{r.incrementName}</TableCell>
                                    <TableCell>{r.incrementPeakingName}</TableCell>
                                    <TableCell>{r.productTypeName}</TableCell>
                                    <TableCell>{r.productName}</TableCell>
                                    <TableCell>{r.quantity}</TableCell>
                                    <TableCell>{r.units}</TableCell>
                                    <TableCell>{r.rate}</TableCell>
                                    <TableCell>{r.rateMinimum}</TableCell>
                                    <TableCell>{r.rateMaximum}</TableCell>
                                    <TableCell>{r.rateDescription}</TableCell>
                                    <TableCell>{r.rateUnits}</TableCell>
                                    <TableCell>{r.pointOfReceiptBalancingAuthority}</TableCell>
                                    <TableCell>{r.pointOfReceiptSpecificLocation}</TableCell>
                                    <TableCell>{r.pointOfDeliveryBalancingAuthority}</TableCell>
                                    <TableCell>{r.pointOfDeliverySpecificLocation}</TableCell>
                                    <TableCell>{r.beginDate}</TableCell>
                                    <TableCell>{r.endDate}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" align="center" style={{ marginBottom: 25, marginTop: 50 }}>Submitted EQR Transactions</Typography>
            <EqrTransactionsTable eqrTransactions={eqrDetails.submittedEqrTransactions} retrieveEqrTransactions={retrieveEqrDetails} />
            <Grid container spacing={2}>
                <Grid item xs={6} sm={12} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4} >
                    <Button onClick={() => downloadEqrSubmission()} fullWidth>Download Submission</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
        </main>
    )

}

export default ViewEqrSubmissionDetails;