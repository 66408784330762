import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import { cleanStringInput } from '../../../Utility/StringUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../../store/actions/popupActions';
import { PANEL_MODULE_IS_EDIT, SET_PANEL_MODULE, UPDATE_PANEL_MODULES } from '../../../store/solar-forecast/SolarForecastActionTypes';
import GenericNumberFormat from '../../NumberFormat/GenericNumberFormat';

const PanelModuleComponent = (props) => {

    const dispatch = useDispatch();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const panelModule = useSelector(state => state.solarForecast.panelModule);
    const isEdit = useSelector(state => state.solarForecast.panelModuleIsEdit);
    const [inServiceDate, setInServiceDate] = useState('');
    const [derateStartDate, setDerateStartDate] = useState('');
    const [derateEndDate, setDerateEndDate] = useState('');

    useEffect(() => {
        if (panelModule) {
            setInServiceDate(panelModule.inServiceDate ? moment(panelModule.inServiceDate) : null);
            setDerateStartDate(panelModule.derateStartDate ? moment(panelModule.derateStartDate) : null);
            setDerateEndDate(panelModule.derateEndDate ? moment(panelModule.derateEndDate) : null);
        } else {
            setInServiceDate('');
            setDerateStartDate('');
            setDerateEndDate('');
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelModule])

    useEffect(() => {
        return () => {
            setInServiceDate('');
            setDerateStartDate('');
            setDerateEndDate('');
            dispatch({ type: SET_PANEL_MODULE, panelModule: {} });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleInputChange = (e) => {
        let localPanelModule = panelModule;
        if (e.target.type === 'text') {
            localPanelModule[e.target.name] = e.target.value;
        } else if (e.target.type === 'number') {
            if (e.target.value === '-') {
                localPanelModule[e.target.name] = e.target.value;
            } else {
                localPanelModule[e.target.name] = Number.parseFloat(e.target.value);
            }
        }
        dispatch({ type: SET_PANEL_MODULE, panelModule: localPanelModule });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localPanelModule);
        }
    }

    const handleTiltTypeChange = (e) => {
        let localPanelModule = panelModule;
        if (e.target.value === 'fixed') {
            localPanelModule.fixed = true;
            localPanelModule.singleAxis = false;
        } else if (e.target.value === 'singleAxis') {
            localPanelModule.fixed = false;
            localPanelModule.singleAxis = true;
        }
        dispatch({ type: SET_PANEL_MODULE, panelModule: localPanelModule });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localPanelModule);
        }
    }

    const determineTiltTypeValue = () => {
        if (panelModule.fixed === true) {
            panelModule.minimumPhi = '';
            panelModule.maximumPhi = '';
            return 'fixed';
        }
        if (panelModule.singleAxis === true) {
            return 'singleAxis';
        }
        return '';
    }

    const handleClear = () => {
        let localPanelModule = panelModule;
        localPanelModule.numberOfModules = '';
        localPanelModule.fixed = false;
        localPanelModule.singleAxis = false;
        localPanelModule.moduleWidth = '';
        localPanelModule.moduleLength = '';
        localPanelModule.axisTilt = '';
        localPanelModule.minimumPhi = '';
        localPanelModule.maximumPhi = '';
        localPanelModule.azimuth = '';
        localPanelModule.cellWidth = '';
        localPanelModule.cellHeight = '';
        localPanelModule.cellCount = '';
        localPanelModule.cellEfficiency = '';
        localPanelModule.pMaxTempCoefficient = '';
        localPanelModule.annualDeterioration = '';
        localPanelModule.inServiceDate = '';
        localPanelModule.moduleName = '';
        localPanelModule.derateAmount = '';
        localPanelModule.derateStartDate = '';
        localPanelModule.derateEndDate = '';
        setInServiceDate('');
        setDerateStartDate('');
        setDerateEndDate('');
        dispatch({ type: SET_PANEL_MODULE, panelModule: localPanelModule });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localPanelModule);
        }
    }

    const handleSave = async () => {
        if (panelModule.id) {
            await trackPromise(axios.put("/solar-forecast/account/" + props.accountId + "/location/detail/" + props.locationDetailId + "/modules/" + panelModule.id, JSON.stringify(panelModule)).then(response => {
                dispatch(onSuccess("Successfully added panel module configuration"));
                dispatch({ type: UPDATE_PANEL_MODULES, panelModule: response.data });
                handleClear();
            }).catch(error => {
                dispatch(onError(error.response));
            }));
        } else {
            await trackPromise(axios.post("/solar-forecast/account/" + props.accountId + "/location/detail/" + props.locationDetailId + "/modules", JSON.stringify(panelModule)).then(response => {
                dispatch(onSuccess("Successfully added panel module configuration"));
                dispatch({ type: UPDATE_PANEL_MODULES, panelModule: response.data });
                handleClear();
            }).catch(error => {
                dispatch(onError(error.response));
            }));
        }
    }

    const inserviceDateChangeHandler = (date) => {
        setInServiceDate(date);
        let localPanelModule = panelModule;
        localPanelModule.inServiceDate = moment(date).format("YYYY-MM-DD");
        dispatch({ type: SET_PANEL_MODULE, panelModule: localPanelModule });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localPanelModule);
        }
    }
    const derateStartDateChangeHandler = (date) => {
        setDerateStartDate(date);
        let localPanelModule = panelModule;
        if (date) {
            localPanelModule.derateStartDate = moment(date).format("YYYY-MM-DD");
        } else {
            localPanelModule.derateStartDate = null;
        }
        dispatch({ type: SET_PANEL_MODULE, panelModule: localPanelModule });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localPanelModule);
        }
    }

    const derateEndDateChangeHandler = (date) => {
        setDerateEndDate(date);
        let localPanelModule = panelModule;
        if (date) {
            localPanelModule.derateEndDate = moment(date).format("YYYY-MM-DD");
        } else {
            localPanelModule.derateEndDate = null;
        }
        dispatch({ type: SET_PANEL_MODULE, panelModule: localPanelModule });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localPanelModule);
        }
    }

    const handleEdit = () => {
        dispatch({ type: PANEL_MODULE_IS_EDIT, panelModuleIsEdit: true });
    }

    const renderButtons = () => {
        if (isEdit) {
            if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_panel_module')) {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4} align="center">
                            <Button onClick={handleClear} variant="contained" >Clear</Button>
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4} align="center">
                            <Button onClick={handleSave} variant="contained">Save</Button>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                    </Grid>
                )
            }
        } else {
            if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_panel_module')) {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={6} sm={4} align="center">
                            <Button onClick={handleEdit} variant="contained">Edit</Button>
                        </Grid>
                        <Grid item xs={6} sm={4} align="center" />
                    </Grid>
                )
            }
        }
    }

    return (
        <React.Fragment>
            <Box border={1} borderColor='black' marginBottom={2} marginTop={5}>
                <InputLabel style={{ marginTop: 10, marginLeft: 10 }}>Module Configuration</InputLabel>
                <FormControl style={{ flexDirection: 'row' }}>
                    <TextField id="moduleWidth" name="moduleWidth" label="Module Width" value={cleanStringInput(panelModule.moduleWidth)}
                        type="number" onChange={handleInputChange} fullWidth InputProps={{
                            inputComponent: GenericNumberFormat,
                            endAdornment: <InputAdornment position="start">MM</InputAdornment>,
                            inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: false },
                        }} InputLabelProps={{ shrink: !!panelModule.moduleWidth || panelModule.moduleWidth === 0 }} disabled={!isEdit}
                        style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, width: '200px' }} />
                    <TextField id="moduleLength" name="moduleLength" label="Module Length" value={cleanStringInput(panelModule.moduleLength)}
                        type="number" onChange={handleInputChange} fullWidth InputProps={{
                            inputComponent: GenericNumberFormat,
                            endAdornment: <InputAdornment position="start">MM</InputAdornment>,
                            inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: false },
                        }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.moduleLength || panelModule.moduleLength === 0 }} style={{ marginRight: 10, marginBottom: 10, width: '200px' }} />
                    <TextField id="numberOfModules" name="numberOfModules" label="Number of Modules" value={cleanStringInput(panelModule.numberOfModules)} type="number" onChange={handleInputChange} fullWidth InputProps={{
                        inputComponent: GenericNumberFormat,
                        inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 0, allowNegative: false }
                    }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.numberOfModules || panelModule.numberOfModules === 0 }} style={{ marginRight: 10, marginBottom: 10, width: '200px' }} />
                    <TextField id="moduleName" name="moduleName" label="Module Name" value={cleanStringInput(panelModule.moduleName)}
                        type="text" onChange={handleInputChange} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.moduleName || panelModule.moduleName === 0 }} style={{ marginRight: 10, marginBottom: 10, width: '500px' }} />
                </FormControl>
            </Box>
            <Box border={1} borderColor='black' marginBottom={2}>
                <InputLabel style={{ marginTop: 10, marginLeft: 10 }}>Tilt Configuration</InputLabel>
                <FormControl style={{ flexDirection: 'row', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                    <FormControl>
                        <RadioGroup value={determineTiltTypeValue()} onChange={handleTiltTypeChange} row >
                            <FormControlLabel value="fixed" control={<Radio />} label="Fixed" disabled={!isEdit} />
                            <FormControlLabel value="singleAxis" control={<Radio />} label="Single Axis" disabled={!isEdit} />
                        </RadioGroup>
                    </FormControl>
                    {panelModule.singleAxis === true &&
                        <FormControl style={{ flexDirection: 'row' }}>
                            <TextField id="minimumPhi" name="minimumPhi" label="Minimum Phi" value={cleanStringInput(panelModule.minimumPhi)}
                                type="number" onChange={handleInputChange} fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    endAdornment: <InputAdornment position="start">Degrees</InputAdornment>,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: true },
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.minimumPhi || panelModule.minimumPhi === 0 }}
                                style={{ marginRight: 10, marginBottom: 10 }} />
                            <TextField id="maximumPhi" name="maximumPhi" label="Maximum Phi" value={cleanStringInput(panelModule.maximumPhi)}
                                type="number" onChange={handleInputChange} fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    endAdornment: <InputAdornment position="start">Degrees</InputAdornment>,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: true }
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.maximumPhi || panelModule.maximumPhi === 0 }}
                                style={{ marginRight: 10, marginBottom: 10 }} />
                        </FormControl>
                    }
                </FormControl>
            </Box>
            <Box border={1} borderColor='black' marginBottom={2}>
                <InputLabel style={{ marginTop: 10, marginLeft: 10 }}>Panel Orientation</InputLabel>
                <FormControl style={{ flexDirection: 'row' }}>
                    <TextField id="axisTilt" name="axisTilt" label="Panel Tilt" value={cleanStringInput(panelModule.axisTilt)}
                        type="number" onChange={handleInputChange} fullWidth InputProps={{
                            inputComponent: GenericNumberFormat,
                            endAdornment: <InputAdornment position="start">Degrees</InputAdornment>,
                            inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: true },
                        }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.axisTilt || panelModule.axisTilt === 0 }}
                        style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }} />
                    <TextField id="azimuth" name="azimuth" label="Azimuth" value={cleanStringInput(panelModule.azimuth)}
                        type="number" onChange={handleInputChange} fullWidth InputProps={{
                            inputComponent: GenericNumberFormat,
                            endAdornment: <InputAdornment position="start">Degrees</InputAdornment>,
                            inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: true },
                        }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.azimuth || panelModule.azimuth === 0 }} style={{ marginRight: 10, marginBottom: 10 }} />
                </FormControl>
            </Box>
            <Box border={1} borderColor='black' marginBottom={2}>
                <InputLabel style={{ marginTop: 10, marginLeft: 10 }}>Cell Configuration</InputLabel>
                <FormControl style={{ flexDirection: 'row' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <TextField id="cellWidth" name="cellWidth" label="Cell Width" value={cleanStringInput(panelModule.cellWidth)}
                                type="number" onChange={handleInputChange} fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    endAdornment: <InputAdornment position="start">MM</InputAdornment>,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: false },
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.cellWidth || panelModule.cellWidth === 0 }}
                                style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField id="cellHeight" name="cellHeight" label="Cell Height" value={cleanStringInput(panelModule.cellHeight)}
                                type="number" onChange={handleInputChange} fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    endAdornment: <InputAdornment position="start">MM</InputAdornment>,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: false },
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.cellHeight || panelModule.cellHeight === 0 }} style={{ marginRight: 10, marginBottom: 10 }} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField id="cellEfficiency" name="cellEfficiency" label="Cell Efficiency" value={cleanStringInput(panelModule.cellEfficiency)}
                                type="number" onChange={handleInputChange} fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 2, allowNegative: false },
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.cellEfficiency || panelModule.cellEfficiency === 0 }} style={{ marginRight: 10, marginBottom: 10 }} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField id="cellCount" name="cellCount" label="Cell Count" value={cleanStringInput(panelModule.cellCount)}
                                type="number" onChange={handleInputChange} fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 0, allowNegative: false },
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.cellCount || panelModule.cellCount === 0 }}
                                style={{ marginRight: 10, marginBottom: 10 }} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField id="pMaxTempCoefficient" name="pMaxTempCoefficient" label="Pmax Temp. Coefficient"
                                value={cleanStringInput(panelModule.pMaxTempCoefficient)} type="number" onChange={handleInputChange}
                                fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    endAdornment: <InputAdornment position="start">%/{'\u00b0'}C</InputAdornment>,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 3, allowNegative: true },
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.pMaxTempCoefficient || panelModule.pMaxTempCoefficient === 0 }}
                                style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField id="annualDeterioration" name="annualDeterioration" label="Annual Deterioration"
                                value={cleanStringInput(panelModule.annualDeterioration)} type="number" onChange={handleInputChange} fullWidth InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    endAdornment: <InputAdornment position="start">%/year</InputAdornment>,
                                    inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 3, allowNegative: true },
                                }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.annualDeterioration || panelModule.annualDeterioration === 0 }}
                                style={{ marginRight: 10, marginBottom: 10 }} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="In-Service Date" name="inServiceDate" id="inServiceDate" onChange={(date) => inserviceDateChangeHandler(date)}
                                    value={inServiceDate ? inServiceDate : null} fullWidth autoOk={true} disabled={!isEdit} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>
            <Box border={1} borderColor='black' marginBottom={2}>
                <InputLabel style={{ marginTop: 10, marginLeft: 10 }}>Module Derating</InputLabel>
                <FormControl style={{ flexDirection: 'row' }}>
                    <TextField id="derateAmount" name="derateAmount" label="Derate Percentage"
                        value={cleanStringInput(panelModule.derateAmount)} type="number" onChange={handleInputChange} fullWidth InputProps={{
                            inputComponent: GenericNumberFormat,
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            inputProps: { isEdit: isEdit, thousandSeperator: true, decimalScale: 3, allowNegative: false },
                        }} disabled={!isEdit} InputLabelProps={{ shrink: !!panelModule.derateAmount || panelModule.derateAmount === 0 }}
                        style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }} />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Derate Start" name="derateStartDate" id="dereateStartDate" onChange={(date) => derateStartDateChangeHandler(date)}
                            value={derateStartDate ? derateStartDate : null} fullWidth autoOk={true} disabled={!isEdit}
                            style={{ marginRight: 10, marginBottom: 10 }} />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Derate End" name="derateEndDate" id="dereateEndDate" onChange={(date) => derateEndDateChangeHandler(date)}
                            value={derateEndDate ? derateEndDate : null} fullWidth autoOk={true} disabled={!isEdit}
                            style={{ marginRight: 10, marginBottom: 10 }} />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Box>
            {renderButtons()}
        </React.Fragment >
    )

}

export default PanelModuleComponent;