import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Grid, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useMemo, useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import { cleanStringInput } from "../../../Utility/StringUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import LoadForecastGraph from "../LoadForecastGraph";
import WeatherForecastGraph from "../WeatherForecastGraph";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    minMaxPeakText: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const PeakManagementAction = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [hasEdit, setHasEdit] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loadForecast, setLoadForecast] = useState([]);
    const [peakManagementAction, setPeakManagementAction] = useState({ action: '', outlook: '' });
    const [calledPeaks, setCalledPeaks] = useState([]);
    const [peakPeriods, setPeakPeriods] = useState([]);
    const [prevYearPeaks, setPrevYearPeaks] = useState([]);
    const [currentYearPeaks, setCurrentYearPeaks] = useState([]);
    const [iso, setIso] = useState('');
    const [zone, setZone] = useState('');
    const [weatherData, setWeatherData] = useState([]);
    const [availablePeaks, setAvailablePeaks] = useState([]);


    useEffect(() => {
        const start = new Date();
        const end = new Date();
        end.setDate(end.getDate() + 6);
        setStartDate(start);
        setEndDate(end);
        retrievePeakPeriods();
        setHasEdit(hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_peak_management_peaks"));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const doSearch = () => {
        setLoadForecast([]);
        setPeakManagementAction({ action: '', outlook: '' });
        setCalledPeaks([]);
        setCurrentYearPeaks([]);
        setPrevYearPeaks([]);
        setWeatherData([]);
        setAvailablePeaks([]);
        if (zone === '') {
            retrieveData(startDate, endDate, iso);
        } else {
            retrieveData(startDate, endDate, iso, zone);
        }
    }

    const retrieveData = (startDate, endDate, iso, zone) => {
        retrieveLoadForecast(startDate, endDate, iso, zone);
        retrievePeakManagementAction(startDate, iso, zone);
        retrievePeaks(startDate, endDate, iso, zone);
        retrieveWeatherData(startDate, endDate, iso, zone);
        retrieveAvailablePeaks(startDate, endDate, iso, zone);
    }

    const retrieveLoadForecast = async (startDate, endDate, iso, zone) => {
        let startEndParams = "startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD");
        let url = "/operational-data/" + iso.toLowerCase();
        if (iso === 'PJM') {
            url = url + '/v1/load/forecast/area/';
            if (!zone) {
                url = url + "RTO";
            } else {
                url = url + zone;
            }
            url = url + "?" + startEndParams;
        } else if (iso === 'ISONE') {
            url = url + "/v1/load/forecast?" + startEndParams;
        }
        setLoadForecast({});
        await trackPromise(axios.get(url + "&version=LATEST&pageNumber=0&pageSize=200").then(response => {
            setLoadForecast(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const retrievePeakManagementAction = async (date, iso, zone) => {
        let url = "/peak-manager/v1/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        await trackPromise(axios.get(url + "?date=" + moment(date).format("YYYY-MM-DD")).then(response => {
            setPeakManagementAction(response.data);
            setCalledPeaks(response.data.calledPeaks);
        }).catch(error => {
            if (error.response.status !== 404) {
                dispatch(onError(error.response));
            }
        }))
    }

    const retrievePeakPeriods = async () => {
        await trackPromise(axios.get("/peak-manager/v1/peak/period").then(response => {
            setPeakPeriods(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const retrievePeaks = async (startDate, endDate, iso, zone) => {
        let peakPeriod = peakPeriods.filter(r => r.iso === iso && (zone ? r.zone === zone : r.zone === null))[0];
        const peakStart = calculatePeakPeriodStartDate(startDate, peakPeriod);
        const peakEnd = calculatePeakPeriodEndDate(startDate, peakPeriod);
        retrieveCurrentYearPeaks(peakStart, peakEnd, iso, zone);
        let prevPeakStart = new Date(peakStart);
        prevPeakStart = prevPeakStart.setFullYear(prevPeakStart.getFullYear() - 1);
        let prevPeakEnd = new Date(peakEnd);
        prevPeakEnd = prevPeakEnd.setFullYear(prevPeakEnd.getFullYear() - 1);
        retrievePreviousYearPeaks(prevPeakStart, prevPeakEnd, iso, zone);
    }

    const retrieveCurrentYearPeaks = async (startDate, endDate, iso, zone) => {
        let url = "/peak-manager/v1/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        setCurrentYearPeaks([]);
        await trackPromise(axios.get(url + "/peaks?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            setCurrentYearPeaks(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const retrievePreviousYearPeaks = async (startDate, endDate, iso, zone) => {
        let url = "/peak-manager/v1/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        setPrevYearPeaks([]);
        await trackPromise(axios.get(url + "/peaks?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            setPrevYearPeaks(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const retrieveAvailablePeaks = async (startDate, endDate, iso, zone) => {
        let url = "/peak-manager/v1/peak/period/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        await trackPromise(axios.get(url + "/available-dates?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD"))).then(response => {
            setAvailablePeaks(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        })
    }

    const calculatePeakPeriodStartDate = (startDate, peakPeriod) => {
        if (startDate.getMonth() + 1 >= peakPeriod.startMonthInt) {
            let date = new Date();
            date.setFullYear(startDate.getFullYear());
            date.setMonth(peakPeriod.startMonthInt - 1);
            date.setDate(peakPeriod.startDay);
            return date;
        }
        let date = new Date();
        date.setFullYear(startDate.getFullYear() - 1);
        date.setMonth(peakPeriod.startMonthInt - 1);
        date.setDate(peakPeriod.startDay);
        return date;
    }

    const calculatePeakPeriodEndDate = (startDate, peakPeriod) => {
        if (startDate.getMonth() + 1 <= peakPeriod.endMonthInt) {
            let date = new Date();
            date.setFullYear(startDate.getFullYear());
            date.setMonth(peakPeriod.endMonthInt - 1);
            date.setDate(peakPeriod.endDay);
            return date;
        }
        let date = new Date();
        date.setFullYear(startDate.getFullYear() + 1);
        date.setMonth(peakPeriod.endMonthInt - 1);
        date.setDate(peakPeriod.endDay);
        return date;
    }

    const retrieveWeatherData = async (startDate, endDate, iso, zone) => {
        let url = "/peak-manager/v1/weather/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        setWeatherData([]);
        await trackPromise(axios.get(url + "?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            setWeatherData(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const startDateChangeHandler = (date) => {
        setStartDate(date);
        let tempDate = new Date(date);
        tempDate.setDate(date.getDate() + 6);
        setEndDate(tempDate);
    }

    const actionChangeHandler = (e) => {
        peakManagementAction.action = e.target.value;
        setPeakManagementAction({ ...peakManagementAction });
    }

    const outlookChangeHandler = (e) => {
        peakManagementAction.outlook = e.target.value;
        setPeakManagementAction({ ...peakManagementAction });
    }

    const handlePeakActionClick = (event, calledPeak, calledPeakHour) => {
        let calledPeakHourIndex = -1;
        for (let i = 0; i < calledPeak.calledPeakHours.length; i++) {
            if (calledPeak.calledPeakHours[i].hourEndingDateTime.hourEnding === calledPeakHour.hourEndingDateTime.hourEnding) {
                calledPeakHourIndex = i;
            }
        }
        if (calledPeakHour.hourColor === 'green') {
            calledPeakHour.hourColor = 'red';
        } else {
            calledPeakHour.hourColor = 'green';
        }
        if (calledPeakHourIndex !== -1) {
            calledPeak.calledPeakHours.splice(calledPeakHourIndex, 1, calledPeakHour);
        } else {
            calledPeak.calledPeakHours.push(calledPeakHour);
        }
        calledPeak.peakDay = false;
        for (let calledPeakHour of calledPeak.calledPeakHours) {
            if (calledPeakHour.hourColor === 'red') {
                calledPeak.peakDay = true;
            }
        }
        let calledPeakIndex = -1;
        for (let j = 0; j < calledPeaks.length; j++) {
            if (calledPeaks[j].date === calledPeak.date) {
                calledPeakIndex = j;
            }
        }
        if (calledPeakIndex !== -1) {
            calledPeaks.splice(calledPeakIndex, 1, calledPeak);
        } else {
            calledPeaks.push(calledPeak);
        }
        setCalledPeaks([...calledPeaks]);
    }

    const findPeakActionStyleForHour = (isIncluded, hourColor) => {
        const greenDay = {
            borderRight: "1px solid black", borderLeft: "1px solid black", backgroundColor: 'green', color: "white",
            cursor: (isIncluded && hasEdit ? "pointer" : "")
        };
        const redDay = {
            borderRight: "1px solid black", borderLeft: "1px solid black",
            backgroundColor: 'red', color: "white", cursor: (isIncluded && hasEdit ? "pointer" : "")
        };
        const nonCall = {
            borderRight: "1px solid black", borderLeft: "1px solid black", borderTop: "1px solid black", borderBottom: "1px solid black",
            backgroundColor: 'white', color: 'black', cursor: (isIncluded && hasEdit ? "pointer" : "")
        };
        if (hourColor === 'green') {
            return greenDay;
        }
        if (hourColor === 'red') {
            return redDay;
        }
        return nonCall;
    }

    const findPeakCallForDate = (date) => {
        if (calledPeaks && calledPeaks.length > 0) {
            for (let calledPeak of calledPeaks) {
                if (calledPeak.date === date) {
                    if (calledPeak.peakDay) {
                        return (<Box sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 24, color: 'red' }}>Red Day</Box>);
                    }
                    if (!calledPeak.peakDay) {
                        return (<Box sx={{ textAlign: 'center', color: 'green' }}>Green Day</Box>);
                    }
                }
            }
        }
        return (<Box sx={{ textAlign: 'center', color: 'black' }}>No Call</Box>);
    }

    const findCalledPeakForDate = (date) => {
        if (calledPeaks && calledPeaks.length > 0) {
            for (let calledPeak of calledPeaks) {
                if (calledPeak.date === date) {
                    return calledPeak;
                }
            }
        }
    }

    const renderTodaysForecast = () => {
        const date = new Date(startDate);
        const prettyDate = moment(date).format('YYYY-MM-DD');
        let isIncluded = availablePeaks && availablePeaks.includes(prettyDate);
        let calledPeak = findCalledPeakForDate(prettyDate);
        return (<React.Fragment>
            <div style={{ width: '100%', height: 1240, display: 'flex', flexDirection: 'column', float: 'left', marginTop: 50 }}>
                <Typography align="center" variant="h6">Peak Management for {moment(date).format('dddd, MMMM D, YYYY')}</Typography>
                <Typography component="div" style={{ marginTop: 20 }}>
                    {findPeakCallForDate(prettyDate)}
                </Typography>
                <LoadForecastGraph loadForecast={loadForecast && loadForecast.results && loadForecast.results.length > 0 &&
                    loadForecast.results.filter(r => r.hourBeginningDateTime.hourBeginningDate === prettyDate)} prevYearPeaks={prevYearPeaks}
                    currentYearPeaks={currentYearPeaks} />
                <WeatherForecastGraph weatherData={filterWeatherData(prettyDate)} />
                {isIncluded ?
                    <Table style={{ marginBottom: 50 }}>
                        <TableBody>
                            <TableRow >
                                {renderCalledPeakHours(calledPeak, isIncluded)}
                            </ TableRow>
                        </TableBody>
                    </Table> : <Typography align="center">This day is excluded from the Peak Calculations.  No actions will be necessary.</Typography>
                }
            </div>
        </React.Fragment>);
    }

    const renderCalledPeakHours = (calledPeak, isIncluded) => {
        if (calledPeak && calledPeak.calledPeakHours) {
            const renderedCalledPeakHours = [];
            calledPeak.calledPeakHours.sort((c1, c2) => c1.hourBeginningDateTime.hourBeginning - c2.hourBeginningDateTime.hourBeginning);
            calledPeak.calledPeakHours.forEach(r => renderedCalledPeakHours.push(
                <React.Fragment key={r.id}>
                    <TableCell key={r.id} style={findPeakActionStyleForHour(isIncluded, r.hourColor)} onClick={isIncluded && hasEdit ? (event) => handlePeakActionClick(event, calledPeak, r) : undefined}>HE {r.hourEndingDateTime.hourEnding !== 0 ? r.hourEndingDateTime.hourEnding : 24}
                    </TableCell>
                </React.Fragment>
            ))
            return renderedCalledPeakHours;
        }
    }

    const renderDailyForecasts = () => {
        let date = new Date(startDate);
        let forecasts = [];
        date.setDate(date.getDate() + 1);
        while (date <= endDate) {
            let prettyDate = moment(date).format("YYYY-MM-DD");
            let isIncluded = availablePeaks && availablePeaks.includes(prettyDate);
            let calledPeak = findCalledPeakForDate(prettyDate);
            forecasts.push(
                <React.Fragment key={prettyDate}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={24} align="center">{isIncluded ? moment(date).format('dddd, MMMM D, YYYY') : moment(date).format('dddd, MMMM D, YYYY') + ' is excluded from the Peak Calculations.  No actions will be necessary.'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                {renderCalledPeakHours(calledPeak, isIncluded)}
                            </ TableRow>
                        </TableBody>
                    </Table>
                </React.Fragment >);
            date.setDate(date.getDate() + 1);
        }
        return forecasts;
    };

    const filterWeatherData = (prettyDate) => {
        const keys = Object.keys(weatherData);
        let weather = {};
        keys.forEach(key => {
            weather[key] = weatherData[key].filter(r => r.hourBeginningDateTime.hourBeginningDate === prettyDate)
        });
        return weather;
    }

    const savePeakManagementAction = async () => {
        let url = "/peak-manager/v1/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        peakManagementAction.iso = iso;
        if (zone) {
            peakManagementAction.zone = zone;
        }
        peakManagementAction.date = moment(startDate).format("YYYY-MM-DD");
        peakManagementAction.calledPeaks = calledPeaks;
        await trackPromise(axios.post(url, JSON.stringify(peakManagementAction)).then(response => {
            setPeakManagementAction(response.data);
            dispatch(onSuccess("Saved Peak Management Action"));
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const renderIsoMenuItems = () => {
        if (peakPeriods) {
            let isos = Array.from(new Set(peakPeriods.map(r => r.iso)));
            let menuItems = [''];
            isos.forEach(r => menuItems.push(<MenuItem key={r} value={r}>{r}</MenuItem>));
            return menuItems;
        }
    }

    const renderZoneMenuItems = () => {
        if (iso === 'PJM') {
            let menuItems = [''];
            menuItems.push(<MenuItem key='' value=''></MenuItem>);
            peakPeriods.filter(r => r.iso === 'PJM').filter(r => r.zone !== null).sort((c1, c2) => c1.zone.localeCompare(c2.zone)).forEach(r => menuItems.push(<MenuItem key={r.zone} value={r.zone}>{r.zone}</MenuItem>));
            return menuItems;
        }
    }

    const handleIsoMenuChange = (e) => {
        setIso(e.target.value);
        setZone('');
        if (e.target.value === 'ISONE') {
            const end = new Date();
            end.setDate(end.getDate() + 2);
            setEndDate(end);
        } else if (e.target.value === 'PJM') {
            const end = new Date();
            end.setDate(end.getDate() + 6);
            setEndDate(end);
        }
    }

    const handleZoneMenuChange = (e) => {
        setZone(e.target.value);
    }

    const renderSaveButton = () => {
        let date = new Date(startDate).setHours(0, 0, 0, 0);
        if (date === new Date().setHours(0, 0, 0, 0) && hasEdit) {
            return (
                <Grid container>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button style={{ marginTop: 25 }} onClick={savePeakManagementAction} fullWidth>Save</Button>
                    </Grid>
                </Grid>
            )
        }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    const todaysForecastPlot = useMemo(() => renderTodaysForecast(), [loadForecast, weatherData, prevYearPeaks, currentYearPeaks, calledPeaks, availablePeaks])
    const dailyForecastPlots = useMemo(() => renderDailyForecasts(), [loadForecast, weatherData, prevYearPeaks, currentYearPeaks, calledPeaks, availablePeaks]);
    /* eslint-disable react-hooks/exhaustive-deps */

    return (
        <main className={classes.layout}>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <Select fullWidth value={iso} onChange={handleIsoMenuChange}>
                        {renderIsoMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <Select value={zone} fullWidth disabled={iso !== 'PJM'} onChange={handleZoneMenuChange}>
                        {renderZoneMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={startDateChangeHandler}
                            value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate" value={endDate} disabled={true} fullWidth
                            autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <Button onClick={doSearch}>Search</Button>
                </Grid>
            </Grid>
            {loadForecast && loadForecast.results && loadForecast.results.length > 0 &&
                <React.Fragment>
                    {todaysForecastPlot}
                    <Grid container spacing={2} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                        <Grid item xs={6} sm={3} />
                        <Grid item xs={6} sm={6}>
                            <TextField name="action" label="Today's Action" value={cleanStringInput(peakManagementAction.action)} onChange={actionChangeHandler} fullWidth multiline maxRows={2} minRows={2} disabled={!hasEdit} />
                        </Grid>
                        <Grid item xs={6} sm={3} />
                        <Grid item xs={6} sm={3} />
                        <Grid item xs={6} sm={6}>
                            <TextField name="outlook" label="Outlook" value={cleanStringInput(peakManagementAction.outlook)} onChange={outlookChangeHandler} fullWidth multiline
                                maxRows={2} minRows={2} disabled={!hasEdit} />
                        </Grid>
                        <Grid item xs={6} sm={3} />
                    </Grid>
                    <Typography variant="h6" align="center" style={{ marginTop: 50 }}>7-Day Load and Weather Forecast</Typography>
                    <LoadForecastGraph loadForecast={loadForecast.results} prevYearPeaks={prevYearPeaks} currentYearPeaks={currentYearPeaks} />
                    <WeatherForecastGraph weatherData={weatherData} />
                    <Typography variant="h6" align="center" style={{ marginTop: 50 }}>Peak Predictions</Typography>
                    {dailyForecastPlots}
                    {renderSaveButton()}
                </React.Fragment>
            }
        </main>
    )

}

export default PeakManagementAction;