import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import store from './store/store';

const theme = createTheme({
    palette: {
        primary: { main: '#0F4E32' },
        secondary: { main: '#3469ac' },
        spacing: 4,
    }
});

const app = (
    <ThemeProvider theme={theme} >
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>
    </ThemeProvider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
