import { trackPromise } from 'react-promise-tracker';
import axios from '../../axios/AxiosInterceptors';
import { EQR_ACCOUNT_CHOSEN } from './FercEqrActionTypes';

export const retrieveFercEqrAccountForWpecAccount = (wpecAccountId) => {
    return async (dispatch) => {
        await trackPromise(axios.get("/ferc/eqr/eqr-account/wpec-account?wpecAccountId=" + wpecAccountId).then(response => {
            return dispatch({ type: EQR_ACCOUNT_CHOSEN, chosenEqrAccount: response.data });
        }).catch(error => {
        }))
    }
}