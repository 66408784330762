import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import useStyles from "../../../Styles";
import { cleanStringInput } from "../../../Utility/StringUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";

const CreateHistoricalPeaks = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const [iso, setIso] = useState('');
    const [zone, setZone] = useState('');
    const [year, setYear] = useState('');
    const [peakPeriods, setPeakPeriods] = useState([]);
    const [historicalPeaks, setHistoricalPeaks] = useState([{ hourBeginningDateTime: {}, hourEndingDateTime: {}, official: true }]);


    useEffect(() => {
        retrievePeakPeriods();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrievePeakPeriods = async () => {
        await trackPromise(axios.get("/peak-manager/v1/peak/period").then(response => {
            setPeakPeriods(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const savePeaks = async () => {
        let peaks = historicalPeaks;
        peaks && peaks.forEach(peak => { peak.iso = iso; peak.zone = zone; peak.year = year });
        let url = "/peak-manager/v1/peak/historical/iso/" + iso;
        if (zone) {
            url += "/zone/" + zone;
        }
        url += "/year/" + year;
        await trackPromise(axios.post(url, JSON.stringify(peaks)).then(response => {
            dispatch(onSuccess("Peaks saved successfully"));
            history.push("/peak-management/historical");
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleIsoMenuChange = (e) => {
        setIso(e.target.value);
        setZone('');
    }

    const handleZoneMenuChange = (e) => {
        if (e.target.value) {
            setZone(e.target.value);
        } else {
            setZone('');
        }
    }

    const renderZoneMenuItems = () => {
        if (iso === 'PJM') {
            let menuItems = [''];
            peakPeriods.filter(r => r.iso === 'PJM').forEach(r => menuItems.push(<MenuItem key={r.zone} value={r.zone}>{r.zone}</MenuItem>));
            return menuItems;
        }
    }

    const renderIsoMenuItems = () => {
        if (peakPeriods) {
            let isos = Array.from(new Set(peakPeriods.map(r => r.iso)));
            let menuItems = [''];
            isos.forEach(r => menuItems.push(<MenuItem key={r} value={r}>{r}</MenuItem>));
            return menuItems;
        }
    }

    const handleYearChange = (e) => {
        setYear(e.target.value);
    }

    const dateChangeHandler = (date, index) => {
        historicalPeaks[index].hourBeginningDateTime.hourBeginningDate = date;
        historicalPeaks[index].hourEndingDateTime.hourEndingDate = date;
        setHistoricalPeaks([...historicalPeaks]);
    }

    const onHourEndingChange = (e, index) => {
        historicalPeaks[index].hourEndingDateTime.hourEnding = e.target.value;
        historicalPeaks[index].hourBeginningDateTime.hourBeginning = e.target.value--;
        setHistoricalPeaks([...historicalPeaks]);
    }

    const onLoadChange = (e, index) => {
        historicalPeaks[index].load = e.target.value;
        setHistoricalPeaks([...historicalPeaks]);
    }

    const handleOfficialChange = (e, index) => {
        historicalPeaks[index].official = !historicalPeaks[index].official;
        setHistoricalPeaks([...historicalPeaks]);
    }

    const addIconClick = () => {
        historicalPeaks.push({ hourBeginningDateTime: {}, hourEndingDateTime: {}, official: true });
        setHistoricalPeaks([...historicalPeaks]);
    }

    const renderAddIcon = (totalCount, index) => {
        if (index === totalCount) {
            return (
                <IconButton onClick={addIconClick}>
                    <AddIcon />
                </IconButton>
            )
        }
    }

    const removeIconClick = (index) => {
        historicalPeaks.splice(index, 1);
        setHistoricalPeaks([...historicalPeaks]);
    }

    const renderHistoricalPeaks = () => {
        if (historicalPeaks.length === 0) {
            addIconClick();
        }
        let renderedHistoricalPeaks = [];
        for (let i = 0; i < historicalPeaks.length; i++) {
            renderedHistoricalPeaks.push(renderHistoricalPeak(historicalPeaks[i], historicalPeaks.length - 1, i));
        }
        return renderedHistoricalPeaks;
    }

    const renderHistoricalPeak = (historicalPeak, totalCount, index) => {
        return (
            <Grid container spacing={2} key={index}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Date" name="date"
                            onChange={(date) => dateChangeHandler(date, index)}
                            value={historicalPeak.hourBeginningDateTime.hourBeginningDate ? historicalPeak.hourBeginningDateTime.hourBeginningDate : null} fullWidth autoOk={true}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <TextField name="hourEnding" label="Hour Ending" onChange={(e) => onHourEndingChange(e, index)}
                        value={cleanStringInput(historicalPeak.hourEndingDateTime ? historicalPeak.hourEndingDateTime.hourEnding : '')} type="number" fullWidth InputProps={{ inputComponent: GenericNumberFormat, inputProps: { fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: true } }} />
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField name="load" label="System Load" onChange={(e) => onLoadChange(e, index)}
                        value={cleanStringInput(historicalPeak.load)} type="number" fullWidth InputProps={{ inputComponent: GenericNumberFormat, inputProps: { fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: true } }} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <FormControlLabel control={<Checkbox checked={historicalPeak.official} onChange={(e) => handleOfficialChange(e, index)} />} label="Official" />
                </Grid>
                <Grid item xs={6} sm={1}>
                    {renderAddIcon(totalCount, index)}
                    <IconButton onClick={() => removeIconClick(index)}>
                        <RemoveIcon />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h6" align="center" style={{ marginBottom: 25 }}>Create Historical Capacity and Transmission Peaks</Typography>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <Select fullWidth value={iso} onChange={handleIsoMenuChange}>
                        {renderIsoMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <Select value={zone} fullWidth disabled={iso !== 'PJM'} onChange={handleZoneMenuChange}>
                        {renderZoneMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={2}>
                    <TextField name="year" label="Peak Year" onChange={handleYearChange} value={cleanStringInput(year)} type="number" fullWidth InputProps={{ inputProps: { min: 2015 } }} />
                </Grid>
            </Grid>
            {renderHistoricalPeaks()}
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={savePeaks} fullWidth>Save</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
        </main>
    )

};

export default CreateHistoricalPeaks;