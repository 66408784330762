import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useStyles from "../../../Styles";
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../../store/actions/popupActions';
import SupplierCustomerComponent from "./SupplierCustomerComponent";

const CreateSupplierCustomer = (props) => {

    const classes = useStyles(props);
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [supplierCustomer, setSupplierCustomer] = useState({});

    const handleSupplierCustomerSave = async () => {
        saveSupplierCustomer(supplierCustomer);
        history.push("/gas/supplier/account/" + params.supplierAccountId + "/customer");
    }

    const sendForEnrollment = async () => {
        await saveSupplierCustomer(supplierCustomer, updateForEnrollment);
    }

    const saveSupplierCustomer = async (customer, callback) => {
        customer.supplierAccountId = params.supplierAccountId;
        await trackPromise(axios.post("/gas-service/supplier/" + params.supplierAccountId + "/customer", JSON.stringify(customer)).then(response => {
            setSupplierCustomer({ ...response.data });
            if (callback) {
                callback(response.data);
            }
            dispatch(onSuccess("Successfully saved customer"));
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const updateForEnrollment = async (customer) => {
        customer.supplierCustomerStatus = 'PENDING';
        await trackPromise(axios.put("/gas-service/supplier/" + params.supplierAccountId + "/customer/" + customer.id, JSON.stringify(customer)).then(response => {
            dispatch(onSuccess("Successfully saved customer"));
            history.push("/gas/supplier/account/" + params.supplierAccountId + "/customer");
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleBack = () => {
        history.push("/gas/supplier/account/" + params.supplierAccountId + "/customer");
    }

    const onChange = (updatedSupplierCustomer) => {
        setSupplierCustomer({ ...updatedSupplierCustomer });
    }

    return (
        <main className={classes.layout}>
            <SupplierCustomerComponent onChange={onChange} isEdit={true} />
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={handleBack} fullWidth>Back</Button>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={handleSupplierCustomerSave} fullWidth>Save</Button>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={sendForEnrollment} fullWidth>Save/Send for Enrollment</Button>
                </Grid>
            </Grid>
        </main>
    )

}

export default CreateSupplierCustomer;