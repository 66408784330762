import DateFnsUtils from "@date-io/date-fns";
import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { cleanStringInput } from '../../../Utility/StringUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError } from "../../../store/actions/popupActions";
import GenericNumberFormat from '../../NumberFormat/GenericNumberFormat';

const LocationDetailComponent = (props) => {

    const dispatch = useDispatch();
    const [locationDetail, setLocationDetail] = useState({});
    const [weatherLocations, setWeatherLocations] = useState();
    const [chosenWeatherLocation, setChosenWeatherLocation] = useState('');
    const [derateStartDate, setDerateStartDate] = useState();
    const [derateEndDate, setDerateEndDate] = useState();

    useEffect(() => {
        if (props.locationDetail) {
            setDerateStartDate(props.locationDetail.derateStartDate ? moment(props.locationDetail.derateStartDate) : null);
            setDerateEndDate(props.locationDetail.derateEndDate ? moment(props.locationDetail.derateEndDate) : null);
            setLocationDetail({ ...props.locationDetail });
        }
        if (!weatherLocations) {
            retrieveWeatherLocations();
        }
        if (props.locationDetail && props.locationDetail.weatherForecastCity && props.locationDetail.weatherForecastState && weatherLocations) {
            const location = weatherLocations.filter(r => r.city === props.locationDetail.weatherForecastCity && r.state === props.locationDetail.weatherForecastState);
            if (location && location.length > 0) {
                setChosenWeatherLocation({ ...location[0] });
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, weatherLocations])

    const timezones = ['', 'US/Alaska', 'US/Arizona', 'US/Eastern', 'US/East-Indiana', 'US/Michigan', 'America/Indiana/Knox', 'America/Chicago', 'America/Indiana/Marengo', 'America/Indiana/Petersburg', 'America/Indiana/Tell_City', 'America/Indianapolis', 'America/Kentucky/Louisville', 'America/Louisville', 'America/New_York', 'America/North_Dakota/Beulah', 'America/North_Dakota/Center', 'America/North_Dakota/New_Salem', 'America/Puerto_Rico', 'GMT', 'US/Aleutian', 'US/Central', 'US/Hawaii', 'US/Indiana-Starke', 'US/Mountain', 'US/Pacific'];

    const stateAbbreviations = ['', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    const retrieveWeatherLocations = async () => {
        await trackPromise(axios.get("/weather/location").then(response => {
            setWeatherLocations([...response.data]);
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleInputChange = (e) => {
        if (e.target.type === 'text') {
            locationDetail[e.target.name] = e.target.value;
        } else if (e.target.type === 'number') {
            locationDetail[e.target.name] = Number.parseFloat(e.target.value);
        }
        setLocationDetail({ ...locationDetail });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(locationDetail);
        }
    }

    const handleTimezoneMenuChange = (e) => {
        if (e.target.value) {
            locationDetail.timezone = e.target.value;
        } else {
            locationDetail.timezone = '';
        }
        setLocationDetail({ ...locationDetail });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(locationDetail);
        }
    }

    const handleStateMenuChange = (e) => {
        if (e.target.value) {
            locationDetail.state = e.target.value;
        } else {
            locationDetail.state = '';
        }
        setLocationDetail({ ...locationDetail });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(locationDetail);
        }
    }

    const handleWeatherLocationChange = (e, value) => {
        setChosenWeatherLocation(value);
        if (value) {
            locationDetail.weatherForecastCity = value.city;
            locationDetail.weatherForecastState = value.state;
        } else {
            locationDetail.weatherForecastCity = '';
            locationDetail.weatherForecastState = '';
        }
        setLocationDetail({ ...locationDetail });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(locationDetail);
        }
    }

    const derateStartDateChangeHandler = (date) => {
        setDerateStartDate(date);
        let localLocationDetail = locationDetail;
        if (date) {
            localLocationDetail.derateStartDate = moment(date).format("YYYY-MM-DD");
        } else {
            localLocationDetail.derateStartDate = '';
        }
        setLocationDetail({ ...locationDetail });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localLocationDetail);
        }
    }

    const derateEndDateChangeHandler = (date) => {
        setDerateEndDate(date);
        let localLocationDetail = locationDetail;
        if (date) {
            localLocationDetail.derateEndDate = moment(date).format("YYYY-MM-DD");
        } else {
            localLocationDetail.derateEndDate = '';
        }
        setLocationDetail({ ...locationDetail });
        if (props.inputChangeHandler) {
            props.inputChangeHandler(localLocationDetail);
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField id="name" name="name" label="Name" value={cleanStringInput(locationDetail.name)} onChange={handleInputChange} type="text" fullWidth disabled={!props.isEdit} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <FormControl fullWidth disabled={!props.isEdit}>
                        <InputLabel>Time Zone</InputLabel>
                        <Select value={cleanStringInput(locationDetail.timezone)} fullWidth onChange={handleTimezoneMenuChange}>
                            {timezones.map(r => {
                                return (
                                    <MenuItem key={r} value={r}>{r}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField id="city" name="city" label="City" value={cleanStringInput(locationDetail.city)} onChange={handleInputChange} type="text" fullWidth disabled={!props.isEdit} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <FormControl fullWidth disabled={!props.isEdit}>
                        <InputLabel>State</InputLabel>
                        <Select value={cleanStringInput(locationDetail.state)} fullWidth onChange={handleStateMenuChange}>
                            {stateAbbreviations.map(r => {
                                return (
                                    <MenuItem key={r} value={r}>{r}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField id="latitude" name="latitude" label="Latitude" value={cleanStringInput(locationDetail.latitude)} type="number" onChange={handleInputChange} fullWidth InputProps={{
                        inputComponent: GenericNumberFormat,
                        inputProps: { isEdit: props.isEdit, thousandSeperator: false, decimalScale: 5, allowNegative: true }
                    }} disabled={!props.isEdit} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <TextField id="longitude" name="longitude" label="Longitude" value={cleanStringInput(locationDetail.longitude)} type="number" onChange={handleInputChange} fullWidth InputProps={{
                        inputComponent: GenericNumberFormat,
                        inputProps: { isEdit: props.isEdit, thousandSeperator: false, decimalScale: 5, allowNegative: true }
                    }} disabled={!props.isEdit} />
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField id="heightAboveSeaLevel" name="heightAboveSeaLevel" label="Elevation" value={cleanStringInput(locationDetail.heightAboveSeaLevel)} type="number" onChange={handleInputChange} fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Meters</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: { isEdit: props.isEdit, thousandSeperator: true, decimalScale: 0, allowNegative: true }
                    }} disabled={!props.isEdit} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    {weatherLocations && weatherLocations.length > 0 &&
                        <Autocomplete options={['', ...weatherLocations]} getOptionLabel={(option) => (option.city && option.state ? option.city + ', ' + option.state : '')}
                            renderInput={(params) => (<TextField label="Weather Location" {...params} />)}
                            onChange={(e, newValue) => handleWeatherLocationChange(e, newValue)}
                            getOptionSelected={(option, value) => (option.id === value.id)} value={chosenWeatherLocation} disabled={!props.isEdit} fullWidth />
                    }
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField id="maximumOutput" name="maximumOutput" label="Maximum Output" value={cleanStringInput(locationDetail.maximumOutput)} type="number" onChange={handleInputChange} fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: { isEdit: props.isEdit, thousandSeperator: true, decimalScale: 3, allowNegative: false }
                    }} disabled={!props.isEdit} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Derate Start" name="derateStartDate" id="dereateStartDate" onChange={(date) => derateStartDateChangeHandler(date)}
                            value={derateStartDate ? derateStartDate : null} fullWidth autoOk={true} disabled={!props.isEdit}
                            style={{ marginRight: 10, marginBottom: 10 }} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Derate End" name="derateEndDate" id="dereateEndDate" onChange={(date) => derateEndDateChangeHandler(date)}
                            value={derateEndDate ? derateEndDate : null} fullWidth autoOk={true} disabled={!props.isEdit}
                            style={{ marginRight: 10, marginBottom: 10 }} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <TextField id="derateAmount" name="derateAmount" label="Derate Amount" value={cleanStringInput(locationDetail.derateAmount)} type="number" onChange={handleInputChange} fullWidth
                        InputProps={{
                            inputComponent: GenericNumberFormat,
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            inputProps: { isEdit: props.isEdit, thousandSeperator: true, decimalScale: 3, allowNegative: false },
                        }} disabled={!props.isEdit} />
                </Grid>
                <Grid item xs={6} sm={1} />
            </Grid>
        </React.Fragment>
    )

}

export default LocationDetailComponent;