import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../../store/actions/popupActions';
import SupplierCustomerComponent from './SupplierCustomerComponent';

const ViewSupplierCustomer = (props) => {

    const classes = useStyles(props);
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const roles = useSelector(state => state.auth.roles);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [supplierCustomer, setSupplierCustomer] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        retrieveSupplierCustomer();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveSupplierCustomer = async () => {
        await trackPromise(axios.get("/gas-service/supplier/" + params.supplierAccountId + "/customer/" + params.id).then(response => {
            setSupplierCustomer(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const onChange = (updatedSupplierCustomer) => {
        setSupplierCustomer({ ...updatedSupplierCustomer });
    }

    const handleSupplierCustomerSave = () => {
        saveSupplierCustomer(supplierCustomer);
    }

    const handleBack = () => {
        history.push("/gas/supplier/account/" + params.supplierAccountId + "/customer");
    }

    const handleEdit = () => {
        setIsEdit(true);
    }

    const sendForEnrollment = () => {
        supplierCustomer.supplierCustomerStatus = 'PENDING';
        setSupplierCustomer({ ...supplierCustomer });
        saveSupplierCustomer(supplierCustomer);
    }

    const sendForDrop = () => {
        supplierCustomer.supplierCustomerStatus = 'DROPPED';
        setSupplierCustomer({ ...supplierCustomer });
        saveSupplierCustomer(supplierCustomer);
    }

    const saveSupplierCustomer = async (customer) => {
        customer.supplierAccountId = params.supplierAccountId;
        await trackPromise(axios.put("/gas-service/supplier/" + params.supplierAccountId + "/customer/" + customer.id, JSON.stringify(customer)).then(response => {
            dispatch(onSuccess("Successfully saved customer"));
            history.push("/gas/supplier/account/" + params.supplierAccountId + "/customer");
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const renderButtons = () => {
        if (isEdit) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={handleBack} fullWidth>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={handleSupplierCustomerSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3} />
                    <Grid item xs={6} sm={3}>
                        <Button onClick={sendForEnrollment} fullWidth disabled={supplierCustomer.supplierCustomerStatus}>Save/Send for Enrollment</Button>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <Button onClick={sendForDrop} fullWidth disabled={supplierCustomer.supplierCustomerStatus !== 'ENROLLED'}>Save/Send for Drop</Button>
                    </Grid>
                </Grid>
            )
        } else if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_gas_supplier_customer')) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={handleBack} fullWidth>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={handleEdit} fullWidth>Edit</Button>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                </Grid>
            )
        } else {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={handleBack} fullWidth>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            )
        }
    }

    return (
        <main className={classes.layout}>
            <SupplierCustomerComponent supplierCustomer={supplierCustomer} isEdit={isEdit} onChange={onChange} />
            {renderButtons()}
        </main>
    )

}

export default ViewSupplierCustomer;