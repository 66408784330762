import { ERROR, SUCCESS } from '../actionTypes';
import { logoutSuccess } from './authActions';

export const onError = (error) => {
    if (error.status === 401) {
        localStorage.removeItem('jwt');
        return (dispatch) => {
            dispatch(logoutSuccess())
        };
    }
    return {
        type: ERROR,
        error: error
    };
};

export const onSuccess = (message) => {
    return { type: SUCCESS, message: message };
}