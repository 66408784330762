import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState } from 'react';
import { cleanStringInput } from '../../../Utility/StringUtil';

const AccountLocationComponent = (props) => {

    const [accountLocations, setAccountLocations] = useState([{ locationName: '', edc: '', zone: '' }]);

    useEffect(() => {
        if (props.account && props.account.accountLocations && props.account.accountLocations.length > 0) {
            setAccountLocations(props.account.accountLocations);
        }
    }, [props])

    const onLocationNameChange = (e, index) => {
        accountLocations[index].locationName = e.target.value;
        setAccountLocations(accountLocations);
        if (props.onChange) {
            props.onChange(accountLocations);
        }
    }

    const onEdcChange = (e, index) => {
        accountLocations[index].edc = e.target.value;
        setAccountLocations(accountLocations);
        if (props.onChange) {
            props.onChange(accountLocations);
        }
    }

    const onZoneChange = (e, index) => {
        accountLocations[index].zone = e.target.value;
        setAccountLocations(accountLocations);
        if (props.onChange) {
            props.onChange(accountLocations);
        }
    }

    const addIconClick = () => {
        accountLocations.push({ locationName: '', edc: '', zone: '' })
        setAccountLocations([...accountLocations]);
        if (props.onChange) {
            props.onChange(accountLocations);
        }
    }

    const renderAddIcon = (totalCount, index) => {
        if (index === totalCount && props.canEdit) {
            return (
                <IconButton onClick={addIconClick}>
                    <AddIcon />
                </IconButton>
            )
        }
    }

    const removeIconClick = (index) => {
        accountLocations.splice(index, 1);
        setAccountLocations([...accountLocations]);
        if (props.onChange) {
            props.onChange(accountLocations);
        }
    }

    const renderPjmAccount = (location, index) => {
        if (props.account && props.account.iso && props.account.iso === 'PJM') {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Zone" name="zone" value={cleanStringInput(location.zone)} onChange={(e) => onZoneChange(e, index)} fullWidth
                            disabled={!props.canEdit} />
                    </Grid>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4} />
                </React.Fragment>
            )
        }
    }

    const renderAccountLocations = () => {
        const renderedLocations = [];
        for (let i = 0; i < accountLocations.length; i++) {
            let location = accountLocations[i];
            renderedLocations.push(
                <React.Fragment>
                    <Typography align="center">{location.locationName ? location.locationName : 'Account Location'}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={6} sm={4}>
                            <TextField label="Location Name" name="locationName" value={cleanStringInput(location.locationName)}
                                onChange={(e) => onLocationNameChange(e, i)} fullWidth disabled={!props.canEdit} />
                        </Grid>
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField label="EDC" name="edc" value={cleanStringInput(location.edc)} onChange={(e) => onEdcChange(e, i)} fullWidth
                                disabled={!props.canEdit} />
                        </Grid>
                        <Grid item xs={2} />
                        {renderPjmAccount(location, i)}
                        <Grid item xs={6} sm={1}>
                            {renderAddIcon(accountLocations.length - 1, i)}
                            {props.canEdit && <IconButton onClick={() => removeIconClick(i)}>
                                <RemoveIcon />
                            </IconButton>}
                        </Grid>
                    </Grid>
                </React.Fragment>)
        }
        return renderedLocations;
    }

    return (
        <React.Fragment>
            {renderAccountLocations()}
        </React.Fragment>
    )
}

export default AccountLocationComponent;