import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import accountsReducer from "./account-management/reducers/accountReducer";
import { LOGOUT } from './actionTypes';
import capacityExchangeReducer from "./capacity-exchange/reducers/CapacityExchangeReducer";
import EnergyMarketReducer from './energy-markets/energyMarketReducer';
import FercEqrReducer from './ferc-eqr/FercEqrReducer';
import SupplierCustomerReducer from './gas-supply/SupplierCustomerReducer';
import authReducer from './reducers/AuthReducer';
import chosenAccountReducer from './reducers/ChosenAccountReducer';
import chosenApplicationReducer from './reducers/ChosenApplicationReducer';
import popupReducer from './reducers/PopupReducer';
import SolarForecastReducer from './solar-forecast/SolarForecastReducer';
import accountRoleReducer from "./user-management/reducers/accountRoleReducer";
import applicationRoleReducer from "./user-management/reducers/applicationRoleReducer";
import userReducer from './user-management/reducers/userReducer';

const appReducers = combineReducers({
    auth: authReducer,
    chosenAccount: chosenAccountReducer,
    popup: popupReducer,
    users: userReducer,
    chosenApplication: chosenApplicationReducer,
    accountRoles: accountRoleReducer,
    applicationRoles: applicationRoleReducer,
    accounts: accountsReducer,
    capacity: capacityExchangeReducer,
    fercEqr: FercEqrReducer,
    energyMarket: EnergyMarketReducer,
    supplierCustomer: SupplierCustomerReducer,
    solarForecast: SolarForecastReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducers(state, action);
};

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;