import { ACCOUNT_CHANGED } from "../actionTypes";
import { EQR_ACCOUNT_CHOSEN, EQR_SUBMISSIONS_RETRIEVED, EQR_SUBMISSION_UPDATED, RETRIEVE_EQR_ACCOUNTS, SELECT_EQR_ACCOUNT, SET_EQR_SUBMISSIONS_PAGE_SIZE } from "./FercEqrActionTypes";

const initialState = {
    eqrAccounts: [],
    eqrAccount: {},
    chosenEqrAccount: {},
    eqrSubmissions: {},
    eqrSubmissionsPageSize: 10
}

const FercEqrReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_EQR_ACCOUNTS:
            return { ...state, eqrAccounts: action.eqrAccounts };
        case SELECT_EQR_ACCOUNT:
            return { ...state, eqrAccount: action.eqrAccount };
        case EQR_ACCOUNT_CHOSEN:
            return { ...state, chosenEqrAccount: action.chosenEqrAccount };
        case EQR_SUBMISSIONS_RETRIEVED:
            return { ...state, eqrSubmissions: action.eqrSubmissions };
        case EQR_SUBMISSION_UPDATED:
            return eqrSubmissionUpdated(state, action);
        case SET_EQR_SUBMISSIONS_PAGE_SIZE:
            return { ...state, eqrSubmissionsPageSize: action.eqrSubmissionsPageSize };
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;

    }
}

const eqrSubmissionUpdated = (state, action) => {
    let eqrSubmissions = state.eqrSubmissions;
    let index = -1;
    for (let i = 0; i < eqrSubmissions.results.length; i++) {
        if (eqrSubmissions.results[i].id === action.eqrSubmission.id) {
            index = i;
        }
    }
    if (index !== -1) {
        eqrSubmissions.results.splice(index, 1);
    }
    eqrSubmissions.results.push(action.eqrSubmission);
    return { ...state, eqrSubmissions: eqrSubmissions };
}

export default FercEqrReducer;