import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router";
import useStyles from '../../../Styles';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../../store/actions/popupActions';
import LocationDetailComponent from './LocationDetailComponent';


const CreateLocationDetail = (props) => {

    const classes = useStyles(props);
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [locationDetail, setLocationDetail] = useState({});


    const onInputChangeHandler = (input) => {
        setLocationDetail({ ...input });
    }

    const handleSaveClick = async () => {
        await trackPromise(axios.post("/solar-forecast/account/" + params.accountId + "/location/detail", JSON.stringify(locationDetail)).then(response => {
            dispatch(onSuccess("Created Solar Location"));
            history.goBack();
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    return (
        <main className={classes.layout}>
            <Typography variant='h4' align="center" style={{ marginBottom: 25 }}>Create Solar Location</Typography>
            <LocationDetailComponent inputChangeHandler={onInputChangeHandler} isEdit={true} />
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4} align="center">
                    <Button onClick={handleSaveClick} variant="contained">Save</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
        </main>
    )

}

export default CreateLocationDetail;