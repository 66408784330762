import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import { cleanStringInput } from "../../../Utility/StringUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import EqrTransactionsTable from "./EqrTransactionsTable";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const EqrTransactions = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [eqrTransactionsFileName, setEqrTransactionsFileName] = useState('');
    const [uploadedEqrTransactionsFile, setEqrTransactionsReadingFile] = useState();
    const [uploadYear, setUploadYear] = useState('');
    const [uploadQuarter, setUploadQuarter] = useState('');
    const [searchYear, setSearchYear] = useState('');
    const [searchQuarter, setSearchQuarter] = useState('');
    const [eqrTransactions, setEqrTransactions] = useState();

    const fileChosen = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        setEqrTransactionsFileName(e.target.files[0].name);
        setEqrTransactionsReadingFile(formData);
    }

    const uploadEqrTransactions = async () => {
        await trackPromise(axios.post("/ferc/eqr/eqr-account/" + params.eqrAccountId + "/transactions/year/" + uploadYear + "/quarter/" + uploadQuarter, uploadedEqrTransactionsFile).then(response => {
            dispatch(onSuccess("EQR Transactions for " + uploadQuarter + "Q" + uploadYear + " uploaded successfully"))
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const retrieveEqrTransactions = async (pageNumber = 0, pageSize = 10, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction };
        await trackPromise(axios.get("/ferc/eqr/eqr-account/" + params.eqrAccountId + "/transactions/year/" + searchYear + "/quarter/" + searchQuarter, { params: { ...urlParams } }).then(response => {
            setEqrTransactions(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h5" align="center" style={{ marginBottom: 50 }}>Unsubmitted EQR Transactions</Typography>
            {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_upload_eqr_transactions') &&
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={2}>
                        <TextField label="Year (YYYY)" name="year" value={cleanStringInput(uploadYear)} onChange={(e) => setUploadYear(e.target.value)}
                            fullWidth type="number" />
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Quarter</InputLabel>
                            <Select value={uploadQuarter} onChange={(e) => setUploadQuarter(e.target.value)} fullWidth>
                                <MenuItem key='Q1' value='1'>Q1</MenuItem>
                                <MenuItem key='Q2' value='2'>Q2</MenuItem>
                                <MenuItem key='Q3' value='3'>Q3</MenuItem>
                                <MenuItem key='Q4' value='4'>Q4</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={5}>
                        <label htmlFor="uploadEqrTransactions">
                            <TextField value={eqrTransactionsFileName} label="Upload EQR Transactions"
                                InputProps={{ startAdornment: <Button component="span" style={{ width: '30%' }}>Upload File</Button> }} fullWidth mt={3} />
                            <input style={{ display: "none" }} id="uploadEqrTransactions" name="uploadEqrTransactions" type="file" onChange={fileChosen} />
                        </label>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={uploadEqrTransactions} fullWidth>Upload</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            }
            {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_retrieve_eqr_transactions') &&
                <React.Fragment>
                    <Typography variant="h6" align="center" style={{ marginTop: 25, marginBottom: 25 }}>Transactions</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <TextField label="Year (YYYY)" name="searchYear" value={cleanStringInput(searchYear)} onChange={(e) => setSearchYear(e.target.value)}
                                fullWidth type="number" />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Quarter</InputLabel>
                                <Select value={searchQuarter} onChange={(e) => setSearchQuarter(e.target.value)} fullWidth>
                                    <MenuItem key='Q1' value='1'>Q1</MenuItem>
                                    <MenuItem key='Q2' value='2'>Q2</MenuItem>
                                    <MenuItem key='Q3' value='3'>Q3</MenuItem>
                                    <MenuItem key='Q4' value='4'>Q4</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={3}>
                            <Button onClick={(e) => retrieveEqrTransactions()} fullWidth>Search</Button>
                        </Grid>
                    </Grid>
                    <EqrTransactionsTable eqrTransactions={eqrTransactions} retrieveEqrTransactions={retrieveEqrTransactions} />
                </React.Fragment>
            }
        </main>
    )

}

export default EqrTransactions;