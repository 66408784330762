import { Button, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import { LOCATION_DETAILS_DIRECTION, LOCATION_DETAILS_ORDER_BY, LOCATION_DETAILS_PAGE_SIZE, RETRIEVE_SOLAR_LOCATIONS } from "../../../store/solar-forecast/SolarForecastActionTypes";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const ViewLocationDetails = (props) => {

    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const pageSize = useSelector(state => state.solarForecast.locationDetails.pageSize);
    const direction = useSelector(state => state.solarForecast.locationDetails.direction);
    const orderBy = useSelector(state => state.solarForecast.locationDetails.orderBy);
    const locationDetails = useSelector(state => state.solarForecast.locationDetails.results);

    useEffect(() => {
        if (locationDetails && locationDetails.pageNumber) {
            retrieveLocations(locationDetails.pageNumber, pageSize, orderBy, direction);
        } else {
            retrieveLocations(0, pageSize, orderBy, direction);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveLocations = async (pageNumber, pageSize, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction }
        await trackPromise(axios.get("/solar-forecast/account/" + params.accountId + "/location/detail", { params: { ...urlParams } }).then(response => {
            dispatch({ type: RETRIEVE_SOLAR_LOCATIONS, locationDetails: response.data });
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handlePageChange = (pageNumber) => {
        retrieveLocations(pageNumber, pageSize, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        dispatch({ type: LOCATION_DETAILS_PAGE_SIZE, pageSize: e.target.value });
        retrieveLocations(0, e.target.value, orderBy, direction);
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        dispatch({ type: LOCATION_DETAILS_ORDER_BY, orderBy: column });
        dispatch({ type: LOCATION_DETAILS_DIRECTION, direction: sortDirection });
        retrieveLocations(0, pageSize, column, sortDirection);
    }

    const handleCreateLocationDetails = () => {
        history.push("/solar-forecast/account/" + params.accountId + "/location/create");
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h4" align="center" style={{ marginBottom: 25 }}>Locations</Typography>
            {hasPermissionForAccount(roles, chosenAccount.id, "ROLE_get_location_detail") &&
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} align="center">
                        <Button onClick={handleCreateLocationDetails} variant="contained">Create Location</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            }
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel active={orderBy === "name"} direction={orderBy === "name" ? direction : 'asc'} onClick={() => handleSort("name")}>
                                Name
                                {orderBy === "name" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "city"} direction={orderBy === "city" ? direction : 'asc'} onClick={() => handleSort("city")}>
                                City
                                {orderBy === "city" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "state"} direction={orderBy === "state" ? direction : 'asc'} onClick={() => handleSort("state")}>
                                State
                                {orderBy === "state" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "timezone"} direction={orderBy === "timezone" ? direction : 'asc'} onClick={() => handleSort("timezone")}>
                                Time Zone
                                {orderBy === "time" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "latitude"} direction={orderBy === "latitude" ? direction : 'asc'} onClick={() => handleSort("latitude")}>
                                Latitude
                                {orderBy === "latitude" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "longitude"} direction={orderBy === "longitude" ? direction : 'asc'} onClick={() => handleSort("longitude")}>
                                Longitude
                                {orderBy === "longitude" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "heightAboveSeaLevel"} direction={orderBy === "heightAboveSeaLevel" ? direction : 'asc'} onClick={() => handleSort("heightAboveSeaLevel")}>
                                Elevation
                                {orderBy === "heightAboveSeaLevel" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locationDetails && locationDetails.results && locationDetails.results.length > 0 &&
                        locationDetails.results.map(r => {
                            return (
                                <TableRow key={r.id} className={classes.tableRow}
                                    onClick={() => history.push("/solar-forecast/account/" + r.accountId + "/location/" + r.id)}>
                                    <TableCell>{r.name}</TableCell>
                                    <TableCell>{r.city}</TableCell>
                                    <TableCell>{r.state}</TableCell>
                                    <TableCell>{r.timezone}</TableCell>
                                    <TableCell><NumberFormat value={r.latitude} displayType={'text'} fixedDecimalScale={true} decimalScale={5}
                                        thousandSeparator={false} /></TableCell>
                                    <TableCell><NumberFormat value={r.longitude} displayType={'text'} fixedDecimalScale={true} decimalScale={5}
                                        thousandSeparator={false} /></TableCell>
                                    <TableCell><NumberFormat value={r.heightAboveSeaLevel} displayType={'text'} fixedDecimalScale={true} decimalScale={0}
                                        thousandSeparator={false} /></TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination count={locationDetails.totalCount ? locationDetails.totalCount : 0} onPageChange={handlePageChange}
                            page={locationDetails.pageNumber ? locationDetails.pageNumber : 0}
                            rowsPerPage={calculateRowsPerPage(locationDetails.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={[10, 25, 50]} colSpan={7} ActionsComponent={PaginatedTableActions} />
                    </TableRow>
                </TableFooter>
            </Table>
        </main>
    )

};

export default ViewLocationDetails;