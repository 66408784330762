import { ACCOUNT_CHANGED } from "../../actionTypes";
import { CHANGE_USER_TABLE_PAGE_SIZE, RETRIEVE_USERS_SUCCESS } from '../userManagementActionTypes';

const initialState = {
    users: [],
    user: undefined,
    accountRoles: [],
    userTablePageSize: 10,
};

const retrieveUsers = (state, action) => {
    return { ...state, users: action.users };
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_USERS_SUCCESS:
            return retrieveUsers(state, action);
        case ACCOUNT_CHANGED:
            return initialState;
        case CHANGE_USER_TABLE_PAGE_SIZE:
            return { ...state, userTablePageSize: action.userTablePageSize };
        default:
            return state;
    }
};

export default userReducer;