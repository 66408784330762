import { TextField } from "@material-ui/core";
import React from 'react';
import NumberFormat from "react-number-format";


const TwoDigitCurrency = (props) => {
    return (
        <NumberFormat {...props.other} getInputRef={props.inputRef} customInput={TextField}
            onValueChange={(values) => props.onChange({ target: { name: props.name, value: values.value } })}
            thousandSeparator displayType="input" fixedDecimalScale={true} decimalScale={2} prefix="$" allowNegative={props.allowNegative ? props.allowNegative : false} fullWidth disabled={!props.isEdit} value={props.value} />
    )
}

export default TwoDigitCurrency;