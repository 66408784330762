import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useStyles from "../../../Styles";
import { cleanStringInput } from '../../../Utility/StringUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../../store/actions/popupActions';

const ViewSupplierAccount = (props) => {

    const classes = useStyles(props);
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [supplierAccount, setSupplierAccount] = useState({});

    useEffect(() => {
        retrieveSupplierAccount();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const onValueChange = (e) => {
        supplierAccount[e.target.name] = e.target.value;
        setSupplierAccount({ ...supplierAccount });
    }

    const retrieveSupplierAccount = async () => {
        await trackPromise(axios.get("/gas-service/supplier/" + params.supplierAccountId).then(response => {
            setSupplierAccount(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleSave = async () => {
        await trackPromise(axios.put("/gas-service/supplier/" + params.supplierAccountId, JSON.stringify(supplierAccount)).then(response => {
            dispatch(onSuccess("Supplier updated successfully"));
            history.push("/gas/supplier/account");
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    return (
        <main className={classes.layout}>
            <Typography variant='h5' style={{ marginBottom: 25 }} align='center'>{supplierAccount ? supplierAccount.supplierName : ''}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField name="supplierName" label="Supplier Name" onChange={(e) => onValueChange(e)} value={cleanStringInput(supplierAccount.supplierName)} fullWidth disabled={false} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <TextField name="supplierGroupNumber" label="Supplier Group Number" onChange={(e) => onValueChange(e)} value={cleanStringInput(supplierAccount.supplierGroupNumber)} fullWidth disabled={false} />
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={() => history.goBack()} fullWidth>Back</Button>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={handleSave} fullWidth>Save</Button>
                </Grid>
                <Grid item xs={6} sm={1} />
            </Grid>
        </main>
    )

}

export default ViewSupplierAccount;