import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import { withStyles, withTheme } from "@material-ui/core/styles";
import React from 'react';
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { chooseAccount } from "../../store/actions/chosenAccountActions";

const styles = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
});

const AccountChooser = (props) => {

    const { classes } = props;

    const handleAccountSelectChange = (e) => {
        let account = props.accounts.find((element) => (element.id === e.target.value));
        props.chooseAccount(account);
        let currentUrl = props.history.location.pathname;
        let prefix = currentUrl.substring(0, currentUrl.indexOf('account') + 'account'.length + 1);
        let suffix1 = currentUrl.substring(currentUrl.indexOf('account') + 'account'.length + 1);
        let suffix2 = suffix1.substring(suffix1.indexOf('/') + 1);
        let suffix3;
        if (suffix2.indexOf('/') !== -1) {
            suffix3 = suffix2.substring(0, suffix2.indexOf('/'));
        } else {
            suffix3 = suffix2;
        }
        props.history.replace(prefix + account.id + '/' + suffix3);
    };

    const createUserMenuItems = () => {
        return props.accounts.sort((c1, c2) => { return c1.customerAccount.localeCompare(c2.customerAccount) }).map((account) => (
            <MenuItem value={account.id} key={account.id}>{account.customerAccount}</MenuItem>
        ));
    };

    const userMenuItems = createUserMenuItems();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>Choose your account</InputLabel>
            <Select value={props.chosenAccount.id} onChange={handleAccountSelectChange} key={props.chosenAccount.id}>
                {userMenuItems}
            </Select>
        </FormControl>
    )
};

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        accounts: state.auth.accounts
    };
};

const mapDispatchToProps = dispatch => {
    return {
        chooseAccount: (account, isDefault) => dispatch(chooseAccount(account, isDefault)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(withRouter(AccountChooser))));
