import { Typography } from "@material-ui/core";
import React from 'react';
import { useParams } from "react-router";
import useStyles from "../../../Styles";
import GeneratorUnitComponent from "./GeneratorUnitComponent";

const CreateGeneratorUnit = (props) => {

    const classes = useStyles(props);
    const params = useParams();

    return (
        <main className={classes.layout}>
            <Typography variant="h4" align="center">Create Generator</Typography>
            <GeneratorUnitComponent accountId={params.accountId} isEdit={true} />
        </main>
    )

}

export default CreateGeneratorUnit;