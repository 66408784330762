import DateFnsUtils from "@date-io/date-fns";
import { Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { cleanStringInput } from '../../../Utility/StringUtil';


const SupplierCustomerComponent = (props) => {

    const [effectiveDate, setEffectiveDate] = useState();
    const [supplierCustomer, setSupplierCustomer] = useState({ utilityName: 'WG', commodity: 'GAS', unitOfMeasure: 'THERMS', billMethod: 'UCB BR' });

    useEffect(() => {
        if (props.supplierCustomer) {
            setSupplierCustomer({ ...props.supplierCustomer });
            if (props.supplierCustomer.effectiveDate) {
                setEffectiveDate(moment(props.supplierCustomer.effectiveDate));
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const onValueChange = (e) => {
        supplierCustomer[e.target.name] = e.target.value;
        setSupplierCustomer({ ...supplierCustomer });
        if (props.onChange) {
            props.onChange(supplierCustomer);
        }
    }

    const effecitveDateChangeHandler = (date) => {
        setEffectiveDate(date);
        supplierCustomer.effectiveDate = moment(date).format("YYYY-MM-DD");
        setSupplierCustomer({ ...supplierCustomer });
        if (props.onChange) {
            props.onChange(supplierCustomer);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={4}>
                <TextField name='utilityName' label='Utility Name' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.utilityName)} fullWidth disabled={true} />
            </Grid>
            <Grid item xs={6} sm={2} />
            <Grid item xs={6} sm={4}>
                <TextField name='utilityAccountNumber' label='Utility Account Number' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.utilityAccountNumber)} fullWidth disabled={!props.isEdit} />
            </Grid>
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={4}>
                <TextField name='accountName' label='Account Name' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.accountName)} fullWidth disabled={!props.isEdit} />
            </Grid>
            <Grid item xs={6} sm={2} />
            <Grid item xs={6} sm={4}>
                <TextField name='billingName' label='Billing Name' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.billingName)} fullWidth disabled={!props.isEdit} />
            </Grid>
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={10}>
                <TextField name='serviceAddress' label='Service Address' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.serviceAddress)} fullWidth disabled={!props.isEdit} />
            </Grid>
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={10}>
                <TextField name='billingAddress' label='Billing Address' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.billingAddress)} fullWidth disabled={!props.isEdit} />
            </Grid>
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={4}>
                <TextField name='commodity' label='Commodity' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.commodity)} fullWidth disabled={true} />
            </Grid>
            <Grid item xs={6} sm={2} />
            <Grid item xs={6} sm={4}>
                <TextField name='unitOfMeasure' label='Unit of Measure' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.unitOfMeasure)} fullWidth disabled={true} />
            </Grid>
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={4}>
                <TextField name='billMethod' label='Bill Method' onChange={(e) => onValueChange(e)}
                    value={cleanStringInput(supplierCustomer.billMethod)} fullWidth disabled={true} />
            </Grid>
            <Grid item xs={6} sm={2} />
            <Grid item xs={6} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Effective Date" name="Effective Date"
                        onChange={effecitveDateChangeHandler} value={effectiveDate} disabled={!props.isEdit} fullWidth autoOk={true} />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={1} />
            <Grid item xs={6} sm={4}>
                <TextField name='supplierCustomerStatus' label="Status" value={cleanStringInput(supplierCustomer.supplierCustomerStatus)} disabled={true} fullWidth />
            </Grid>
        </Grid>
    )
}

export default SupplierCustomerComponent;