import React, { useEffect, useState } from 'react';
import Plot from "react-plotly.js";


const SolarForecastGraph = (props) => {

    const { solarForecast } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        formatData();
        // eslint-disable-next-line
    }, [props])

    const formatData = () => {
        setData([]);
        if (solarForecast && solarForecast.length > 0) {
            data.push({
                x: solarForecast.map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                y: solarForecast.map(r => r.totalLocationOutput),
                type: 'line',
                name: 'Solar Forecast'
            })
        }
        setData([...data]);
    }

    return (
        <React.Fragment>
            {
                data && data.length > 0 &&
                <Plot data={data}
                    layout={{
                        width: 1200,
                        height: 600,
                        title: 'Solar Forecast',
                        showlegend: true,
                        xaxis: { title: { text: 'Date (Hour Ending)' } },
                        yaxis: { title: { text: 'Output (MWs)' } }
                    }}
                    config={{ displaylogo: false }}
                />
            }
        </React.Fragment>
    )

}

export default SolarForecastGraph;