import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from "../../../Styles";
import { cleanStringInput } from '../../../Utility/StringUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from "../../../store/actions/popupActions";


const CreateSupplierAccount = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const [wpecAccounts, setWpecAccounts] = useState({});
    const [supplierAccount, setSupplierAccount] = useState({ supplierName: '', supplierGroupNumber: '' });
    const [wpecAccount, setWpecAccount] = useState({ id: '' });

    useEffect(() => {
        retrieveWpecAccounts();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onValueChange = (e) => {
        supplierAccount[e.target.name] = e.target.value;
        setSupplierAccount({ ...supplierAccount });
    }

    const handleSave = async () => {
        supplierAccount.wpecAccountId = wpecAccount.id;
        await trackPromise(axios.post("/gas-service/supplier", JSON.stringify(supplierAccount)).then(response => {
            dispatch(onSuccess("Gas Supplier created successfully"));
            history.push("/gas/supplier/account");
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const retrieveWpecAccounts = async () => {
        await trackPromise(axios.get("/account-service/v4/account?pageNumber=0&pageSize=1000").then(response => {
            setWpecAccounts(response.data);
            if (response.data && response.data.results && response.data.results.length > 0) {
                setWpecAccount(response.data.results[0]);
            }
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const handleWpecMenuChange = (e) => {
        const chosenAccount = wpecAccounts.results.find((element) => (element.id === e.target.value));
        setWpecAccount(chosenAccount);
        supplierAccount.supplierName = chosenAccount.customerAccount;
        setSupplierAccount({ ...supplierAccount });
    }

    const renderWpecAccounts = () => {
        if (wpecAccounts && wpecAccounts.results && wpecAccounts.results.length > 0) {
            return wpecAccounts.results.sort((c1, c2) => { return c1.customerAccount.localeCompare(c2.customerAccount) }).map((wpecAccount) => {
                return <MenuItem value={wpecAccount.id} key={wpecAccount.id}>{wpecAccount.customerAccount}</MenuItem>
            });
        }
    }

    return (
        <main className={classes.layout}>
            <Typography variant='h4' align='center' style={{ marginBottom: 25 }}>Create Supplier Account</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Choose Account</InputLabel>
                        <Select fullWidth value={wpecAccount.id} onChange={handleWpecMenuChange}>
                            {renderWpecAccounts()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4}>
                    <TextField name="supplierName" label="Supplier Name" onChange={(e) => onValueChange(e)} value={cleanStringInput(supplierAccount.supplierName)} fullWidth disabled={false} />
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={4}>
                    <TextField name="supplierGroupNumber" label="Supplier Group Number" onChange={(e) => onValueChange(e)} value={cleanStringInput(supplierAccount.supplierGroupNumber)} fullWidth disabled={false} />
                </Grid>
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <Button onClick={handleSave} fullWidth>Save</Button>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
        </main>
    )

};

export default CreateSupplierAccount;