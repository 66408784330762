import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";

const CapacityExchangeAppBar = () => {

    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [ctrAnchorEl, setCtrAnchorEl] = React.useState(null);
    const [deliveryYearAuctionAnchorEl, setDeliveryYearAuctionAnchorEl] = React.useState(null);
    const [projectStatusAnchorEl, setProjectStatusAnchorEl] = React.useState(null);
    const [iCtrAnchorEl, setICtrAnchorEl] = React.useState(null);
    const [rpmParameterAnchorEl, setRpmParameterAnchorEl] = React.useState(null);
    const [rcpAnchorEl, setRcpAnchorEl] = React.useState(null);
    const [zarAnchorEl, setZarAnchorEl] = React.useState(null);
    const [capacityPositionAnchorEl, setCapacityPositionAnchorEl] = React.useState(null);
    const history = useHistory();

    const handleCtrClick = (e) => {
        setCtrAnchorEl(e.currentTarget);
    }

    const handleCtrMenuClose = () => {
        setCtrAnchorEl(null);
    }

    const handleCtrMenuItemClick = (path) => {
        history.push(path);
        setCtrAnchorEl(null);
    }

    const handleDyAuctionClick = (e) => {
        setDeliveryYearAuctionAnchorEl(e.currentTarget);
    }

    const handleDyAuctionClose = () => {
        setDeliveryYearAuctionAnchorEl(null);
    }

    const handleDyAuctionMenuItemClick = (path) => {
        history.push(path);
        setDeliveryYearAuctionAnchorEl(null);
    }

    const handleProjectStatusClick = (e) => {
        setProjectStatusAnchorEl(e.currentTarget);
    }

    const handleProjectStatusClose = () => {
        setProjectStatusAnchorEl(null);
    }

    const handleProjectStatusMenuItemClick = (path) => {
        history.push(path);
        setProjectStatusAnchorEl(null);
    }

    const handleICtrClick = (e) => {
        setICtrAnchorEl(e.currentTarget);
    }

    const handleICtrMenuClose = () => {
        setICtrAnchorEl(null);
    }

    const handleICtrMenuItemClick = (path) => {
        history.push(path);
        setICtrAnchorEl(null);
    }

    const handleRpmParameterMenuClick = (e) => {
        setRpmParameterAnchorEl(e.currentTarget);
    }

    const handleRpmParameterMenuClose = () => {
        setRpmParameterAnchorEl(null);
    }

    const handleRpmParameterMenuItemClick = (path) => {
        history.push(path);
        setRpmParameterAnchorEl(null);
    }

    const handleRcpMenuClick = (e) => {
        setRcpAnchorEl(e.currentTarget);
    }

    const handleRcpMenuClose = () => {
        setRcpAnchorEl(null);
    }

    const handleRcpMenuItemClick = (path) => {
        history.push(path);
        setRcpAnchorEl(null);
    }

    const handleZarMenuClick = (e) => {
        setZarAnchorEl(e.currentTarget);
    }

    const handleZarMenuClose = () => {
        setZarAnchorEl(null);
    }

    const handleZarMenuItemClick = (path) => {
        history.push(path);
        setZarAnchorEl(null);
    }

    const handleCapacityPositionMenuClick = (e) => {
        setCapacityPositionAnchorEl(e.currentTarget);
    }

    const handleCapacityPositionMenuClose = () => {
        setCapacityPositionAnchorEl(null);
    }

    const handleCapacityPositionMenuItemClick = (path) => {
        history.push(path);
        setCapacityPositionAnchorEl(null);
    }

    const buildDeliveryYearAuctionMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_delivery_year_auction')) {
            menuItems.push(<MenuItem key='ROLE_create_delivery_year_auction' onClick={() => handleDyAuctionMenuItemClick("/capacity/deliveryYearAuctions/create")}>Create Delivery
                Year Auction</MenuItem>);
        }
        menuItems.push(<MenuItem key='ROLE_get_delivery_year_auction' onClick={() => handleDyAuctionMenuItemClick("/capacity/deliveryYearAuctions/view")}>View Delivery Year
            Auctions</MenuItem>);
        if (menuItems.length === 1) {
            return (<Button onClick={() => handleDyAuctionMenuItemClick("/capacity/deliveryYearAuctions/view")}>Delivery Year Auctions</Button>);
        } else if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleDyAuctionClick}>Delivery Year Auctions</Button>
                    <Menu open={Boolean(deliveryYearAuctionAnchorEl)} onClose={handleDyAuctionClose} anchorEl={deliveryYearAuctionAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    const buildCapacityPositionMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_delivery_year_position')) {
            menuItems.push(<MenuItem key='ROLE_get_delivery_year_position'
                onClick={() => handleCapacityPositionMenuItemClick("/capacity/account/" + chosenAccount.id + "/commitment")}>Capacity Commitment</MenuItem>);
        }
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_peak_load_summary')) {
            menuItems.push(<MenuItem key='ROLE_get_peak_load_summary' onClick={() => handleCapacityPositionMenuItemClick("/capacity/account/" + chosenAccount.id + "/obligation")}>Capacity
                Obligation</MenuItem>);
        }
        if (menuItems && menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleCapacityPositionMenuClick}>Capacity Position</Button>
                    <Menu open={Boolean(capacityPositionAnchorEl)} onClose={handleCapacityPositionMenuClose} anchorEl={capacityPositionAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    const buildResourceClearingPriceMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_resource_clearing_price')) {
            menuItems.push(<MenuItem key='ROLE_create_resource_clearing_price' onClick={() => handleRcpMenuItemClick("/capacity/resourceClearingPrice/create")}>Create Resource
                Clearing Prices</MenuItem>);
        }
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_resource_clearing_price') ||
            hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_resource_clearing_price') ||
            hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_resource_clearing_price')) {
            menuItems.push(<MenuItem key='ROLE_get_resource_clearing_price' onClick={() => handleRcpMenuItemClick("/capacity/resourceClearingPrice/view")}>View Resource Clearing
                Prices</MenuItem>);
        }
        if (menuItems.length === 1 && hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_resource_clearing_price')) {
            return (<Button onClick={() => handleRcpMenuItemClick("/capacity/resourceClearingPrice/view")}>Resource Clearing Prices</Button>);
        } else if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleRcpMenuClick}>Resource Clearing Prices</Button>
                    <Menu open={Boolean(rcpAnchorEl)} onClose={handleRcpMenuClose} anchorEl={rcpAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>);
        }
    }

    const buildZonalResultMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_zonal_auction_result')) {
            menuItems.push(<MenuItem key='ROLE_create_zonal_auction_result' onClick={() => handleZarMenuItemClick("/capacity/zonalResult/create")}>Create Zonal Results</MenuItem>);
        }
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_zonal_auction_results') ||
            hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_zonal_auction_result') ||
            hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_zonal_auction_result')) {
            menuItems.push(<MenuItem key='ROLE_get_zonal_auction_results' onClick={() => handleZarMenuItemClick("/capacity/zonalResult/view")}>View Zonal Results</MenuItem>);
        }
        if (menuItems.length === 1 && hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_zonal_auction_results')) {
            return (<Button onClick={() => handleZarMenuItemClick("/capacity/zonalResult/view")}>Zonal Result</Button>);
        } else if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleZarMenuClick}>Zonal Result</Button>
                    <Menu open={Boolean(zarAnchorEl)} onClose={handleZarMenuClose} anchorEl={zarAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    const buildRpmParametersMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_rpm_parameter')) {
            menuItems.push(<MenuItem key='ROLE_create_rpm_parameter' onClick={() => handleRpmParameterMenuItemClick("/capacity/rpmParameter/create")}>Create RPM
                Parameter</MenuItem>);
        }
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_rpm_parameter')) {
            menuItems.push(<MenuItem key='ROLE_get_rpm_parameter' onClick={() => handleRpmParameterMenuItemClick("/capacity/rpmParameter/view")}>View RPM Parameters</MenuItem>);
        }
        if (menuItems.length === 1 && hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_rpm_parameter')) {
            return (<Button onClick={() => handleRpmParameterMenuItemClick("/capacity/rpmParameter/view")}>RPM Parameters</Button>);
        } else if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleRpmParameterMenuClick}>RPM Parameters</Button>
                    <Menu open={Boolean(rpmParameterAnchorEl)} onClose={handleRpmParameterMenuClose} anchorEl={rpmParameterAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    const buildCapacityTransferRightsMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_capacity_transfer_right')) {
            menuItems.push(<MenuItem key='ROLE_create_capacity_transfer_right' onClick={() => handleCtrMenuItemClick("/capacity/capacityTransferRights/create")}>Create
                CapacityTransfer Rights</MenuItem>);
        }
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_capacity_transfer_right')) {
            menuItems.push(<MenuItem key='ROLE_get_capacity_transfer_right' onClick={() => handleCtrMenuItemClick("/capacity/capacityTransferRights/view")}>View CapacityTransfer
                Rights</MenuItem>);
        }
        if (menuItems.length === 1 && hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_capacity_transfer_right')) {
            return (<Button onClick={() => handleCtrMenuItemClick("/capacity/capacityTransferRights/view")}>Capacity Transfer Rights</Button>);
        } else if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleCtrClick}>CTRs</Button>
                    <Menu open={Boolean(ctrAnchorEl)} onClose={handleCtrMenuClose} anchorEl={ctrAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    const buildProjectStatusMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_project_status_cost_allocation')) {
            menuItems.push(<MenuItem key='ROLE_get_project_status_cost_allocation' onClick={() => handleProjectStatusMenuItemClick("/capacity/projectStatus")}>Project
                Status</MenuItem>);
        }
        if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleProjectStatusClick}>Project Status</Button>
                    <Menu open={Boolean(projectStatusAnchorEl)} onClose={handleProjectStatusClose} anchorEl={projectStatusAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    const buildIctrMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_ictr')) {
            menuItems.push(<MenuItem key='ROLE_create_ictr' onClick={() => handleICtrMenuItemClick("/capacity/projectStatus/ictr/create")}>Create ICTR</MenuItem>);
        }
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_ictr')) {
            menuItems.push(<MenuItem key='ROLE_get_ictr' onClick={() => handleICtrMenuItemClick("/capacity/projectStatus/ictr/view")}>View ICTRs</MenuItem>);
        }
        if (menuItems.length === 1 && hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_ictr')) {
            return (<Button onClick={() => handleICtrMenuItemClick("/capacity/projectStatus/ictr/view")}>Incremental Capacity Transfer Rights</Button>);
        } else if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleICtrClick}>ICTRs</Button>
                    <Menu open={Boolean(iCtrAnchorEl)} onClose={handleICtrMenuClose} anchorEl={iCtrAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    const buildDataRetrievalMenu = () => {
        const retrievalPermissions = ['ROLE_get_capacity_bid_results', 'ROLE_create_capacity_transaction', 'ROLE_create_generator_position', 'ROLE_create_peak_load_summary', 'ROLE_create_resource_offer', 'ROLE_create_resource_offer_result', 'ROLE_create_capacity_bid'];
        for (let retrievalPermission of retrievalPermissions) {
            if (hasPermissionForAccount(roles, chosenAccount.id, retrievalPermission)) {
                return (<Button onClick={() => history.push("/capacity/account/" + chosenAccount.id + "/data-retrieval")}>Data Retrievals</Button>)
            }
        }

    }

    const buildResourceMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_available_resource_offer')) {
            return (<Button onClick={() => history.push("/capacity/account/" + chosenAccount.id + "/resources")}>Capacity Resources</Button>);
        }
    }

    const buildCapacityTransactionsMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_retrieve_capacity_transaction')) {
            return (<Button onClick={() => history.push("/capacity/account/" + chosenAccount.id + "/transactions")}>Capacity Transactions</Button>);
        }
    }

    return (
        <React.Fragment>
            {buildDeliveryYearAuctionMenu()}
            {buildCapacityPositionMenu()}
            {buildResourceClearingPriceMenu()}
            {buildZonalResultMenu()}
            {buildRpmParametersMenu()}
            {buildCapacityTransferRightsMenu()}
            {buildProjectStatusMenu()}
            {buildIctrMenu()}
            {buildResourceMenu()}
            {buildCapacityTransactionsMenu()}
            {buildDataRetrievalMenu()}
        </React.Fragment>
    );
};

export default CapacityExchangeAppBar;