import React from 'react';

import CheckBox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from 'lodash';

const EditableServicePermissions = (props) => {

    const { servicePermissions } = props;
    const updateSelectedPermissionsFunction = props.updateSelectedPermissions;
    const [selectedPermissions, setSelectedPermissions] = React.useState(props.selectedPermissions ? props.selectedPermissions : []);

    const handleCheckBoxChange = (permissionId) => {
        let permissions = [];
        if (selectedPermissions.length === 0) {
            permissions.push(Number.parseInt(permissionId));
        } else {
            permissions = _.clone(selectedPermissions);
            let index = -1;
            for (let i = 0; i < permissions.length; i++) {
                if (permissions[i] === permissionId) {
                    index = i;
                }
            }
            if (index !== -1) {
                permissions.splice(index, 1);
            } else {
                permissions.push(permissionId);
            }
        }
        setSelectedPermissions(permissions);
        if (updateSelectedPermissionsFunction) {
            updateSelectedPermissionsFunction(permissions);
        }
    };

    const isChecked = (permissionId) => {
        if (selectedPermissions) {
            for (let selectedPermission of selectedPermissions) {
                if (selectedPermission === permissionId) {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>Service Name</TableCell>
                    <TableCell>Permission Name</TableCell>
                    <TableCell>Permission Description</TableCell>
                </TableRow>
            </TableHead>
            {servicePermissions &&
                <TableBody>
                    {servicePermissions.sort((r1, r2) => { return r1.serviceName.localeCompare(r2.serviceName) }).map((servicePermission) => (
                        <TableRow key={servicePermission.id}>
                            <TableCell><CheckBox disableRipple key={servicePermission.id} checked={isChecked(servicePermission.id)}
                                onChange={() => handleCheckBoxChange(servicePermission.id)} /></TableCell>
                            <TableCell>{servicePermission.prettyServiceName ? servicePermission.prettyServiceName : servicePermission.serviceName}
                            </TableCell>
                            <TableCell>{servicePermission.permissionPrettyName}</TableCell>
                            <TableCell>{servicePermission.permissionDescription}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            }
        </Table>
    )

}

export default EditableServicePermissions;