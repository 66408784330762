import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError, onSuccess } from '../../../store/actions/popupActions';
import PanelModuleComponent from '../PanelModules/PanelModuleComponent';
import ViewPanelModulesCompnent from '../PanelModules/ViewPanelModulesComponent';
import LocationDetailComponent from './LocationDetailComponent';

const ViewLocationDetail = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [locationDetail, setLocationDetail] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        retrieveLocationDetail(params.accountId, params.locationDetailId);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveLocationDetail = async (accountId, locationDetailId) => {
        await trackPromise(axios.get("/solar-forecast/account/" + accountId + "/location/detail/" + locationDetailId).then(response => {
            setLocationDetail(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const inputChangeHandler = (input) => {
        setLocationDetail({ ...input });
    }

    const handleBackButtonClick = () => {
        history.push("/solar-forecast/account/" + params.accountId + "/location");
    }

    const handleBackFromEditButtonClick = () => {
        setIsEdit(false);
    }

    const handleSaveButtonClick = async () => {
        await trackPromise(axios.put("/solar-forecast/account/" + params.accountId + "/location/detail/" + params.locationDetailId, JSON.stringify(locationDetail)).then(response => {
            dispatch(onSuccess("Location updated successfully"));
            handleBackButtonClick();
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleEditButtonClick = () => {
        setIsEdit(true);
    }

    const displayButtons = () => {
        if (isEdit) {
            return (
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4} align="center">
                            <Button onClick={handleBackFromEditButtonClick} variant="contained">Back</Button>
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4} align="center">
                            <Button onClick={handleSaveButtonClick} variant="contained">Save</Button>
                        </Grid>
                        <Grid item xs={6} sm={1} />
                    </Grid>
                </React.Fragment>
            )
        } else {
            if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_location_detail')) {
                return (
                    <React.Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={4} align="center">
                                <Button onClick={handleBackButtonClick} variant="contained">Back</Button>
                            </Grid>
                            <Grid item xs={6} sm={2} />
                            <Grid item xs={6} sm={4} align="center">
                                <Button onClick={handleEditButtonClick} variant="contained">Edit</Button>
                            </Grid>
                            <Grid item xs={6} sm={1} />
                        </Grid>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4} />
                            <Grid item xs={6} sm={4} align="center">
                                <Button onClick={handleBackButtonClick} variant="contained">Back</Button>
                            </Grid>
                            <Grid item xs={6} sm={4} />
                        </Grid>
                    </React.Fragment>
                )
            }
        }
    }

    return (
        <main className={classes.layout}>
            <LocationDetailComponent locationDetail={locationDetail} isEdit={isEdit} inputChangeHandler={inputChangeHandler} />
            {displayButtons()}
            {(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_panel_module') || hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_panel_module')) &&
                <React.Fragment>
                    <ViewPanelModulesCompnent accountId={params.accountId} locationDetailId={params.locationDetailId} />
                    <PanelModuleComponent accountId={params.accountId} locationDetailId={params.locationDetailId} />
                </React.Fragment>
            }
        </main>
    )

}

export default ViewLocationDetail;