import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { hasPermissionForAccount } from '../../Utility/PermissionsUtil';
import axios from '../../axios/AxiosInterceptors';
import { SUPPLIER_ACCOUNT_CHOSEN } from '../../store/gas-supply/GasSupplyActionTypes';

const GasSupplierAppBar = () => {

    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const chosenSupplierAccount = useSelector(state => state.supplierCustomer.chosenSupplierAccount);
    const roles = useSelector(state => state.auth.roles);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        retrieveSupplierAccountForWpecAccount();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenAccount]);

    const retrieveSupplierAccountForWpecAccount = async () => {
        await trackPromise(axios.get("/gas-service/supplier/wpec/account/" + chosenAccount.id).then(response => {
            return dispatch({ type: SUPPLIER_ACCOUNT_CHOSEN, chosenSupplierAccount: response.data });
        }).catch(error => {
        }));
    }

    const buildSupplierAccountMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_gas_supplier_account')) {
            return (<Button onClick={() => history.push("/gas/supplier/account")}>Supplier Accounts</Button>);
        }
    }

    const buildSupplierCustomerMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_view_gas_supplier_customer')) {
            return (<Button onClick={() => history.push("/gas/supplier/account/" + chosenSupplierAccount.id + "/customer")}>Supplier Customers</Button>);
        }
    }

    return (
        <React.Fragment>
            {buildSupplierAccountMenu()}
            {buildSupplierCustomerMenu()}
        </React.Fragment>
    )

}

export default GasSupplierAppBar;