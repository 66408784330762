import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";

const TransactionEvents = (props) => {

    const dispatch = useDispatch();
    const [events, setEvents] = useState();

    useEffect(() => {
        retrieveEvents(props.accountId, props.transactionType, props.transactionId);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const retrieveEvents = async (accountId, transactionType, transactionId) => {
        await trackPromise(axios.get("/capacity/account/" + accountId + "/transactions/" + transactionType + "/" + transactionId + "/event").then(response => {
            setEvents(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <React.Fragment>
            <Typography variant="h6" align="center" style={{ marginTop: 50 }}>Events</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Timestamp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events && events.length > 0 &&
                        events.map(r => {
                            return (
                                <TableRow key={r.id}>
                                    <TableCell>{r.agentName}</TableCell>
                                    <TableCell>{r.status}</TableCell>
                                    <TableCell>{r.timestamp}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    );

}

export default TransactionEvents;