import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router';
import { hasPermissionForAccount } from '../../Utility/PermissionsUtil';

const PjmBillingAppBar = () => {

    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const history = useHistory();
    const [invoiceReconAnchorEl, setInvoiceReconAnchorEl] = React.useState(null);

    const handleInvoiceReconClick = (e) => {
        setInvoiceReconAnchorEl(e.currentTarget);
    }

    const handleInvoiceReconMenuClick = (path) => {
        history.push(path);
        setInvoiceReconAnchorEl(null);
    }

    const handleInvoiceReconClose = () => {
        setInvoiceReconAnchorEl(null);
    }

    const buildInvoiceReconMenu = () => {
        let menuItems = [];
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_transmission_invoice_recon')) {
            menuItems.push(<MenuItem key='ROLE_create_transmission_invoice_recon' onClick={() => handleInvoiceReconMenuClick("/billing/account/" + chosenAccount.id + "/pjm/reconciliation/create")}>Create Invoice Reconciliation</MenuItem>)
        }
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_transmission_invoice_recon')) {
            menuItems.push(<MenuItem key='ROLE_get_transmission_invoice_recon' onClick={() => handleInvoiceReconMenuClick("/billing/account/" + chosenAccount.id + "/pjm/reconciliation")}>View Invoice Reconciliations</MenuItem>)
        }
        if (menuItems.length > 0) {
            return (
                <React.Fragment>
                    <Button onClick={handleInvoiceReconClick}>Invoice Reconciliation</Button>
                    <Menu open={Boolean(invoiceReconAnchorEl)} onClose={handleInvoiceReconClose} anchorEl={invoiceReconAnchorEl} getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        {menuItems}
                    </Menu>
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_monthly_billing_statement') && <Button onClick={() => history.push("/billing/account/" + chosenAccount.id + "/pjm")}>PJM Billing</Button>}
            {buildInvoiceReconMenu()}
        </React.Fragment>
    )

}

export default PjmBillingAppBar;