import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles, withTheme } from "@material-ui/core/styles";
import React, { Component } from 'react';
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import DeliveryYearAuctionComponent from "./DeliveryYearAuctionComponent";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class CreateDeliveryYearAuction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deliveryYearAuction: {
                deliveryYear: '',
                deliveryYearAuctions: []
            }
        }
    }

    onSave = async () => {
        await trackPromise(axios.post("/capacity/deliveryYearAuction/deliveryYear/" + this.state.deliveryYearAuction.deliveryYear.replace("/", "-"),
            JSON.stringify(this.state.deliveryYearAuction.deliveryYearAuctions)).then(response => {
                this.props.onSuccess("Successfully saved auctions for delivery year " + this.state.deliveryYearAuction.deliveryYear);
            }).catch(error => {
                this.props.onError(error);
            }));
        this.props.history.push("/capacity/deliveryYearAuctions/view")
    }

    onChangeHandler = (deliveryYearAuction) => {
        this.setState({ deliveryYearAuction: deliveryYearAuction });
    }


    render() {
        const { classes } = this.props;
        return (
            <main className={classes.layout}>
                <DeliveryYearAuctionComponent onChangeHandler={this.onChangeHandler} isEdit={hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_create_delivery_year_auction')} />
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.onSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onError: (error) => dispatch(onError(error)),
        onSuccess: (message) => dispatch(onSuccess(message))
    };
}

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateDeliveryYearAuction)));