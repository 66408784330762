import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";
import { retrieveDeliveryYearAuctions } from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import { hasPermissionForAccount } from "../../../Utility/PermissionsUtil";
import { cleanStringInput } from "../../../Utility/StringUtil";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const EditZonalResult = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [zonalResult, setZonalResult] = useState();
    const [hasEdit, setHasEdit] = useState(false);
    const [hasDelete, setHasDelete] = useState(false);

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        retrieveZonalResult();
        setHasEdit(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_zonal_auction_result'));
        setHasDelete(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_zonal_auction_result'));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveZonalResult = async () => {
        await (trackPromise(axios.get("/capacity/zonalResult/" + props.match.params.zonalResultId).then(response => {
            setZonalResult(response.data);
        }).catch(error => {
            dispatch(onError(error));
        })))
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={zonalResult.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                    getOptionLabel={(option) => option} renderInput={(params) => <TextField {...params} label="Delivery Year" />}
                    onChange={(event, value) => onDeliveryYearSelectChange(event, value)} disabled={!hasEdit} />
            );
        }
    }

    const renderAuctionSelectBox = () => {
        if (zonalResult.deliveryYear) {
            return (
                <Autocomplete value={zonalResult.auction}
                    options={['', ...deliveryYearAuctions[zonalResult.deliveryYear].map(r => r.auction)]}
                    getOptionLabel={(option) => option} renderInput={(params) => <TextField {...params} label="Auction" />}
                    onChange={(event, value) => onAuctionSelectChange(event, value)} disabled={!hasEdit} />
            );
        }
        return (
            <Autocomplete value={zonalResult.auction} options={['']} getOptionLabel={() => ''}
                renderInput={(params) => <TextField {...params} label="Auction" />} disabled={!hasEdit} />
        );
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            zonalResult.deliveryYear = value;
        } else {
            zonalResult.deliveryYear = '';
            zonalResult.auction = '';
        }
        setZonalResult({ ...zonalResult });
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            zonalResult.auction = value;
        } else {
            zonalResult.auction = '';
        }
        setZonalResult({ ...zonalResult });
    }

    const onSave = async () => {
        await trackPromise(axios.put("/capacity/zonalResult/" + props.match.params.zonalResultId, JSON.stringify(zonalResult)).then(() => {
            dispatch(onSuccess("Updated Zonal Result for delivery year " + zonalResult.deliveryYear + ", auction " + zonalResult.auction + " and zone " + zonalResult.zone));
            history.push("/capacity/zonalResult/view");
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const onDelete = async () => {
        await trackPromise(axios.delete("/capacity/zonalResult/" + props.match.params.zonalResultId).then(() => {
            dispatch(onSuccess("Deleted Zonal Result"));
            history.push("/capacity/zonalResult/view");
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const onInputChange = (e) => {
        if (e.target.value) {
            if (e.target.type === "number") {
                zonalResult[e.target.name] = Number.parseFloat(e.target.value);
            } else {
                zonalResult[e.target.name] = e.target.value;
            }
        } else {
            zonalResult[e.target.name] = '';
        }
        setZonalResult({ ...zonalResult });
    }

    const renderButtons = () => {
        if (hasEdit && hasDelete) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            )
        }
        if (!hasEdit && hasDelete) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            )
        }
        if (hasEdit && !hasDelete) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onSave} fullWidth>Save</Button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    return (
        <main className={classes.layout}>
            {zonalResult &&
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            {renderDeliveryYearSelectBox()}
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            {renderAuctionSelectBox()}
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="zone" label="Zone Name" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.zone)} fullWidth disabled={!hasEdit} />
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="coincidentPeakYear" label="Coincident Peak Year" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.coincidentPeakYear)} fullWidth disabled={!hasEdit} InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    inputProps: { fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: hasEdit, thousandSeparator: false }
                                }} InputLabelProps={{ shrink: !!zonalResult.coincidentPeakYear }} />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="coincidentPeakLoad" label="Coincident Peak Load" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.coincidentPeakLoad)}
                                type="number" fullWidth InputProps={{
                                    endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                                    inputProps: { fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: hasEdit }
                                }} disabled={!hasEdit} InputLabelProps={{ shrink: !!zonalResult.coincidentPeakLoad }} />
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="peakLoadScalingFactor" label="Peak Load Scaling Factor" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.peakLoadScalingFactor)}
                                type="number" fullWidth disabled={!hasEdit} InputProps={{
                                    inputComponent: GenericNumberFormat,
                                    inputProps: { fixedDecimalScale: true, decimalScale: 10, allowNegative: false, isEdit: hasEdit }
                                }} InputLabelProps={{ shrink: !!zonalResult.peakLoadScalingFactor }} />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="zonalPeakLoadForecast" label="Zonal Peak Load Forecast" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.zonalPeakLoadForecast)} type="number" fullWidth InputProps={{
                                    endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                                    inputComponent: GenericNumberFormat,
                                    inputProps: { fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: hasEdit }
                                }} disabled={!hasEdit} InputLabelProps={{ shrink: !!zonalResult.zonalPeakLoadForecast }} />
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="ucapObligation" label="UCAP Obligation" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.ucapObligation)} type="number" fullWidth InputProps={{
                                    endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                                    inputComponent: GenericNumberFormat,
                                    inputProps: { fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: hasEdit }
                                }} disabled={!hasEdit} InputLabelProps={{ shrink: !!zonalResult.ucapObligation }} />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="zonalCapacityPrice" label="Zonal Capacity Price" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.zonalCapacityPrice)} type="number" fullWidth InputProps={{
                                    endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                    inputComponent: TwoDigitCurrency,
                                    inputProps: { isEdit: hasEdit }
                                }} disabled={!hasEdit} InputLabelProps={{ shrink: !!zonalResult.zonalCapacityPrice }} />
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="netLoadPrice" label="Net Load Price" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.netLoadPrice)} type="number" fullWidth InputProps={{
                                    endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                    inputComponent: TwoDigitCurrency,
                                    inputProps: { isEdit: hasEdit }
                                }} disabled={!hasEdit} InputLabelProps={{ shrink: !!zonalResult.netLoadPrice }} />
                        </Grid>
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={1} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="ctrCreditRate" label="CTR Credit Rate" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.ctrCreditRate)} type="number" fullWidth InputProps={{
                                    endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                    inputComponent: TwoDigitCurrency,
                                    inputProps: { isEdit: hasEdit, allowNegative: true }
                                }} disabled={!hasEdit} InputLabelProps={{ shrink: !!zonalResult.ctrCreditRate }} />
                        </Grid>
                        <Grid item xs={6} sm={2} />
                        <Grid item xs={6} sm={4}>
                            <TextField name="ctrSettlementRate" label="CTR Settlement Rate" onChange={(e) => onInputChange(e)}
                                value={cleanStringInput(zonalResult.ctrSettlementRate)} type="number" fullWidth InputProps={{
                                    endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                    inputComponent: TwoDigitCurrency,
                                    inputProps: { isEdit: hasEdit, allowNegative: true }
                                }} disabled={!hasEdit} InputLabelProps={{ shrink: true }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {renderButtons()}
                    </Grid>
                </React.Fragment>
            }
        </main>
    )
}

export default EditZonalResult;