import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';

const SolarForecastAppBar = (props) => {

    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const history = useHistory();

    const buildLocationDetailsMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_get_location_detail')) {
            return (<Button onClick={() => history.push("/solar-forecast/account/" + chosenAccount.id + "/location")}>Location Details</Button>);
        }
    }

    const buildSolarForecastMenu = () => {
        if (hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_generate_solar_forecast')) {
            return (<Button onClick={() => history.push("/solar-forecast/account/" + chosenAccount.id + "/forecast")}>Solar Forecast</Button>)
        }
    }


    return (
        <React.Fragment>
            {buildLocationDetailsMenu()}
            {buildSolarForecastMenu()}
        </React.Fragment>
    );

}

export default SolarForecastAppBar;