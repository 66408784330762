import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import useStyles from '../../../Styles';
import PaginatedTableActions from '../../Table/PaginatedTableActions';

const SolarForecastResults = (props) => {

    const [solarForecasts, setSolarForecasts] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(24);

    useEffect(() => {
        setSolarForecasts(props.solarForecasts);
        setPageNumber(0);
    }, [props]);

    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    }

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPageNumber(0);
    }

    return (
        <React.Fragment>
            <Table size="small" style={{ marginTop: 20 }} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Hour Beginning Date</TableCell>
                        <TableCell>Hour Beginning</TableCell>
                        <TableCell>Hour Ending Date</TableCell>
                        <TableCell>Hour Ending</TableCell>
                        <TableCell>Total Location Output (MWs)</TableCell>
                        <TableCell>Temperature {'\u00b0'}F</TableCell>
                        <TableCell>Cloud Cover (%)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        solarForecasts && solarForecasts.length > 0 && solarForecasts.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage).map(r => {
                            return (
                                <SolarForecastRow key={r.hourBeginningDateTime.hourBeginningDate + r.hourBeginningDateTime.hourBeginning} solarForecast={r} />
                            )
                        })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination count={solarForecasts ? solarForecasts.length : 0} onPageChange={handlePageChange}
                            page={pageNumber} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={[24, 48, 72]} colSpan={8} ActionsComponent={PaginatedTableActions} />
                    </TableRow>
                </TableFooter>
            </Table>
        </React.Fragment>
    )

}

const SolarForecastRow = (props) => {

    const classes = useStyles(props);
    const solarForecast = props.solarForecast;
    const [open, setOpen] = useState(false);

    const renderHourlyDetail = (solarModuleForecasts) => {
        return (
            <React.Fragment>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Module Name</TableCell>
                            <TableCell>Number of Modules</TableCell>
                            <TableCell>Per Module Output (MW)</TableCell>
                            <TableCell>Total Modules Output (MW)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            solarModuleForecasts.sort((r1, r2) => { return r1.panelModule.moduleName.localeCompare(r2.panelModule.moduleName) }).map(t => {
                                return (
                                    <TableRow key={t.panelModule.moduleName}>
                                        <TableCell>{t.panelModule.moduleName}</TableCell>
                                        <TableCell><NumberFormat value={t.panelModule.numberOfModules} displayType='text' fixedDecimalScale={true} decimalScale={0} thousandSeparator /></TableCell>
                                        <TableCell><NumberFormat value={t.perModuleOutput} displayType='text' fixedDecimalScale={true} decimalScale={6} thousandSeparator /></TableCell>
                                        <TableCell><NumberFormat value={t.totalModulesOutput} displayType='text' fixedDecimalScale={true} decimalScale={3} thousandSeparator /></TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <TableRow key={solarForecast.hourBeginningDateTime.hourBeginningDate + solarForecast.hourBeginningDateTime.hourBeginning} className={classes.table}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{solarForecast.hourBeginningDateTime.hourBeginningDate}</TableCell>
                <TableCell>{solarForecast.hourBeginningDateTime.hourBeginning}</TableCell>
                <TableCell>{solarForecast.hourEndingDateTime.hourEndingDate}</TableCell>
                <TableCell>{solarForecast.hourEndingDateTime.hourEnding}</TableCell>
                <TableCell>
                    <NumberFormat value={solarForecast.totalLocationOutput} displayType='text' fixedDecimalScale={true} decimalScale={3} thousandSeparator />
                </TableCell>
                <TableCell>
                    <NumberFormat value={solarForecast.temperature} displayType='text' fixedDecimalScale={true} decimalScale={0} />
                </TableCell>
                <TableCell>
                    <NumberFormat value={solarForecast.cloudCover} displayType='text' fixedDecimalScale={true} decimalScale={0} />
                </TableCell>
            </TableRow>
            {open && <TableRow>
                <TableCell />
                <TableCell colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant='h6'>Hourly Details</Typography>
                            {renderHourlyDetail(solarForecast.solarModuleForecasts)}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            }
        </React.Fragment>
    )
}

export default SolarForecastResults;