import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router';
import useStyles from '../../../Styles';
import { saveUser, saveUserAdmin } from '../../../store/user-management/actions/userActions';
import AccountRoleComponent from '../AccountRoleComponent';
import UserComponent from "../User/UserComponent";

const CreateUser = (props) => {

    const [user, setUser] = useState({
        username: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        password: '',
        phoneNumber: '',
        accountIds: [],
        userApplicationRoles: []
    });
    const dispatch = useDispatch();
    const superUser = useSelector(state => state.auth.superUser);
    const params = useParams();
    const history = useHistory();
    const classes = useStyles();

    const inputChangeHandler = (inputUser) => {
        inputUser.userApplicationRoles = user.userApplicationRoles;
        inputUser.accountIds = user.accountIds;
        setUser({ ...inputUser });
    };

    const accountIdChangeHandler = (accountIds) => {
        user.accountIds = [...accountIds];
        setUser({ ...user });
    }

    const applicationRoleChangeHandler = (accountRoles) => {
        user.userApplicationRoles = [...accountRoles];
        setUser({ ...user });
    }

    const createUser = () => {
        if (superUser) {
            dispatch(saveUserAdmin(user));
        } else {
            user.accountIds = [params.accountId];
            dispatch(saveUser(params.accountId, user));
        }
        history.goBack();
    };

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">Create New User</Typography>
            <UserComponent inputChangeHandler={inputChangeHandler} canEdit={true} displayUsername={true} />
            <AccountRoleComponent accountIdChangeHandler={accountIdChangeHandler} applicationRoleChangeHandler={applicationRoleChangeHandler} canEdit={true} />
            <Grid container spacing={6}>
                <Grid item xs={4} align="center">
                    <Button onClick={createUser}>Save</Button>
                </Grid>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                    <Button onClick={() => {
                        history.goBack()
                    }}>Back</Button>
                </Grid>
            </Grid>
        </main>
    );
}

export default CreateUser;