import React, { useEffect, useState } from 'react';
import Plot from "react-plotly.js";

const WeatherForecastGraph = (props) => {

    const { weatherData } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        generateWeatherData();
        // eslint-disable-next-line
    }, [props]);

    const generateWeatherData = () => {
        let localData = []
        if (weatherData) {
            let keys = Object.keys(weatherData);
            keys.forEach(key => localData.push({
                x: weatherData[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                y: weatherData[key].map(r => r.temperature),
                type: 'line',
                connectgaps: true,
                name: key,
                yaxis: 'y'
            }));
        }
        setData(localData);
    }

    return (
        <React.Fragment>
            {data && data.length > 0 &&
                <React.Fragment>
                    <Plot data={data}
                        layout={{
                            width: 1200,
                            height: 500,
                            title: 'Weather Forecast',
                            showlegend: true,
                            xaxis: { title: { text: 'Date  (Hour Ending)' } },
                            yaxis: { title: { text: 'Degrees (F)' } },
                            legend: { orientation: "h" }
                        }} config={{ displaylogo: false }} />
                </React.Fragment>
            }
        </React.Fragment>
    );

}

export default WeatherForecastGraph;