import { ACCOUNT_CHANGED } from "../actionTypes";
import { RETRIEVE_SUPPLIER_CUSTOMERS, SUPPLIER_ACCOUNT_CHOSEN, SUPPLIER_CUSTOMER_DIRECTION, SUPPLIER_CUSTOMER_ORDER_BY, SUPPLIER_CUSTOMER_PAGE_NUMBER, SUPPLIER_CUSTOMER_PAGE_SIZE } from "./GasSupplyActionTypes";

const initialState = {
    supplierCustomers: {},
    pageSize: 10,
    pageNumber: 0,
    direction: 'asc',
    orderBy: 'accountName',
    chosenSupplierAccount: {}
}

const SupplierCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_SUPPLIER_CUSTOMERS:
            return { ...state, supplierCustomers: action.supplierCustomers };
        case SUPPLIER_CUSTOMER_PAGE_SIZE:
            return { ...state, pageSize: action.pageSize };
        case SUPPLIER_CUSTOMER_PAGE_NUMBER:
            return { ...state, pageNumber: action.pageNumber };
        case SUPPLIER_CUSTOMER_ORDER_BY:
            return { ...state, orderBy: action.orderBy };
        case SUPPLIER_CUSTOMER_DIRECTION:
            return { ...state, direction: action.direction };
        case SUPPLIER_ACCOUNT_CHOSEN:
            return { ...state, chosenSupplierAccount: action.chosenSupplierAccount };
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

export default SupplierCustomerReducer;