import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect } from 'react';
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import useStyles from "../../../Styles";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import { AVAILABLE_GENERATORS_RETREIVED, UNIT_DETAILS_RESULTS_DIRECTION, UNIT_DETAILS_RESULTS_ORDER_BY, UNIT_DETAILS_RESULTS_PAGE_SIZE, UNIT_DETAILS_RESULTS_RETRIEVED, UNIT_DETAILS_SEARCH_END_DATE, UNIT_DETAILS_SEARCH_START_DATE, UNIT_DETAILS_SEARCH_UNIT } from "../../../store/energy-markets/energyMarketActionTypes";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const UnitDetails = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const results = useSelector(state => state.energyMarket.unitDetailsResults);
    const startDate = useSelector(state => state.energyMarket.unitDetailsSearchStart);
    const endDate = useSelector(state => state.energyMarket.unitDetailsSearchEnd);
    const searchUnit = useSelector(state => state.energyMarket.unitDetailsSearchUnit);
    const pageSize = useSelector(state => state.energyMarket.unitDetailsResultsPageSize);
    const direction = useSelector(state => state.energyMarket.unitDetailsResultsDirection);
    const orderBy = useSelector(state => state.energyMarket.unitDetailsResultsOrderBy);
    const availableGenerators = useSelector(state => state.energyMarket.availableGenerators);

    useEffect(() => {
        retrieveAvailableGenerators();
        let date = new Date();
        date.setDate(date.getDate() + 1);
        dispatch({ type: UNIT_DETAILS_SEARCH_START_DATE, unitDetailsSearchStartDate: new Date() });
        dispatch({ type: UNIT_DETAILS_SEARCH_END_DATE, unitDetailsSearchEndDate: date });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const retrieveAvailableGenerators = async () => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + params.accountId + "/generation/unit?pageNumber=0&pageSize=100").then(response => {
            dispatch({ type: AVAILABLE_GENERATORS_RETREIVED, availableGenerators: response.data.results });
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const startDateChangeHandler = (date) => {
        dispatch({ type: UNIT_DETAILS_SEARCH_START_DATE, unitDetailsSearchStartDate: date });
    }

    const endDateChangeHandler = (date) => {
        dispatch({ type: UNIT_DETAILS_SEARCH_END_DATE, unitDetailsSearchEndDate: date });
    }

    const doSearch = async (pageNumber, pageSize, orderBy, direction) => {
        let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction };
        urlParams['startDate'] = moment(startDate).format("YYYY-MM-DD");
        urlParams['endDate'] = moment(endDate).format("YYYY-MM-DD");
        if (searchUnit) {
            urlParams['location'] = searchUnit.location;
        }
        await trackPromise(axios.get("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/details", { params: { ...urlParams } }).then(response => {
            dispatch({ type: UNIT_DETAILS_RESULTS_RETRIEVED, unitDetailsResults: response.data });
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        dispatch({ type: UNIT_DETAILS_RESULTS_DIRECTION, unitDetailsResultsDirection: sortDirection });
        dispatch({ type: UNIT_DETAILS_RESULTS_ORDER_BY, unitDetailsResultsOrderBy: column });
        doSearch(0, pageSize, column, sortDirection);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        doSearch(pageNumber, pageSize, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        dispatch({ type: UNIT_DETAILS_RESULTS_PAGE_SIZE, unitDetailsResultsPageSize: e.target.value });
        doSearch(0, e.target.value, orderBy, direction);
    }

    return (
        <main className={classes.layout}>
            <form onSubmit={(e) => {
                e.preventDefault();
                doSearch(0, pageSize);
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                onChange={(date) => startDateChangeHandler(date)} value={startDate} fullWidth
                                autoOk={true} maxDate={endDate ? endDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                onChange={(date) => endDateChangeHandler(date)} value={endDate} fullWidth
                                autoOk={true} minDate={startDate ? startDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <Autocomplete value={searchUnit} options={availableGenerators} getOptionLabel={(option) => option ? option.unitName : ''}
                            renderInput={(params) => <TextField {...params} label="Unit Name" />} onChange={(event, value) => dispatch({ type: UNIT_DETAILS_SEARCH_UNIT, unitDetailsSearchUnit: value })} />
                    </Grid>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} align="center">
                        <Button type="submit" style={{ minHeight: '3em' }} variant="contained">Search</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </form>
            <Table>
                <TableHead sx={{ width: "max-content" }}>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel active={orderBy === "date"} direction={orderBy === "date" ? direction : 'asc'} onClick={() => handleSort("date")}>
                                Market Date
                                {orderBy === "date" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "location"} direction={orderBy === "location" ? direction : 'asc'} onClick={() => handleSort("location")}>
                                Resource Name
                                {orderBy === "location" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "emergencyMinimum"} direction={orderBy === "emergencyMinimum" ? direction : 'asc'} onClick={() => handleSort("emergencyMinimum")}>
                                Emergency Min.
                                {orderBy === "emergencyMinimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "economicMinimum"} direction={orderBy === "economicMinimum" ? direction : 'asc'} onClick={() => handleSort("economicMinimum")}>
                                Eco. Min.
                                {orderBy === "economicMinimum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "economicMaximum"} direction={orderBy === "economicMaximum" ? direction : 'asc'} onClick={() => handleSort("economicMaximum")}>
                                Eco. Max.
                                {orderBy === "economicMaximum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "emergencyMaximum"} direction={orderBy === "emergencyMaximum" ? direction : 'asc'} onClick={() => handleSort("emergencyMaximum")}>
                                Emergency Max.
                                {orderBy === "emergencyMaximum" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results && results.results && results.results.length > 0 && results.results.map(r => {
                        return (
                            <TableRow key={r.id} onClick={() => history.push("/markets/account/" + r.accountId + "/energy/generator/unit/" + r.location + "/" + r.id)} className={classes.tableRow}>
                                <TableCell>{r.date}</TableCell>
                                <TableCell>{r.resourceName ? r.resourceName : r.location}</TableCell>
                                <TableCell><NumberFormat value={r.emergencyMinimum} displayType='text' fixedDecimalScale={true} decimalScale={2} thousandSeparator /></TableCell>
                                <TableCell><NumberFormat value={r.economicMinimum} displayType='text' fixedDecimalScale={true} decimalScale={2} thousandSeparator /></TableCell>
                                <TableCell><NumberFormat value={r.economicMaximum} displayType='text' fixedDecimalScale={true} decimalScale={2} thousandSeparator /></TableCell>
                                <TableCell><NumberFormat value={r.emergencyMaximum} displayType='text' fixedDecimalScale={true} decimalScale={2} thousandSeparator /></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination count={results.totalCount ? results.totalCount : 0} onPageChange={handlePageChange}
                            page={results.pageNumber ? results.pageNumber : 0}
                            rowsPerPage={calculateRowsPerPage(results.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={[10, 25, 50]} colSpan={11} ActionsComponent={PaginatedTableActions} />
                    </TableRow>
                </TableFooter>
            </Table>
        </main>
    )

}

export default UnitDetails;