import { Checkbox, Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";
import PaginatedTableActions from "../Table/PaginatedTableActions";

const AccountRoleComponent = (props) => {

    const dispatch = useDispatch();
    const superUser = useSelector(state => state.auth.superUser);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [accounts, setAccounts] = useState({});
    const [checkedAccounts, setCheckedAccounts] = useState([]);
    const [roles, setRoles] = useState([]);
    const [expandedAccounts, setExpandedAccounts] = useState([]);
    const [accountRoles, setAccountRoles] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const [firstCall, setFirstCall] = useState(true);

    useEffect(() => {
        if (firstCall) {
            retrieveAccounts(0, 10);
            retrieveRoles();
            setFirstCall(false);
        }
        if (props.accounts && props.accounts instanceof Array) {
            setCheckedAccounts([...props.accounts]);
        } else if (props.accounts) {
            setCheckedAccounts([props.accounts]);
        }
        if (props.userApplicationRoles && props.userApplicationRoles instanceof Array) {
            setAccountRoles([...props.userApplicationRoles]);
        } else if (props.userApplicationRoles) {
            setAccountRoles([props.userApplicationRoles]);
        }
        setCanEdit(props.canEdit);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.accounts, props.userApplicationRoles, props.canEdit])

    const retrieveAccounts = async (pageNumber, pageSize) => {
        if (superUser) {
            await trackPromise(axios.get("/account-service/v4/account?pageNumber=" + pageNumber + "&pageSize=" + pageSize).then(response => {
                setAccounts(response.data);
            }).catch(error => {
                dispatch(onError(error.response));
            }))
        } else {
            await trackPromise(axios.get("/account-service/v4/account/" + chosenAccount.id).then(response => {
                setAccounts({ pageNumber: 0, pageSize: 10, totalCount: 1, results: [response.data] });
            }).catch(error => {
                dispatch(onError(error.response));
            }))
        }
    }

    const retrieveRoles = async () => {
        await trackPromise(axios.get("/auth/application/roles").then(response => {
            if (response.data) {
                response.data.sort((r1, r2) => (r1.roleName.localeCompare(r2.roleName)));
                setRoles(response.data);
            }
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const handlePageChange = (pageNumber, pageSize) => {
        retrieveAccounts(pageNumber, pageSize);
    }

    const handleRowsPerPageChange = (e) => {
        retrieveAccounts(0, e.target.value);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const updateCheckedAccounts = (e, accountId) => {
        if (checkedAccounts && checkedAccounts.length > 0) {
            let index = -1;
            for (let i = 0; i < checkedAccounts.length; i++) {
                if (checkedAccounts[i] === accountId) {
                    index = i;
                }
            }
            if (index === -1) {
                checkedAccounts.push(accountId);
            } else {
                checkedAccounts.splice(index, 1);
                removeRolesForAccount(accountId);
            }
        } else {
            checkedAccounts.push(accountId);
        }
        setCheckedAccounts([...checkedAccounts]);
        if (props.accountIdChangeHandler) {
            props.accountIdChangeHandler(checkedAccounts);
        }
    }

    const addAccountAccessForRole = (accountId) => {
        if (checkedAccounts && checkedAccounts.length > 0) {
            let index = -1;
            for (let i = 0; i < checkedAccounts.length; i++) {
                if (checkedAccounts[i] === accountId) {
                    index = i;
                }
            }
            if (index === -1) {
                checkedAccounts.push(accountId);
            }
        } else {
            checkedAccounts.push(accountId);
        }
        setCheckedAccounts([...checkedAccounts]);
        if (props.accountIdChangeHandler) {
            props.accountIdChangeHandler(checkedAccounts);
        }
    }

    const isAccountChecked = (accountId) => {
        if (checkedAccounts && checkedAccounts.length > 0) {
            for (let checkedAccount of checkedAccounts) {
                if (checkedAccount === accountId) {
                    return true;
                }
            }
        }
        return false;
    }

    const expandOrCollapseAccount = (e, accountId) => {
        if (expandedAccounts && expandedAccounts.length > 0) {
            let index = -1;
            for (let i = 0; i < expandedAccounts.length; i++) {
                if (expandedAccounts[i] === accountId) {
                    index = i;
                }
            }
            if (index === -1) {
                expandedAccounts.push(accountId);
            } else {
                expandedAccounts.splice(index, 1);
            }
        } else {
            expandedAccounts.push(accountId);
        }
        setExpandedAccounts([...expandedAccounts]);
    }

    const isAccountExpanded = (accountId) => {
        if (expandedAccounts && expandedAccounts.length > 0) {
            for (let expandedAccount of expandedAccounts) {
                if (expandedAccount === accountId) {
                    return true;
                }
            }
        }
        return false;
    }

    const updateAccountRole = (e, accountId, roleId) => {
        if (accountRoles && accountRoles.length > 0) {
            let index = -1;
            for (let i = 0; i < accountRoles.length; i++) {
                if (accountRoles[i].accountId === accountId && accountRoles[i].roleId === roleId) {
                    index = i;
                }
            }
            if (index === -1) {
                accountRoles.push({ accountId: accountId, roleId: roleId })
            } else {
                accountRoles.splice(index, 1);
            }
        } else {
            accountRoles.push({ accountId: accountId, roleId: roleId });
        }
        addAccountAccessForRole(accountId);
        setAccountRoles([...accountRoles]);
        if (props.applicationRoleChangeHandler) {
            props.applicationRoleChangeHandler(accountRoles);
        }
    }

    const isAccountRoleChecked = (accountId, roleId) => {
        if (accountRoles && accountRoles.length > 0) {
            for (let accountRole of accountRoles) {
                if (accountRole.accountId === accountId && accountRole.roleId === roleId) {
                    return true;
                }
            }
        }
        return false;
    }

    const removeRolesForAccount = (accountId) => {
        let tempAccountRoles = accountRoles.filter(r => r.accountId !== accountId);
        setAccountRoles([...tempAccountRoles]);
        if (props.applicationRoleChangeHandler) {
            props.applicationRoleChangeHandler(accountRoles);
        }
    }

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Account Name</TableCell>
                        <TableCell>Has Access</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts && accounts.results && accounts.results.length > 0 && accounts.results.map(r => {
                        return (
                            <React.Fragment key={r.id}>
                                <TableRow key={r.id}>
                                    <TableCell>
                                        <IconButton onClick={(e) => expandOrCollapseAccount(e, r.id)}>
                                            {isAccountExpanded(r.id) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{r.customerAccount}</TableCell>
                                    <TableCell><Checkbox checked={isAccountChecked(r.id)} onClick={(e) => updateCheckedAccounts(e, r.id)}
                                        disabled={!canEdit} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <Collapse in={isAccountExpanded(r.id)} timeout="auto" unmountOnExit>
                                            <List dense={true}>
                                                {roles.map(s => {
                                                    return (
                                                        <ListItem key={s.id}>
                                                            <ListItemText>{s.roleName}</ListItemText>
                                                            <ListItemSecondaryAction>
                                                                <Checkbox edge="end" value={s.id} onChange={(e) => updateAccountRole(e, r.id, s.id)}
                                                                    checked={isAccountRoleChecked(r.id, s.id)} disabled={!canEdit} />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        </Collapse>
                                    </TableCell>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
                                </TableRow>
                            </React.Fragment>
                        )
                    })
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={3} count={accounts.totalCount ? accounts.totalCount : 0}
                            rowsPerPage={calculateRowsPerPage(accounts.pageSize)}
                            page={accounts.pageNumber ? accounts.pageNumber : 0} onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            ActionsComponent={PaginatedTableActions} />
                    </TableRow>
                </TableFooter>
            </Table>
        </React.Fragment>
    )
}

export default AccountRoleComponent;