import DateFnsUtils from "@date-io/date-fns";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import { SET_CAPACICITY_TRANSACTIONS_TRANSACTION_ID, SET_CAPACICITY_TRANSACTIONS_TRANSACTION_TYPE, SET_CAPACITY_TRANSACTIONS_DIRECTION, SET_CAPACITY_TRANSACTIONS_END_DATE, SET_CAPACITY_TRANSACTIONS_ORDER_BY, SET_CAPACITY_TRANSACTIONS_PAGE_SIZE, SET_CAPACITY_TRANSACTIONS_RESULTS, SET_CAPACITY_TRANSACTIONS_SEARCH_BY, SET_CAPACITY_TRANSACTIONS_START_DATE } from "../../../store/capacity-exchange/capacityExchangeActionTypes";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const CapacityTransactions = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const startDate = useSelector(state => state.capacity.capacityTransactionsSearchStart);
    const endDate = useSelector(state => state.capacity.capacityTransactionsSearchEnd);
    const searchBy = useSelector(state => state.capacity.capacityTransactionsSearchBy);
    const transactionType = useSelector(state => state.capacity.capacityTransactionsTransactionType);
    const transactionId = useSelector(state => state.capacity.capacityTransactionsTransactionId);
    const capacityTransactions = useSelector(state => state.capacity.capacityTransactionsResults);
    const pageSize = useSelector(state => state.capacity.capacityTransactionsPageSize);
    const direction = useSelector(state => state.capacity.capacityTransactionsDirection);
    const orderBy = useSelector(state => state.capacity.capacityTransactionsOrderBy);


    const startDateChangeHandler = (date) => {
        dispatch({ type: SET_CAPACITY_TRANSACTIONS_START_DATE, capacityTransactionsSearchStart: date });
    }

    const endDateChangeHandler = (date) => {
        dispatch({ type: SET_CAPACITY_TRANSACTIONS_END_DATE, capacityTransactionsSearchEnd: date });
    }

    const doSearch = async (pageNumber, pageSize, orderBy, direction) => {
        if (!transactionId) {
            let urlParams = { pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, direction: direction };
            if (startDate) {
                urlParams['startDate'] = moment(startDate).format("YYYY-MM-DD");
            }
            if (endDate) {
                urlParams['endDate'] = moment(endDate).format("YYYY-MM-DD");
            }
            if (searchBy) {
                urlParams['searchBy'] = searchBy;
            }
            if (transactionType && transactionType.name !== '') {
                urlParams['transactionType'] = transactionType.name;
            }
            await trackPromise(axios.get("/capacity/account/" + params.accountId + "/transactions", { params: { ...urlParams } }).then(response => {
                dispatch({ type: SET_CAPACITY_TRANSACTIONS_RESULTS, capacityTransactionsResults: response.data });
            }).catch(error => {
                dispatch(onError(error))
            }))
        } else {
            await trackPromise(axios.get("/capacity/account/" + params.accountId + "/transactions/" + transactionId).then(response => {
                dispatch({ type: SET_CAPACITY_TRANSACTIONS_RESULTS, capacityTransactionsResults: response.data });
            }).catch(error => {
                dispatch(onError(error))
            }))
        }
    }

    const onSearchByChange = (e) => {
        dispatch({ type: SET_CAPACITY_TRANSACTIONS_SEARCH_BY, capacityTransactionsSearchBy: e.target.value });
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        dispatch({ type: SET_CAPACITY_TRANSACTIONS_ORDER_BY, capacityTransactionsOrderBy: column });
        dispatch({ type: SET_CAPACITY_TRANSACTIONS_DIRECTION, capacityTransactionsDirection: sortDirection });
        doSearch(0, pageSize, column, sortDirection);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        doSearch(pageNumber, pageSize, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleRowsPerPageChange = (e) => {
        dispatch({ type: SET_CAPACITY_TRANSACTIONS_PAGE_SIZE, capacityTransactionsPageSize: pageSize });
        doSearch(0, e.target.value, orderBy, direction);
    }

    return (
        <main className={classes.layout}>
            <form onSubmit={(e) => {
                e.preventDefault();
                doSearch(0, pageSize)
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                onChange={(date) => startDateChangeHandler(date)} value={startDate} fullWidth
                                autoOk={true} maxDate={endDate ? endDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                onChange={(date) => endDateChangeHandler(date)} value={endDate} fullWidth
                                autoOk={true} minDate={startDate ? startDate : null} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel>Search By</FormLabel>
                            <RadioGroup name="searchBy" value={searchBy} onChange={onSearchByChange} row>
                                <FormControlLabel control={<Radio />} label="Start Date" value="startDate" />
                                <FormControlLabel control={<Radio />} label="Stop Date" value="stopDate" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <Autocomplete value={transactionType} options={[{ name: '', display: '' }, { name: 'CAPMOD', display: 'Cap Mod' }, { name: 'REPLACEMENT', display: 'Replacement' }, { name: 'UCAPTRANSFER', display: 'UCAP Transfer' }, { name: 'UNIT', display: 'Unit Specific' }]}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.display} id="transactionType"
                            renderInput={(params) => <TextField {...params} label="Transaction Type" />}
                            onChange={(event, value) => dispatch({ type: SET_CAPACICITY_TRANSACTIONS_TRANSACTION_TYPE, capacityTransactionsTransactionType: value })} />
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        <TextField value={transactionId} label="Transaction Id"
                            onChange={(e) => dispatch({ type: SET_CAPACICITY_TRANSACTIONS_TRANSACTION_ID, capacityTransactionsTransactionId: e.target.value })} fullWidth />
                    </Grid>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button type="submit" fullWidth>Search</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </form>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel active={orderBy === "transactionId"} direction={orderBy === "transactionId" ? direction : 'asc'} onClick={() => handleSort("transactionId")}>
                                Transaction ID
                                {orderBy === "transactionId" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "startDate"} direction={orderBy === "startDate" ? direction : 'asc'} onClick={() => handleSort("startDate")}>
                                Start Date
                                {orderBy === "startDate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "endDate"} direction={orderBy === "endDate" ? direction : 'asc'} onClick={() => handleSort("endDate")}>
                                Stop Date
                                {orderBy === "endDate" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "transactionType"} direction={orderBy === "transactionType" ? direction : 'asc'} onClick={() => handleSort("transactionType")}>
                                Transaction Type
                                {orderBy === "transactionType" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "resourceName"} direction={orderBy === "resourceName" ? direction : 'asc'} onClick={() => handleSort("resourceName")}>
                                Resource Name
                                {orderBy === "resourceName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "megawatts"} direction={orderBy === "megawatts" ? direction : 'asc'} onClick={() => handleSort("megawatts")}>
                                Megawatts
                                {orderBy === "megawatts" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "buyerName"} direction={orderBy === "buyerName" ? direction : 'asc'} onClick={() => handleSort("buyerName")}>
                                Buyer
                                {orderBy === "buyerName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "sellerName"} direction={orderBy === "sellerName" ? direction : 'asc'} onClick={() => handleSort("sellerName")}>
                                Seller
                                {orderBy === "sellerName" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === "status"} direction={orderBy === "status" ? direction : 'asc'} onClick={() => handleSort("status")}>
                                Status
                                {orderBy === "status" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {capacityTransactions && capacityTransactions.results && capacityTransactions.results.length > 0 &&
                    <React.Fragment>
                        <TableBody>
                            {capacityTransactions.results.map(r => {
                                return (
                                    <TableRow key={r.id} className={classes.tableRow} onClick={() => history.push("/capacity/account/" + params.accountId + "/transactions/" + r.transactionType + "/" + r.transactionId)}>
                                        <TableCell>{r.transactionId}</TableCell>
                                        <TableCell>{r.startDate}</TableCell>
                                        <TableCell>{r.endDate}</TableCell>
                                        <TableCell>{r.transactionType}</TableCell>
                                        <TableCell>{r.resourceName}</TableCell>
                                        <TableCell><NumberFormat value={r.megawatts} displayType='text' fixedDecimalScale={true} decimalScale={1} thousandSeparator /></TableCell>
                                        <TableCell>{r.buyerName}</TableCell>
                                        <TableCell>{r.sellerName}</TableCell>
                                        <TableCell>{r.status}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination count={capacityTransactions.totalCount ? capacityTransactions.totalCount : 0} onPageChange={handlePageChange}
                                    page={capacityTransactions.pageNumber ? capacityTransactions.pageNumber : 0}
                                    rowsPerPage={calculateRowsPerPage(capacityTransactions.pageSize)} onRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPageOptions={[10, 25, 50]} colSpan={9} ActionsComponent={PaginatedTableActions} />
                            </TableRow>
                        </TableFooter>
                    </React.Fragment>
                }
            </Table>
        </main >
    )

}

export default CapacityTransactions;