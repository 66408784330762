import React from 'react';
import { trackPromise } from "react-promise-tracker";
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import EditableServicePermissions from "../ServicePermissions/EditableServicePermissions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const ViewRole = (props) => {

    const [isEdit, setIsEdit] = React.useState(false);
    const [availablePermissions, setAvailablePermissions] = React.useState([]);
    const [selectedPermissions, setSelectedPermissions] = React.useState([]);

    const classes = useStyles();
    const history = useHistory();

    const role = props.role;
    const onSaveFunction = props.onSaveFunction;

    const renderButtons = () => {
        if (isEdit) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onSaveClick}>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={() => history.goBack()}>Back</Button>
                    </Grid>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onEditClick}>Edit</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={() => history.goBack()}>Back</Button>
                    </Grid>
                </React.Fragment>
            );
        }
    };

    const renderCurrentRoles = () => {
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Service Name</TableCell>
                        <TableCell>Permission Name</TableCell>
                        <TableCell>Permission Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {role.servicePermissions.sort((r1, r2) => { return r1.serviceName.localeCompare(r2.serviceName) }).map((servicePermission) => (
                        <TableRow key={servicePermission.id}>
                            <TableCell>{servicePermission.prettyServiceName ? servicePermission.prettyServiceName : servicePermission.serviceName}</TableCell>
                            <TableCell>{servicePermission.permissionPrettyName}</TableCell>
                            <TableCell>{servicePermission.permissionDescription}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };

    const renderRolesForEdit = () => {
        let selectedPermissions = [];
        role.servicePermissions.forEach((servicePermission) =>
            selectedPermissions.push(Number.parseInt(servicePermission.id))
        )
        return (
            <EditableServicePermissions servicePermissions={availablePermissions} selectedPermissions={selectedPermissions} updateSelectedPermissions={updateSelectedPermissions} />
        )
    };

    const updateSelectedPermissions = (permissions) => {
        setSelectedPermissions(permissions);
    }

    const onEditClick = () => {
        setIsEdit(true);
        retrieveSelectedPermissions();
    };

    const retrieveSelectedPermissions = async () => {
        await trackPromise(axios.get("/auth/service/permissions").then(response => {
            let servicePermissions = response.data;
            servicePermissions.sort(compareServicePermissions);
            setAvailablePermissions(servicePermissions);
        }).catch((dispatch, error) => {
            dispatch(onError(error.response));
        }));
    };

    const onSaveClick = () => {
        let updatedRole = role;
        updatedRole.servicePermissions = [];
        selectedPermissions.forEach(id => {
            updatedRole.servicePermissions.push({ id: id })
        });
        if (onSaveFunction) {
            onSaveFunction(updatedRole);
        }
    }

    const compareServicePermissions = (sp1, sp2) => {
        let result = 0;
        if (sp1.serviceName.toUpperCase() > sp2.serviceName.toUpperCase()) {
            result = 1;
        } else if (sp1.serviceName.toUpperCase() < sp2.serviceName.toUpperCase()) {
            result = -1;
        }
        return result;
    };

    return (
        <React.Fragment>
            <Typography variant="h4" align="center">{role.roleName}</Typography>
            <Paper className={classes.paper}>
                {isEdit ? renderRolesForEdit() : renderCurrentRoles()}
            </Paper>
            <Grid container spacing={2}>
                {renderButtons()}
            </Grid>
        </React.Fragment>
    );

}

export default ViewRole;