import { TableSortLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qs from "qs";
import React, { Component } from 'react';
import NumberFormat from "react-number-format";
import { trackPromise } from 'react-promise-tracker';
import { connect } from "react-redux";
import axios from "../../../axios/AxiosInterceptors";
import { onError } from "../../../store/actions/popupActions";
import { retrieveDeliveryYearAuctions, retrieveLdas } from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import { calculateRowsPerPage } from "../../Table/PaginatedTableUtils";


const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
});

class ViewCapacityTransferRights extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: {
                deliveryYear: '',
                auction: '',
                lda: ''
            },
            capacityTransferRights: undefined,
            orderBy: undefined,
            direction: undefined,
            pageSize: 10
        }
    }

    componentDidMount() {
        this.props.retrieveLdas();
        this.props.retrieveDeliveryYearAuction();
        if (this.props.location.search) {
            let queryParams = qs.parse(this.props.location.search.substring(1));
            let search = { ...this.state.search };
            search.deliveryYear = queryParams.deliveryYear;
            search.auction = queryParams.auction;
            search.lda = queryParams.lda;
            this.setState({ search: search })
            this.retrieveCapacityTransferRights(search, 0, this.state.pageSize, this.state.direction, this.state.column)
        }
    }


    renderLdaSelectBox = () => {
        if (this.props.ldas && this.props.ldas.length > 0) {
            return (
                <Autocomplete value={this.state.search.lda} options={['', ...this.props.ldas.map(r => r.ldaName)]} getOptionLabel={(option) => option} id="lda"
                    renderInput={(params) => <TextField {...params} label="LDA" />}
                    onChange={(event, value) => this.onLdaSelectChange(event, value)} />
            );
        }
    }

    renderDeliveryYearSelectBox = () => {
        if (this.props.deliveryYearAuctions) {
            return (
                <Autocomplete value={this.state.search.deliveryYear} options={['', ...Object.keys(this.props.deliveryYearAuctions).sort((r1, r2) => r2.localeCompare(r1))]}
                    getOptionLabel={(option) => option} id="deliveryYear"
                    renderInput={(params) => <TextField {...params} label="Delivery Year" />}
                    onChange={(event, value) => this.onDeliveryYearSelectChange(event, value)} />
            );
        }
    }

    renderAuctionSelectBox = () => {
        return (
            <Autocomplete value={this.state.search.auction} options={['', 'BRA', 'FIRST', 'SECOND', 'THIRD']} getOptionLabel={(option) => option} id="auction"
                renderInput={(params) => <TextField {...params} label="Auction" />}
                onChange={(event, value) => this.onAuctionSelectChange(event, value)} />
        );
    }

    onLdaSelectChange = (event, value) => {
        let search = { ...this.state.search };
        if (value) {
            search.lda = value;
        } else {
            search.lda = '';
        }
        this.setState({ search: search });
        this.retrieveCapacityTransferRights(search, 0, this.state.pageSize, this.state.direction, this.state.column);
    }

    onDeliveryYearSelectChange = (event, value) => {
        let search = { ...this.state.search };
        if (value) {
            search.deliveryYear = value;
        } else {
            search.deliveryYear = '';
        }
        this.setState({ search: search });
        this.retrieveCapacityTransferRights(search, 0, this.state.pageSize, this.state.direction, this.state.column);
    }

    onAuctionSelectChange = (event, value) => {
        let search = { ...this.state.search };
        if (value) {
            search.auction = value;
        } else {
            search.auction = '';
        }
        this.setState({ search: search });
        this.retrieveCapacityTransferRights(search, 0, this.state.pageSize, this.state.direction, this.state.column);
    }

    retrieveCapacityTransferRights = async (search, pageNumber, pageSize, direction, column) => {
        let url = undefined;
        if (search.deliveryYear && search.auction && search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/auction/" + search.auction + "/lda/" + search.lda + "/transferRight";
        } else if (search.deliveryYear && search.auction && !search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/auction/" + search.auction + "/transferRight";
        } else if (search.deliveryYear && !search.auction && search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/lda/" + search.lda + "/transferRight";
        } else if (search.deliveryYear && !search.auction && !search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/transferRight";
        } else if (!search.deliveryYear && search.auction && search.lda) {
            url = "/capacity/auction/" + search.auction + "/lda/" + search.lda + "/transferRight";
        } else if (!search.deliveryYear && search.auction && !search.lda) {
            url = "/capacity/auction/" + search.auction + "/transferRight";
        } else if (!search.deliveryYear && !search.auction && search.lda) {
            url = "/capacity/lda/" + search.lda + "/transferRight";
        }
        if (url) {
            url = url + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
            if (direction) {
                url = url + "&direction=" + direction;
            }
            if (column) {
                url = url + "&orderBy=" + column;
            }
            await trackPromise(axios.get(url).then(response => {
                this.setState({ capacityTransferRights: response.data });
            }).catch(error => {
                this.props.onError(error);
            }));
        }
    }

    handlePageChange = (pageNumber, pageSize) => {
        this.retrieveCapacityTransferRights(this.state.search, pageNumber, pageSize, this.state.direction, this.state.column);
    }

    handleRowsPerPageChange = (e) => {
        this.setState({ pageSize: e.target.value });
        this.retrieveCapacityTransferRights(this.state.search, 0, e.target.value, this.state.direction, this.state.column);
    }

    handleSort = (column) => {
        let sortDirection = column === this.state.orderBy && this.state.direction === 'asc' ? 'desc' : 'asc';
        this.setState({ orderBy: column, direction: sortDirection });
        this.retrieveCapacityTransferRights(this.state.search, 0, this.state.pageSize, sortDirection, column);
    }

    renderCtrTable = () => {
        const { classes } = this.props;
        let table = (
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <TableSortLabel active={this.state.orderBy === 'deliveryYear'} direction={this.state.orderBy === 'deliveryYear' ? this.state.direction : 'asc'}
                            onClick={() => this.handleSort('deliveryYear')}>
                            Delivery Year
                            {this.state.orderBy === 'deliveryYear' ? (
                                <span className={classes.visuallyHidden}>{this.state.direction === 'desc' ? 'sorted descending' : 'sorted ascending'} </span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={this.state.orderBy === 'auction'} direction={this.state.orderBy === 'auction' ? this.state.direction : 'asc'}
                            onClick={() => this.handleSort('auction')}>
                            Auction
                            {this.state.orderBy === 'auction' ? (
                                <span className={classes.visuallyHidden}>{this.state.direction === 'desc' ? 'sorted descending' : 'sorted ascending'} </span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={this.state.orderBy === 'lda'} direction={this.state.orderBy === 'lda' ? this.state.direction : 'asc'}
                            onClick={() => this.handleSort('lda')}>
                            LDA
                            {this.state.orderBy === 'lda' ? (
                                <span className={classes.visuallyHidden}>{this.state.direction === 'desc' ? 'sorted descending' : 'sorted ascending'} </span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={this.state.orderBy === 'remainingCtrs'} direction={this.state.orderBy === 'remainingCtrs' ? this.state.direction : 'asc'}
                            onClick={() => this.handleSort('remainingCtrs')}>
                            Remaining CTRs
                            {this.state.orderBy === 'remainingCtrs' ? (
                                <span className={classes.visuallyHidden}>{this.state.direction === 'desc' ? 'sorted descending' : 'sorted ascending'} </span>) : null}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
        if (this.state.capacityTransferRights) {
            if (Array.isArray(this.state.capacityTransferRights.results)) {
                return (
                    <Table className={classes.table}>
                        {table}
                        <TableBody>
                            {this.state.capacityTransferRights.results.map(ctr => {
                                return this.renderTableRow(ctr);
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={4}
                                    count={this.state.capacityTransferRights.totalCount ? this.state.capacityTransferRights.totalCount : 0}
                                    rowsPerPage={calculateRowsPerPage(this.state.capacityTransferRights.pageSize)}
                                    page={this.state.capacityTransferRights.pageNumber ? this.state.capacityTransferRights.pageNumber : 0}
                                    onPageChange={this.handlePageChange} onRowsPerPageChange={this.handleRowsPerPageChange} ActionsComponent={PaginatedTableActions} />

                            </TableRow>
                        </TableFooter>
                    </Table>
                )
            }
            return (
                <Table className={classes.table}>
                    {table}
                    <TableBody>
                        {this.renderTableRow(this.state.capacityTransferRights)}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={4} count={1} rowsPerPage={10} page={0}
                                onPageChange={this.handlePageChange} onRowsPerPageChange={this.handleRowsPerPageChange} ActionsComponent={PaginatedTableActions} />
                        </TableRow>
                    </TableFooter>
                </Table>
            )
        }
        return (
            <Table>
                {table}
            </Table>
        );
    }

    renderTableRow = (capacityTransferRight) => {
        const { classes } = this.props;
        return (
            <TableRow key={capacityTransferRight.id} className={classes.tableRow}
                onClick={() => this.props.history.push("/capacity/capacityTransferRights/" + capacityTransferRight.id)}>
                <TableCell align="center">{capacityTransferRight.deliveryYear}</TableCell>
                <TableCell align="center">{capacityTransferRight.auction}</TableCell>
                <TableCell align="center">{capacityTransferRight.lda}</TableCell>
                <TableCell align="center"><NumberFormat value={capacityTransferRight.remainingCtrs} displayType='text' fixedDecimalScale={true} decimalScale={1} thousandSeparator />
                </TableCell>
            </TableRow>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <main className={classes.layout}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        {this.renderDeliveryYearSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        {this.renderAuctionSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={3}>
                        {this.renderLdaSelectBox()}
                    </Grid>
                </Grid>
                {this.renderCtrTable()}
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        ldas: state.capacity.ldas,
        deliveryYearAuctions: state.capacity.deliveryYearAuctions
    };
}

const mapDispatchToProps = dispatch => {
    return {
        retrieveLdas: () => dispatch(retrieveLdas()),
        retrieveDeliveryYearAuction: () => dispatch(retrieveDeliveryYearAuctions()),
        onError: (error) => dispatch(onError(error))
    };
}

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewCapacityTransferRights)));