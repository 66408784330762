import { ACCOUNT_CHANGED } from "../../actionTypes";
import { RETRIEVE_DELIVERY_YEAR_AUCTION, RETRIEVE_DELIVERY_YEAR_AUCTIONS, RETRIEVE_LDAS, RETRIEVE_ZONES, SET_CAPACICITY_TRANSACTIONS_TRANSACTION_ID, SET_CAPACICITY_TRANSACTIONS_TRANSACTION_TYPE, SET_CAPACITY_TRANSACTIONS_DIRECTION, SET_CAPACITY_TRANSACTIONS_END_DATE, SET_CAPACITY_TRANSACTIONS_ORDER_BY, SET_CAPACITY_TRANSACTIONS_PAGE_SIZE, SET_CAPACITY_TRANSACTIONS_RESULTS, SET_CAPACITY_TRANSACTIONS_SEARCH_BY, SET_CAPACITY_TRANSACTIONS_START_DATE } from "../capacityExchangeActionTypes";

const initialState = {
    ldas: undefined,
    deliveryYearAuctions: undefined,
    deliveryYearAuction: undefined,
    zones: undefined,
    capacityTransactionsSearchStart: null,
    capacityTransactionsSearchEnd: null,
    capacityTransactionsSearchBy: 'startDate',
    capacityTransactionsTransactionType: null,
    capacityTransactionsTransactionId: '',
    capacityTransactionsResults: [],
    capacityTransactionsDirection: undefined,
    capacityTransactionsOrderBy: undefined,
    capacityTransactionsPageSize: 10
}

const capacityExchangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_LDAS:
            return retrieveLdas(state, action);
        case RETRIEVE_DELIVERY_YEAR_AUCTIONS:
            return retrieveDeliveryYearAuctions(state, action);
        case RETRIEVE_DELIVERY_YEAR_AUCTION:
            return retrieveDeliveryYearAuction(state, action);
        case RETRIEVE_ZONES:
            return retrieveZones(state, action);
        case SET_CAPACITY_TRANSACTIONS_START_DATE:
            return { ...state, capacityTransactionsSearchStart: action.capacityTransactionsSearchStart };
        case SET_CAPACITY_TRANSACTIONS_END_DATE:
            return { ...state, capacityTransactionsSearchEnd: action.capacityTransactionsSearchEnd };
        case SET_CAPACITY_TRANSACTIONS_SEARCH_BY:
            return { ...state, capacityTransactionsSearchBy: action.capacityTransactionsSearchBy };
        case SET_CAPACITY_TRANSACTIONS_RESULTS:
            return { ...state, capacityTransactionsResults: action.capacityTransactionsResults };
        case SET_CAPACITY_TRANSACTIONS_PAGE_SIZE:
            return { ...state, capacityTransactionsPageSize: action.capacityTransactionsPageSize };
        case SET_CAPACITY_TRANSACTIONS_DIRECTION:
            return { ...state, capacityTransactionsDirection: action.capacityTransactionsDirection };
        case SET_CAPACITY_TRANSACTIONS_ORDER_BY:
            return { ...state, capacityTransactionsOrderBy: action.capacityTransactionsOrderBy };
        case SET_CAPACICITY_TRANSACTIONS_TRANSACTION_TYPE:
            return { ...state, capacityTransactionsTransactionType: action.capacityTransactionsTransactionType };
        case SET_CAPACICITY_TRANSACTIONS_TRANSACTION_ID:
            return { ...state, capacityTransactionsTransactionId: action.capacityTransactionsTransactionId };
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

const retrieveLdas = (state, action) => {
    return { ...state, ldas: action.ldas };
}

const retrieveDeliveryYearAuctions = (state, action) => {
    return { ...state, deliveryYearAuctions: action.deliveryYearAuctions }
}

const retrieveDeliveryYearAuction = (state, action) => {
    return { ...state, deliveryYearAuction: action.deliveryYearAuction };
}

const retrieveZones = (state, action) => {
    let zones = new Map();
    zones.set(action.deliveryYear, action.zones);
    return { ...state, zones: zones };
}

export default capacityExchangeReducer;