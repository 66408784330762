export const RETRIEVE_LDAS = 'RETRIEVE_LDAS';
export const RETRIEVE_DELIVERY_YEAR_AUCTIONS = 'RETRIEVE_DELIVERY_YEAR_AUCTIONS';
export const RETRIEVE_DELIVERY_YEAR_AUCTION = 'RETRIEVE_DELIVERY_YEAR_AUCTION';
export const RETRIEVE_ZONES = 'RETRIEVE_ZONES';
export const SET_CAPACITY_TRANSACTIONS_START_DATE = 'SET_CAPACITY_TRANSACTIONS_START_DATE';
export const SET_CAPACITY_TRANSACTIONS_END_DATE = 'SET_CAPACITY_TRANSACTIONS_END_DATE';
export const SET_CAPACITY_TRANSACTIONS_SEARCH_BY = 'SET_CAPACITY_TRANSACTIONS_SEARCH_BY';
export const SET_CAPACITY_TRANSACTIONS_RESULTS = 'SET_CAPACITY_TRANSACTIONS_RESULTS';
export const SET_CAPACITY_TRANSACTIONS_PAGE_SIZE = 'SET_CAPACITY_TRANSACTIONS_PAGE_SIZE';
export const SET_CAPACITY_TRANSACTIONS_DIRECTION = 'SET_CAPACITY_TRANSACTIONS_DIRECTION';
export const SET_CAPACITY_TRANSACTIONS_ORDER_BY = 'SET_CAPACITY_TRANSACTIONS_ORDER_BY';
export const SET_CAPACICITY_TRANSACTIONS_TRANSACTION_TYPE = 'SET_CAPACICITY_TRANSACTIONS_TRANSACTION_TYPE';
export const SET_CAPACICITY_TRANSACTIONS_TRANSACTION_ID = 'SET_CAPACICITY_TRANSACTIONS_TRANSACTION_ID';