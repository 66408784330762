import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from 'react';
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
        padding: "5px 0px 20px 0px"
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        background: 'white'
    }
}));

const MonthlyBillExplanationComponent = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [monthlyBill, setMonthlyBill] = useState();

    useEffect(() => {
        setMonthlyBill(props.monthlyBill);
        // eslint-disable-next-line
    }, [props]);


    const downloadMonthlyBill = async () => {
        await trackPromise(axios.get("/account/" + props.accountId + "/v1/pjm/billing/monthly/explanation/billingYear/" + props.billingYear + "/billingMonth/" + props.billingMonth.name, { headers: { accept: 'application/octet-stream' }, responseType: 'arraybuffer' }).then(response => {
            let filenameHeader = response.headers['content-disposition'];
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filenameHeader.substring(filenameHeader.indexOf('=') + 2, filenameHeader.lastIndexOf('"')));
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const renderLineItems = (lineItems) => {
        if (lineItems.pjmLineItems && lineItems.pjmLineItems.length > 0) {
            let renderedLineItems = [];
            lineItems.pjmLineItems.forEach(r => {
                renderedLineItems.push(
                    <React.Fragment key={r.lineItemNumber}>
                        <TableRow>
                            <TableCell>{r.lineItemNumber}</TableCell>
                            <TableCell>{r.lineItemName}</TableCell>
                            <TableCell><NumberFormat value={r.charge} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell><NumberFormat value={r.credit} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell><NumberFormat value={r.dollarsPerMWH} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                        </TableRow>
                        {r.lineItemDescription &&
                            <TableRow>
                                <TableCell colSpan={5}>{r.lineItemDescription} {r.rpmDescriptions && r.rpmDescriptions.map(s => {
                                    return (" " + s + " ")
                                })}</TableCell>
                            </TableRow>
                        }
                    </React.Fragment>
                )
            })
            return renderedLineItems;
        }
    }

    const renderBillExplanation = () => {
        return (
            <React.Fragment>
                <Table stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={5} align="center">Billing Totals</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center">{monthlyBill.currentMonth}, {monthlyBill.currentYear}</TableCell>
                            <TableCell align="center">{monthlyBill.previousMonth}, {monthlyBill.previousYear}</TableCell>
                            <TableCell align="center">{monthlyBill.prevPreviousMonth}, {monthlyBill.prevPreviousYear}</TableCell>
                            <TableCell align="center">{monthlyBill.currentYear} YTD</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Charges</TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.currentCharges} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.previousCharges} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.prevPreviousCharges} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.ytdCharges} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Credits</TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.currentCredits} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.previousCredits} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.prevPreviousCredits} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.ytdCredits} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.currentTotal} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.previousTotal} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.prevPreviousTotal} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                            <TableCell align="center"><NumberFormat value={monthlyBill.ytdTotal} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                prefix="$" thousandSeparator={true} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={5} align="center">Bill Summary</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center">{monthlyBill.currentMonth}, {monthlyBill.currentYear}</TableCell>
                            <TableCell align="center">{monthlyBill.previousMonth}, {monthlyBill.previousYear}</TableCell>
                            <TableCell align="center">{monthlyBill.prevPreviousMonth}, {monthlyBill.prevPreviousYear}</TableCell>
                            <TableCell align="center">{monthlyBill.currentYear} YTD</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {monthlyBill.billingCategories && monthlyBill.billingCategories.map(r => {
                            return (
                                <TableRow id={r.categoryName}>
                                    <TableCell>{r.categoryName}</TableCell>
                                    <TableCell align="center"><NumberFormat value={r.currentAmount} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={r.previousAmount} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={r.prevPreviousAmount} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={r.ytdAmount} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {monthlyBill.invoiceRecon &&
                    <React.Fragment>
                        <Table stickyHeader className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={5} align="center">Volume Reconciliation</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell />
                                    <TableCell align="center">{monthlyBill.currentMonth}, {monthlyBill.currentYear}</TableCell>
                                    <TableCell align="center">{monthlyBill.previousMonth}, {monthlyBill.previousYear}</TableCell>
                                    <TableCell align="center">{monthlyBill.prevPreviousMonth}, {monthlyBill.prevPreviousYear}</TableCell>
                                    <TableCell align="center">{monthlyBill.currentYear} YTD</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Independent Meter Read (MWh)</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.currentThirdPartyMeterRead} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.previousThirdPartyMeterRead} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.prevPreviousThirdPartyMeterRead} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.ytdThirdPartyMeterRead} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{monthlyBill.invoiceRecon.transmissionProvider} Meter Read (MWh)</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.currentTransmissionLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.previousTransmissionLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.prevPreviousTransmissionLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.ytdTransmissionLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>PJM Load (MWh)</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.currentPjmLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.previousPjmLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.prevPreviousPjmLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.ytdPjmLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Under/Over Volume (MWh)</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.currentUnderOverCharge} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.previousUnderOverCharge} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.prevPreviousUnderOverCharge} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.ytdUnderOverCharge} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Percent Difference to {monthlyBill.invoiceRecon.transmissionProvider}</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.currentPercentDifference} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.previousPercentDifference} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.prevPreviousPercentDifference} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.ytdPercentDifference} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table stickyHeader className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={5} align="center">Invoice Reconciliation</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">{monthlyBill.currentMonth}, {monthlyBill.currentYear}</TableCell>
                                    <TableCell align="center">{monthlyBill.previousMonth}, {monthlyBill.previousYear}</TableCell>
                                    <TableCell align="center">{monthlyBill.prevPreviousMonth}, {monthlyBill.prevPreviousYear}</TableCell>
                                    <TableCell align="center">{monthlyBill.currentYear} YTD</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Projected Reconciliation (Month Due)</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.currentProjectedRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /> ({monthlyBill.invoiceRecon.currentDueMonth})</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.previousProjectedRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /> ({monthlyBill.invoiceRecon.previousDueMonth})</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.prevPreviousProjectedRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /> ({monthlyBill.invoiceRecon.prevPreviousDueMonth})</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.ytdProjectedRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} />  (YTD)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Projected Reconciliation (Month From)</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.currentActualRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /> ({monthlyBill.invoiceRecon.currentFromMonth})</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.previousActualRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /> ({monthlyBill.invoiceRecon.previousFromMonth})</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.prevPreviousActualRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /> ({monthlyBill.invoiceRecon.prevPreviousFromMonth})</TableCell>
                                    <TableCell align="center"><NumberFormat value={monthlyBill.invoiceRecon.ytdActualRecon} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /> (YTD)</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </React.Fragment>
                }
                {monthlyBill.usage &&
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={5} align="center">Usage</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center">{monthlyBill.currentMonth}, {monthlyBill.currentYear}</TableCell>
                                <TableCell align="center">{monthlyBill.previousMonth}, {monthlyBill.previousYear}</TableCell>
                                <TableCell align="center">{monthlyBill.prevPreviousMonth}, {monthlyBill.prevPreviousYear}</TableCell>
                                <TableCell align="center">{monthlyBill.currentYear} YTD</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Balancing Load (MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.currentBalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.previousBalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.prevPreviousBalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.ytdBalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Day Ahead Load (MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.currentDALoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.previousDALoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.prevPreviousDALoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.ytdDALoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total Load (MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.currentTotalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.previousTotalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.prevPreviousTotalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.ytdTotalLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Balancing Generation (MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.currentBalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.previousBalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.prevPreviousBalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.ytdBalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Day Ahead Generation (MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.currentDAGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.previousDAGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.prevPreviousDAGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.ytdDAGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total Generation (MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.currentTotalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.previousTotalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.prevPreviousTotalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.usage.ytdTotalGeneration} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
                {monthlyBill.eMeter &&
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={5} align="center">Usage</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center">Original</TableCell>
                                <TableCell align="center">Correction</TableCell>
                                <TableCell align="center">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center"><NumberFormat value={monthlyBill.eMeter.currentOriginal} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.eMeter.currentCorrection} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.eMeter.currentProduction} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
                {monthlyBill.lmp &&
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={5} align="center">Average LMP</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center">{monthlyBill.currentMonth}, {monthlyBill.currentYear}</TableCell>
                                <TableCell align="center">{monthlyBill.previousMonth}, {monthlyBill.previousYear}</TableCell>
                                <TableCell align="center">{monthlyBill.prevPreviousMonth}, {monthlyBill.prevPreviousYear}</TableCell>
                                <TableCell align="center">{monthlyBill.currentYear} YTD</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>RT LMP ($/MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.currentRT} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.previousRT} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.prevPreviousRT} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.ytdRT} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>DA LMP ($/MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.currentDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.previousDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.prevPreviousDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.lmp.ytdDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
                {monthlyBill.operatingReserves &&
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={5} align="center">Operating Reserves</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center">{monthlyBill.currentMonth}, {monthlyBill.currentYear}</TableCell>
                                <TableCell align="center">{monthlyBill.previousMonth}, {monthlyBill.previousYear}</TableCell>
                                <TableCell align="center">{monthlyBill.prevPreviousMonth}, {monthlyBill.prevPreviousYear}</TableCell>
                                <TableCell align="center">{monthlyBill.currentYear} YTD</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Balancing Operating Reserves ($/MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.currentBalancing} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.previousBalancing} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.prevPreviousBalancing} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.ytdBalancing} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Day Ahead Operating Reserves ($/MWh)</TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.currentDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.previousDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.prevPreviousDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                <TableCell align="center"><NumberFormat value={monthlyBill.operatingReserves.ytdDA} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
                {monthlyBill.lineItems && monthlyBill.lineItems.length > 0 &&
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Line Item Number</TableCell>
                                <TableCell align="center">Line Item Name</TableCell>
                                <TableCell align="center">Charges</TableCell>
                                <TableCell align="center">Credits</TableCell>
                                <TableCell align="center">$MWh</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {monthlyBill.lineItems.map(r => {
                                return (
                                    <React.Fragment key={r.categoryName}>
                                        <TableRow>
                                            <TableCell align="center" colSpan={5}>{r.categoryName}</TableCell>
                                        </TableRow>
                                        {renderLineItems(r)}
                                    </React.Fragment>

                                )
                            })}
                        </TableBody>
                    </Table>
                }
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={downloadMonthlyBill} fullWidth>Download</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {monthlyBill && renderBillExplanation()}
        </React.Fragment>
    );

};

export default MonthlyBillExplanationComponent;