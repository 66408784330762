import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
        padding: "5px 0px 20px 0px"
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        background: 'white'
    }
}));

const ViewInvoiceReconciliation = () => {

    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const [billingYear, setBillingYear] = useState('');
    const [invoiceRecons, setInvoiceRecons] = useState([]);

    const renderBillingYearOptions = () => {
        const currentYear = new Date().getFullYear();
        let startingYear = currentYear - 4;
        let yearOptions = [];
        while (startingYear <= currentYear) {
            yearOptions.push(<MenuItem key={startingYear} value={startingYear}>{startingYear}</MenuItem>);
            startingYear++;
        }
        return yearOptions;
    }

    const handleBillingYearChange = (e) => {
        setBillingYear(e.target.value);
        retrieveInvoiceRecons(e.target.value);
    }

    const retrieveInvoiceRecons = async (year) => {
        await trackPromise(axios.get("/account/" + params.accountId + "/v1/pjm/billing/invoice/reconciliation/year/" + year).then(response => {
            setInvoiceRecons(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} />
                <Grid item xs={6} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel>Billing Year</InputLabel>
                        <Select value={billingYear} label="Billing Year" onChange={handleBillingYearChange}>
                            {renderBillingYearOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} />
            </Grid>
            {invoiceRecons && invoiceRecons.length > 0 &&
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Month</TableCell>
                            <TableCell align="center">PJM Usage (MWhs)</TableCell>
                            <TableCell align="center">Distribution Invoice (MWhs)</TableCell>
                            <TableCell align="center">Percent Difference</TableCell>
                            <TableCell align="center">Distribution - PJM Difference (MWhs)</TableCell>
                            <TableCell align="center">Estimated Charge/Credit</TableCell>
                            <TableCell align="center">Actual Charge/Credit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            invoiceRecons.sort((r1, r2) => { return r1.billingMonthInt - r2.billingMonthInt }).map(r => {
                                return (
                                    <TableRow key={r.id}>
                                        <TableCell align="center">{r.billingMonth}</TableCell>
                                        <TableCell align="center"><NumberFormat value={r.pjmMonthEndLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                        <TableCell align="center"><NumberFormat value={r.transmissionMonthEndLoad} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                        <TableCell align="center"><NumberFormat value={r.percentDifferenceToDpl * 100} displayType={'text'} fixedDecimalScale={true} decimalScale={0} suffix="%" />
                                        </TableCell>
                                        <TableCell align="center"><NumberFormat value={r.underOverCharge} displayType={'text'} fixedDecimalScale={true} decimalScale={3} thousandSeparator={true} /></TableCell>
                                        <TableCell align="center"><NumberFormat value={r.projectedReconciliation} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                        <TableCell align="center"><NumberFormat value={r.actualReconciliation} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$" thousandSeparator={true} /></TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            }
        </main>
    )

}

export default ViewInvoiceReconciliation;