import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError } from '../../../store/actions/popupActions';

const ViewSupplierAccounts = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const roles = useSelector(state => state.auth.roles);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [supplierAccounts, setSupplierAccounts] = useState([]);

    useEffect(() => {
        retrieveSupplierAccounts();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveSupplierAccounts = async () => {
        await trackPromise(axios.get("/gas-service/supplier").then(response => {
            setSupplierAccounts(response.data);
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const handleCreateSupplierAccountClick = () => {
        history.push("/gas/supplier/account/create");
    }

    const hasEditPermissions = () => {
        return hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_gas_supplier_account');
    }

    return (
        <main className={classes.layout}>
            <Typography variant='h4' align='center' style={{ marginBottom: 25 }}>Supplier Accounts</Typography>
            {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_gas_supplier_account') &&
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={handleCreateSupplierAccountClick} fullWidth>Create Supplier Account</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Supplier Name</TableCell>
                        <TableCell>Supplier Group Number</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supplierAccounts && supplierAccounts.length > 0 && supplierAccounts.map(supplierAccount => {
                        return (
                            <TableRow key={supplierAccount.id} className={hasEditPermissions() ? classes.tableRow : ''}
                                onClick={hasEditPermissions() ? () => history.push("/gas/supplier/account/" + supplierAccount.id) : undefined}>
                                <TableCell>{supplierAccount.supplierName}</TableCell>
                                <TableCell>{supplierAccount.supplierGroupNumber}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </main>
    );

};

export default ViewSupplierAccounts;