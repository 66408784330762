import { ACCOUNT_CHANGED } from "../actionTypes";
import { LOCATION_DETAILS_DIRECTION, LOCATION_DETAILS_ORDER_BY, LOCATION_DETAILS_PAGE_SIZE, PANEL_MODULE_IS_EDIT, RETRIEVE_SOLAR_LOCATIONS, SET_PANEL_MODULE, SET_PANEL_MODULES, UPDATE_PANEL_MODULES } from "./SolarForecastActionTypes";

const initialState = {
    locationDetails: {
        pageSize: 10,
        direction: 'asc',
        orderBy: 'name',
        results: {}
    },
    panelModules: {
        results: []
    },
    panelModule: {
        inServiceDate: null,
        derateStartDate: null,
        derateEndDate: null
    },
    panelModuleIsEdit: true,
}

const SolarForecastReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_SOLAR_LOCATIONS:
            let locationDetails = state.locationDetails;
            locationDetails.results = action.locationDetails;
            return { ...state, locationDetails: locationDetails };
        case LOCATION_DETAILS_PAGE_SIZE:
            state.locationDetails.pageSize = action.pageSize;
            return { ...state, locationDetails: state.locationDetails };
        case LOCATION_DETAILS_ORDER_BY:
            state.locationDetails.orderBy = action.orderBy;
            return { ...state, locationDetails: state.locationDetails };
        case LOCATION_DETAILS_DIRECTION:
            state.locationDetails.direction = action.direction;
            return { ...state, locationDetails: state.locationDetails };
        case SET_PANEL_MODULES:
            return { ...state, panelModules: action.panelModules };
        case SET_PANEL_MODULE:
            state.panelModule = action.panelModule;
            return { ...state, panelModule: { ...state.panelModule } };
        case UPDATE_PANEL_MODULES:
            return updatePanelModules(state, action);
        case PANEL_MODULE_IS_EDIT:
            return { ...state, panelModuleIsEdit: action.panelModuleIsEdit };
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

const updatePanelModules = (state, action) => {
    let index = -1;
    let panelModules = state.panelModules;
    if (panelModules && panelModules.results) {
        for (let i = 0; i < panelModules.results.length; i++) {
            if (panelModules.results[i].id === action.panelModule.id) {
                index = i;
            }
        }
    }
    if (index === -1) {
        if (panelModules && panelModules.results) {
            panelModules.results.push(action.panelModule);
            panelModules.totalCount = panelModules.results.length;
        } else {
            panelModules.results = [{ ...action.panelModule }];
            panelModules.totalCount = 1;
        }
    } else {
        panelModules.results.splice(index, 1, action.panelModule);
    }
    return { ...state, panelModules: { ...panelModules } };
}

export default SolarForecastReducer;