import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import { cleanStringInput } from '../../../Utility/StringUtil';
import axios from '../../../axios/AxiosInterceptors';
import { onError } from '../../../store/actions/popupActions';

const GeneratorUnitComponent = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const accountId = props.accountId;
    const generatorId = props.generatorId;
    const disableFields = !props.isEdit;
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [generatorUnit, setGeneratorUnit] = useState({ useInschedule: false, useSolarForecast: false, submitDayAhead: false, autoSubmit: false, submitFiveMinute: false });
    const [solarForecastLocations, setSolarForecastLocations] = useState([{ id: 0, name: '' }]);
    const [solarForecastLocation, setSolarForecastLocation] = useState({ id: 0, name: '' });

    useEffect(() => {
        if (generatorId) {
            retrieveGenerator(accountId, generatorId);
        }
        setSolarForecastLocations([{ id: 0, name: '' }]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])


    const retrieveGenerator = async (accountId, generatorId) => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + generatorId).then(response => {
            setGeneratorUnit(response.data);
            if (response.data.useSolarForecast) {
                retrieveSolarForecastLocations(accountId, response.data.solarForecastLocationId);
            }
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrieveSolarForecastLocations = async (accountId, selectedLocationId) => {
        await trackPromise(axios.get("/solar-forecast/account/" + accountId + "/location/detail?pageNumber=0&pageSize=1000")).then(response => {
            solarForecastLocations.push(...response.data.results);
            setSolarForecastLocations([...solarForecastLocations]);
            if (selectedLocationId) {
                let selectedLocation = solarForecastLocations.filter(r => r.id === selectedLocationId);
                if (selectedLocation && selectedLocation.length === 1) {
                    setSolarForecastLocation({ ...selectedLocation[0] });
                }
            }
        }).catch(error => {
            dispatch(onError(error.response));
        })
    }

    const handleUnitNameChange = (e) => {
        generatorUnit.unitName = e.target.value;
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleLocationChange = (e) => {
        generatorUnit.location = e.target.value;
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleUseInscheduleChange = () => {
        generatorUnit.useInschedule = !generatorUnit.useInschedule;
        generatorUnit.inscheduleContractId = '';
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleInscheduleContractIdChange = (e) => {
        if (e.target.value) {
            generatorUnit.inscheduleContractId = Number(e.target.value);
        } else {
            generatorUnit.inscheduleContractId = '';
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleUseSolarForecastChange = () => {
        generatorUnit.useSolarForecast = !generatorUnit.useSolarForecast;
        generatorUnit.solarForecastLocationId = '';
        setSolarForecastLocation({ id: 0, name: '' });
        if (generatorUnit.useSolarForecast) {
            retrieveSolarForecastLocations(accountId);
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleSolarForecastLocationChange = (e, value) => {
        setSolarForecastLocation(value);
        if (value) {
            generatorUnit.solarForecastLocationId = value.id;
        } else {
            generatorUnit.solarForecastLocationId = 0;
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleSubmitFiveMinuteChange = () => {
        generatorUnit.submitFiveMinute = !generatorUnit.submitFiveMinute;
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleAutoSubmitChange = () => {
        generatorUnit.autoSubmit = !generatorUnit.autoSubmit;
        setGeneratorUnit({ ...generatorUnit });
    }

    const saveGenerator = () => {
        if (generatorUnit.solarForecastLocationId === 0) {
            generatorUnit.solarForecastLocationId = undefined;
            setGeneratorUnit({ ...generatorUnit });
        }
        if (generatorId) {
            updateGenerator();
        } else {
            createGenerator();
        }
    }

    const createGenerator = async () => {
        await trackPromise(axios.post("/marketsgateway/v1/account/" + accountId + "/generation/unit", JSON.stringify(generatorUnit)).then(response => {
            history.push("/markets/account/" + accountId + "/energy/generator/unit");
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const updateGenerator = async () => {
        await trackPromise(axios.put("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + generatorId, JSON.stringify(generatorUnit)).then(response => {
            history.push("/markets/account/" + accountId + "/energy/generator/unit");
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <main className={classes.layout}>
            {generatorUnit && <Typography variant="h5" align="center">{generatorUnit.unitName}</Typography>}
            <form onSubmit={(e) => {
                e.preventDefault();
                saveGenerator();
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <TextField name="unitName" value={cleanStringInput(generatorUnit.unitName)} label="Unit Name" onChange={handleUnitNameChange} fullWidth disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <TextField name="location" value={cleanStringInput(generatorUnit.location)} label="Location Id" onChange={handleLocationChange} fullWidth disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={12}>
                        <Typography variant='h6' align='center'>Default Day Ahead Offer Strategy</Typography>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <FormControlLabel control={<Checkbox checked={generatorUnit.useInschedule} onChange={handleUseInscheduleChange} />}
                            label="Use Inschedule Contract" disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <TextField name="inscheduleContractId" value={cleanStringInput(generatorUnit.inscheduleContractId)} label="Inschedule Contract Id"
                            onChange={handleInscheduleContractIdChange} type='number' fullWidth disabled={!generatorUnit.useInschedule || disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <FormControlLabel control={<Checkbox checked={generatorUnit.useSolarForecast} onChange={handleUseSolarForecastChange} />}
                            label="Use Solar Forecast" disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <Autocomplete name="solarForecastLocation" value={solarForecastLocation} options={solarForecastLocations}
                            getOptionLabel={(option) => option.name} getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleSolarForecastLocationChange(e, value)} renderInput={(params) => <TextField {...params} label="Solar Forecast Location" />} disabled={!generatorUnit.useSolarForecast || disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <FormControlLabel control={<Checkbox checked={generatorUnit.submitFiveMinute} onChange={handleSubmitFiveMinuteChange} />}
                            label="Submit Five Minute Updates" disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <FormControlLabel control={<Checkbox checked={generatorUnit.autoSubmit} onChange={handleAutoSubmitChange} />}
                            label="Auto Submit DA Offers" disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        {(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_generator_unit') || hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_generator_unit')) && <Button type="submit" fullWidth disabled={disableFields}>Save</Button>}
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </form>
        </main>
    )

}

export default GeneratorUnitComponent;