import { Box, Button, Grid, Modal, TextField, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from "../../../store/actions/popupActions";

const UploadAccountResponse = (props) => {

    const dispatch = useDispatch();
    const [accountResponseFileName, setAccountResponseFileName] = useState('');
    const [accountResponseFile, setAccountResponseFile] = useState();

    const fileChosen = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        setAccountResponseFileName(e.target.files[0].name);
        setAccountResponseFile(formData);
    }

    const uploadAccountResponse = async () => {
        var formData = new FormData();
        formData.append("file", accountResponseFile);
        formData.append("filename", accountResponseFileName);
        await trackPromise(axios.post("/gas-service/supplier/" + props.supplierAccountId + "/customer/WG/account", accountResponseFile).then(response => {
            dispatch(onSuccess("Account response uploaded successfully"));
            if (props.handleUpload) {
                props.handleUpload();
            }
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const handleClose = () => {
        setAccountResponseFile(undefined);
        setAccountResponseFileName('');
        if (props.handleClose) {
            props.handleClose();
        }
    }

    return (
        <Modal open={props.isOpen} onClose={props.handleClose} >
            <Box sx={{ backgroundColor: 'white', position: 'absolute', top: '40%', left: '40%', width: 500, height: 175, border: '2px solid #000' }}>
                <Typography variant="h6" align="center" style={{ marginTop: 10, marginBottom: 25 }}>Upload Account Response</Typography>
                <label htmlFor="uploadAccountResponse">
                    <TextField value={accountResponseFileName} style={{ width: '90%', marginLeft: "5%" }} align="center"
                        InputProps={{ startAdornment: <Button component="span" style={{ width: '35%', marginBottom: 3 }} variant="outlined">Upload File</Button> }} fullWidth mt={3} />
                    <input style={{ display: "none" }} id="uploadAccountResponse" name="uploadAccountResponse" type="file" onChange={fileChosen} />
                </label>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={uploadAccountResponse} fullWidth style={{ marginTop: 15 }}>Upload</Button>
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={handleClose} fullWidth style={{ marginTop: 15 }}>Close</Button>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                </Grid>
            </Box>
        </Modal>
    )

}

export default UploadAccountResponse;