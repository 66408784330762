import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment/moment";
import { default as React, useEffect, useState } from 'react';
import NumberFormat from "react-number-format";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import axios from "../../axios/AxiosInterceptors";
import { onError } from "../../store/actions/popupActions";


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        background: 'white'
    }
}));

const BillingEstimate = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [weeklyBillPeriods, setWeeklyBillPeriods] = useState([]);
    const [weeklyPeriod, setWeeklyPeriod] = useState({});
    const [includeMonthToDate, setIncludeMonthToDate] = useState(false);
    const [billingYear, setBillingYear] = useState('');
    const [billingMonth, setBillingMonth] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [billingDetails, setBillingDetails] = useState();
    const [lineItemNumbers, setLineItemNumbers] = useState([]);
    const [dailyTotals, setDailyTotals] = useState();

    const billingMonthMenuItems = [{ "name": "January", "value": 1 }, { "name": "February", "value": 2 }, { "name": "March", "value": 3 }, { "name": "April", "value": 4 }, { "name": "May", "value": 5 }, { "name": "June", "value": 6 }, { "name": "July", "value": 7 }, { "name": "August", "value": 8 }, { "name": "September", "value": 9 }, { "name": "October", "value": 10 }, { "name": "November", "value": 11 }, { "name": "December", "value": 12 }];

    useEffect(() => {
        setStartDate(new Date());
        setEndDate(new Date());
        retrieveWeeklyBillPeriods(new Date().getFullYear());
        // eslint-disable-next-line
    }, [])

    const retrieveWeeklyBillPeriods = async (year) => {
        await trackPromise(axios.get("/pjm/billing/weekly/period?year=" + year)).then(response => {
            if (response.data) {
                setWeeklyBillPeriods(response.data);
            }
        }).catch(error => {
            dispatch(onError(error));
        });
    }

    const doSearch = async () => {
        await trackPromise(axios.get("/billing/account/" + params.accountId + "/estimate/detail?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            if (response.data) {
                setBillingDetails(response.data);
                var lineItems = [];
                var localDailyTotals = [];
                for (var key of Object.keys(response.data)) {
                    var dailyTotal = 0;
                    for (var value of response.data[key]) {
                        lineItems.push({ lineItemNumber: value.lineItemNumber, lineItemName: value.lineItemName });
                        dailyTotal = dailyTotal + value.amount;
                    }
                    localDailyTotals.push({ date: key, total: dailyTotal });
                }
                localDailyTotals.sort((c1, c2) => c1.date.localeCompare(c2.date));
                setDailyTotals([...localDailyTotals]);
                const uniqueIds = [];
                const unique = lineItems.filter(r => {
                    const isDuplicate = uniqueIds.includes(r.lineItemNumber);
                    if (!isDuplicate) {
                        uniqueIds.push(r.lineItemNumber);
                        return true;
                    }
                    return false;
                });
                setLineItemNumbers([...unique]);
            }
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const renderDailyTotals = () => {
        var totals = 0;
        return (
            <TableRow key={'dailyTotals'}>
                <TableCell align="center" className={classes.stickyColumn} />
                <TableCell align="center" className={classes.stickyColumn}>Total</TableCell>
                {dailyTotals &&
                    dailyTotals.map(r => {
                        totals = totals + r.total;
                        return (
                            <TableCell align="center" key={r.date}><NumberFormat value={r.total} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} decimalScale={2} prefix="$" /></TableCell>
                        )
                    })
                }
                <TableCell align="center"><NumberFormat value={totals} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} decimalScale={2} prefix="$" /></TableCell>
            </TableRow>
        );
    }

    const renderBillingDetails = () => {
        if (billingDetails) {
            let keys = Object.keys(billingDetails);
            var renderedTable = (
                <TableContainer style={{ maxHeight: 700 }}>
                    <Table className={classes.table} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.stickyColumn}>Line Item Number</TableCell>
                                <TableCell align="center" className={classes.stickyColumn}>Line Item Name</TableCell>
                                {keys.map(key => {
                                    return (
                                        <TableCell align="center" key={key} style={{ minWidth: 100 }}>{key}</TableCell>
                                    )
                                })}
                                <TableCell align="center">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderDailyTotals()}
                            {
                                lineItemNumbers.map(lineItemNumber => {
                                    var lineItemTotal = 0;
                                    return (<TableRow key={lineItemNumber.lineItemNumber}>
                                        <TableCell align="center" key={lineItemNumber.lineItemNumber} className={classes.stickyColumn}>{lineItemNumber.lineItemNumber}</TableCell>
                                        <TableCell align="center" key={lineItemNumber.lineItemName} className={classes.stickyColumn}>{lineItemNumber.lineItemName}</TableCell>
                                        {keys.map(key => {
                                            var lineItem = billingDetails[key].filter(r => r.lineItemNumber === lineItemNumber.lineItemNumber);
                                            var amount = "-";
                                            if (lineItem && lineItem.length > 0 && (lineItem[0].amount || lineItem[0].amount === 0)) {
                                                amount = lineItem[0].amount;
                                                lineItemTotal = lineItemTotal + amount;
                                            }
                                            return (
                                                <TableCell align="center" key={key}>{amount !== '-' ? <NumberFormat value={amount} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} decimalScale={2} prefix="$" /> : amount} </TableCell>
                                            )
                                        })}
                                        <TableCell align="center" key={"total"}>{<NumberFormat value={lineItemTotal} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} decimalScale={2} prefix="$" />} </TableCell>
                                    </TableRow>
                                    )
                                })
                            }
                            {renderDailyTotals()}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
            return renderedTable;
        }
    }

    const renderWeeklyPeriodValue = (value) => {
        if (!value || !value.startDate) {
            return '';
        }
        return value.startDate + " - " + value.endDate;
    }

    const renderWeeklyBillPeriodsOptions = () => {
        let options = [];
        if (weeklyBillPeriods && weeklyBillPeriods.length > 0) {
            weeklyBillPeriods.forEach(wbp => {
                options.push(<MenuItem key={wbp.startDate} value={wbp}>{wbp.startDate + " - " + wbp.endDate}</MenuItem>);
            })
        }
        return options;
    }

    const handleWeeklyBillPeriodChange = (e) => {
        let selectedPeriod = e.target.value;
        setStartDate(moment(selectedPeriod.startDate));
        setEndDate(moment(selectedPeriod.endDate));
        setWeeklyPeriod(selectedPeriod);
        if (includeMonthToDate) {
            setStartDate(moment(selectedPeriod.startDate).startOf('month'));
        }
        setBillingYear('');
        setBillingMonth({});
    }

    const determineWeeklyPeriodValue = () => {
        if (!weeklyPeriod || !weeklyPeriod.startDate) {
            return '';
        }
        return weeklyPeriod;
    }

    const handleIncludeMonthToDateChange = (e) => {
        if (e.target.checked === false) {
            setIncludeMonthToDate(false);
            if (weeklyPeriod && weeklyPeriod.startDate) {
                setStartDate(moment(weeklyPeriod.startDate));
            }
        }
        if (e.target.checked === true) {
            setIncludeMonthToDate(true);
            if (weeklyPeriod && weeklyPeriod.startDate) {
                setStartDate(startDate.startOf('month'));
            }
        }
    }

    const renderBillingYearOptions = () => {
        const currentYear = new Date().getFullYear();
        let startingYear = currentYear - 3;
        let yearOptions = [];
        while (startingYear <= currentYear) {
            yearOptions.push(<MenuItem key={startingYear} value={startingYear}>{startingYear}</MenuItem>);
            startingYear++;
        }
        return yearOptions;
    }

    const handleBillingMonthChange = (e) => {
        setBillingMonth(e.target.value);
        if (billingYear) {
            let start = moment({ year: billingYear, month: e.target.value.value - 1, day: 1 })
            setStartDate(start);
            setEndDate(start.clone().endOf('month'));
        }
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
    }

    const handleBillingYearChange = (e) => {
        setBillingYear(e.target.value);
        if (billingMonth && billingMonth.name) {
            let start = moment({ year: e.target.value, month: billingMonth.value - 1, day: 1 })
            setStartDate(start);
            setEndDate(start.clone().endOf('month'));
        }
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
    }

    const renderBillingMonthValue = (value) => {
        if (value && value.name) {
            return value.name;
        }
        return '';
    }

    const determineBillingMonthValue = () => {
        if (billingMonth && billingMonth.name) {
            return billingMonth;
        }
        return '';
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
        setBillingYear('');
        setBillingMonth({});
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setWeeklyPeriod({});
        setIncludeMonthToDate(false);
        setBillingYear('');
        setBillingMonth({});
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Weekly Billing Period</InputLabel>
                        <Select value={determineWeeklyPeriodValue()} renderValue={(value) => renderWeeklyPeriodValue(value)} label="Weekly Billing Period" onChange={handleWeeklyBillPeriodChange} >
                            {renderWeeklyBillPeriodsOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel control={<Checkbox name="isMonthToDate" checked={includeMonthToDate}
                        onChange={handleIncludeMonthToDateChange} />} label="Include Month to Date" />
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Billing Year</InputLabel>
                        <Select value={billingYear} label="Billing Year" onChange={handleBillingYearChange}>
                            {renderBillingYearOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>Billing Month</InputLabel>
                        <Select value={determineBillingMonthValue()} label="Billing Month" renderValue={(value) => renderBillingMonthValue(value)}
                            onChange={handleBillingMonthChange}>
                            {
                                billingMonthMenuItems.map(menuItem => {
                                    return (
                                        <MenuItem value={menuItem} key={menuItem.value}>{menuItem.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={1} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={(date) => handleStartDateChange(date)}
                            value={startDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate" onChange={(date) => handleEndDateChange(date)}
                            value={endDate} fullWidth autoOk={true} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2} />
                <Grid item xs={6} sm={5} />
                <Grid item xs={6} sm={2}>
                    <Button onClick={doSearch}>Search</Button>
                </Grid>
                <Grid item xs={6} sm={5} />
            </Grid>
            {renderBillingDetails()}
        </main >
    )

}

export default BillingEstimate;