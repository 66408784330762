import { Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import React from 'react';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewEqrAccount = (props) => {

    const classes = useStyles();
    const eqrAccount = useSelector(state => state.fercEqr.eqrAccount);

    return (
        <main className={classes.layout}>
            <Typography variant="h5" align="center" style={{ marginBottom: 50 }}>{eqrAccount.companyName}</Typography>
            <Typography variant="h6" align="center" style={{ marginBottom: 25 }}>WPEC Accounts</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Account Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eqrAccount.wpecAccounts && eqrAccount.wpecAccounts.length > 0 && eqrAccount.wpecAccounts.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell>{r.wpecAccountName}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Typography variant="h6" align="center" style={{ marginBottom: 25, marginTop: 50 }}>EQR Identities</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Filer Unique Id</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Company Identifier</TableCell>
                        <TableCell>Contact Name</TableCell>
                        <TableCell>Contact Title</TableCell>
                        <TableCell>Contact Address</TableCell>
                        <TableCell>Contact City</TableCell>
                        <TableCell>Contact State</TableCell>
                        <TableCell>Contact Zip</TableCell>
                        <TableCell>Contact Country</TableCell>
                        <TableCell>Contact Phone</TableCell>
                        <TableCell>Contact E-Mail</TableCell>
                        <TableCell>Report To Index Price Publishers</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eqrAccount.eqrIdentities && eqrAccount.eqrIdentities.length > 0 && eqrAccount.eqrIdentities.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell>{r.filerUniqueId}</TableCell>
                                <TableCell>{r.companyName}</TableCell>
                                <TableCell>{r.companyIdentifier}</TableCell>
                                <TableCell>{r.contactName}</TableCell>
                                <TableCell>{r.contactTitle}</TableCell>
                                <TableCell>{r.contactAddress}</TableCell>
                                <TableCell>{r.contactCity}</TableCell>
                                <TableCell>{r.contactState}</TableCell>
                                <TableCell>{r.contactZip}</TableCell>
                                <TableCell>{r.contactCountryName}</TableCell>
                                <TableCell>{r.contactPhone}</TableCell>
                                <TableCell>{r.contactEmail}</TableCell>
                                <TableCell>{r.transactionReportToIndexPricePublishers}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Typography variant="h6" align="center" style={{ marginBottom: 25, marginTop: 50 }}>EQR Contracts</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Contract Unique Id</TableCell>
                        <TableCell>Seller Company Name</TableCell>
                        <TableCell>Customer Company Name</TableCell>
                        <TableCell>Contract Affiliate</TableCell>
                        <TableCell>FERC Tariff Ref.</TableCell>
                        <TableCell>Contract Service Agreement Id</TableCell>
                        <TableCell>Contract Execution Date</TableCell>
                        <TableCell>Commencement Date of Contract Term</TableCell>
                        <TableCell>Contract Termination Date</TableCell>
                        <TableCell>Actual Termination Date</TableCell>
                        <TableCell>Extention Provision Description</TableCell>
                        <TableCell>Class Name</TableCell>
                        <TableCell>Term Name</TableCell>
                        <TableCell>Increment Name</TableCell>
                        <TableCell>Increment Peaking Name</TableCell>
                        <TableCell>Product Type Name</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Units</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Rate Min.</TableCell>
                        <TableCell>Rate Max.</TableCell>
                        <TableCell>Rate Desc.</TableCell>
                        <TableCell>Rate Units</TableCell>
                        <TableCell>Receipt Balancing Authority</TableCell>
                        <TableCell>Receipt Specific Location</TableCell>
                        <TableCell>Delivery Balancing Authority</TableCell>
                        <TableCell>Delivery Specific Location</TableCell>
                        <TableCell>Begin Date</TableCell>
                        <TableCell>End Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eqrAccount.eqrContracts && eqrAccount.eqrContracts.length > 0 && eqrAccount.eqrContracts.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell>{r.contractUniqueId}</TableCell>
                                <TableCell>{r.sellerCompanyName}</TableCell>
                                <TableCell>{r.customerCompanyName}</TableCell>
                                <TableCell>{r.contractAffiliate}</TableCell>
                                <TableCell>{r.fercTariffReference}</TableCell>
                                <TableCell>{r.contractServiceAgreementId}</TableCell>
                                <TableCell>{r.contractExecutionDate}</TableCell>
                                <TableCell>{r.commencementDateOfContractTerm}</TableCell>
                                <TableCell>{r.contractTerminationDate}</TableCell>
                                <TableCell>{r.actualTerminationDate}</TableCell>
                                <TableCell>{r.extenstionProvisionDescription}</TableCell>
                                <TableCell>{r.className}</TableCell>
                                <TableCell>{r.termName}</TableCell>
                                <TableCell>{r.incrementName}</TableCell>
                                <TableCell>{r.incrementPeakingName}</TableCell>
                                <TableCell>{r.productTypeName}</TableCell>
                                <TableCell>{r.productName}</TableCell>
                                <TableCell>{r.quantity}</TableCell>
                                <TableCell>{r.units}</TableCell>
                                <TableCell>{r.rate}</TableCell>
                                <TableCell>{r.rateMinimum}</TableCell>
                                <TableCell>{r.rateMaximum}</TableCell>
                                <TableCell>{r.rateDescription}</TableCell>
                                <TableCell>{r.rateUnits}</TableCell>
                                <TableCell>{r.pointOfReceiptBalancingAuthority}</TableCell>
                                <TableCell>{r.pointOfReceiptSpecificLocation}</TableCell>
                                <TableCell>{r.pointOfDeliveryBalancingAuthority}</TableCell>
                                <TableCell>{r.pointOfDeliverySpecificLocation}</TableCell>
                                <TableCell>{r.beginDate}</TableCell>
                                <TableCell>{r.endDate}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </main>
    )

};

export default ViewEqrAccount;